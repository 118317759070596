import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./pages/layout";
import Home from "./pages/home";
import Callback from "./pages/callback";
import CallbackLocalhost from "./pages/callbackLocalhost";
import CallbackDev from "./pages/callbackDev";
import NoPage from "./pages/noPage";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="redirect" element={<Callback />} />

          <Route path="redirect-localhost" element={<CallbackLocalhost />} />
          <Route path="redirect-dev" element={<CallbackDev />} />
          <Route path="no_page" element={<NoPage />} />
          <Route path="*" element={<Home />} />
          <Route path="/:env" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export { App };
