import axios from 'axios';

var Environment;
(function (Environment) {
    Environment["PRODUCTION"] = "production";
    Environment["SANDBOX"] = "sandbox";
})(Environment || (Environment = {}));
class ImmutableConfiguration {
    environment;
    rateLimitingKey;
    apiKey;
    publishableKey;
    constructor(options) {
        this.environment = options.environment;
    }
}
const API_KEY_PREFIX = 'sk_imapik-';
const PUBLISHABLE_KEY_PREFIX = 'pk_imapik-';
const addApiKeyToAxiosHeader = (apiKey) => {
    if (!apiKey.startsWith(API_KEY_PREFIX)) {
        throw new Error('Invalid API key. Create your api key in Immutable developer hub. https://hub.immutable.com');
    }
    axios.defaults.headers.common['x-immutable-api-key'] = apiKey;
};
const addPublishableKeyToAxiosHeader = (publishableKey) => {
    if (!publishableKey.startsWith(PUBLISHABLE_KEY_PREFIX)) {
        throw new Error('Invalid Publishable key. Create your Publishable key in Immutable developer hub.'
            + ' https://hub.immutable.com');
    }
    axios.defaults.headers.common['x-immutable-publishable-key'] = publishableKey;
};
const addRateLimitingKeyToAxiosHeader = (rateLimitingKey) => {
    axios.defaults.headers.common['x-api-key'] = rateLimitingKey;
};

export { Environment, ImmutableConfiguration, addApiKeyToAxiosHeader, addPublishableKeyToAxiosHeader, addRateLimitingKeyToAxiosHeader };
