import { Config, ImmutableX } from '@imtbl/core-sdk';
export { IMXError, createStarkSigner as imxClientCreateStarkSigner, generateLegacyStarkPrivateKey as imxClientGenerateLegacyStarkPrivateKey } from '@imtbl/core-sdk';
import { Environment } from '@imtbl/config';

/**
 * createImmutableXConfiguration to create a custom ImmutableXConfiguration
 * other than the production and sandbox defined below.
 */
const createImmutableXConfiguration = ({ basePath, chainID, coreContractAddress, registrationContractAddress, }) => Config.createConfig({
    basePath,
    chainID,
    coreContractAddress,
    registrationContractAddress,
    sdkVersion: 'ts-immutable-sdk-__SDK_VERSION__',
});
/**
 * Sets `sdkVersion` at the time of build
 */
const production = () => createImmutableXConfiguration({
    basePath: 'https://api.x.immutable.com',
    chainID: 1,
    coreContractAddress: '0x5FDCCA53617f4d2b9134B29090C87D01058e27e9',
    registrationContractAddress: '0x72a06bf2a1CE5e39cBA06c0CAb824960B587d64c',
});
/**
 * Sets `sdkVersion` at the time of build
 */
const sandbox = () => createImmutableXConfiguration({
    basePath: 'https://api.sandbox.x.immutable.com',
    chainID: 11155111,
    coreContractAddress: '0x2d5C349fD8464DA06a3f90b4B0E9195F3d1b7F98',
    registrationContractAddress: '0xDbA6129C02E69405622fAdc3d5A7f8d23eac3b97',
});
class ImxConfiguration {
    immutableXConfig;
    baseConfig;
    constructor({ baseConfig, overrides }) {
        this.baseConfig = baseConfig;
        if (overrides) {
            this.immutableXConfig = overrides.immutableXConfig;
        }
        else {
            switch (baseConfig.environment) {
                case Environment.SANDBOX: {
                    this.immutableXConfig = sandbox();
                    break;
                }
                case Environment.PRODUCTION: {
                    this.immutableXConfig = production();
                    break;
                }
                default: {
                    this.immutableXConfig = sandbox();
                }
            }
        }
    }
}

class ImmutableXClient {
    immutableX;
    depositsApi;
    mintsApi;
    ordersApi;
    tokensApi;
    tradesApi;
    transfersApi;
    exchangeApi;
    nftCheckoutPrimaryApi;
    usersApi;
    withdrawalsApi;
    balanceApi;
    assetApi;
    collectionApi;
    metadataApi;
    metadataRefreshesApi;
    projectsApi;
    constructor(config) {
        const imxConfig = new ImxConfiguration(config);
        this.immutableX = new ImmutableX(imxConfig.immutableXConfig);
        this.depositsApi = this.immutableX.depositsApi;
        this.mintsApi = this.immutableX.mintsApi;
        this.ordersApi = this.immutableX.ordersApi;
        this.tokensApi = this.immutableX.tokensApi;
        this.tradesApi = this.immutableX.tradesApi;
        this.transfersApi = this.immutableX.transfersApi;
        this.exchangeApi = this.immutableX.exchangeApi;
        this.usersApi = this.immutableX.usersApi;
        this.withdrawalsApi = this.immutableX.withdrawalsApi;
        this.balanceApi = this.immutableX.balanceApi;
        this.assetApi = this.immutableX.assetApi;
        this.collectionApi = this.immutableX.collectionApi;
        this.metadataApi = this.immutableX.metadataApi;
        this.metadataRefreshesApi = this.immutableX.metadataRefreshesApi;
        this.nftCheckoutPrimaryApi = this.immutableX.nftCheckoutPrimaryApi;
        this.projectsApi = this.immutableX.projectsApi;
    }
    /**
     * Get details of a Deposit with the given ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Deposit
     * @throws {@link index.IMXError}
     */
    getDeposit(request) {
        return this.immutableX.getDeposit(request);
    }
    /**
     * Get a list of Deposits
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Deposits
     * @throws {@link index.IMXError}
     */
    listDeposits(request) {
        return this.immutableX.listDeposits(request);
    }
    /**
     * Get Stark keys for a registered User
     * @param ethAddress - the eth address of the User
     * @returns a promise that resolves with the requested User
     * @throws {@link index.IMXError}
     */
    getUser(ethAddress) {
        return this.immutableX.getUser(ethAddress);
    }
    /**
     * Get details of an Asset
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Asset
     * @throws {@link index.IMXError}
     */
    getAsset(request) {
        return this.immutableX.getAsset(request);
    }
    /**
     * Get a list of Assets
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Assets
     * @throws {@link index.IMXError}
     */
    listAssets(request) {
        return this.immutableX.listAssets(request);
    }
    /**
     * Create a Collection
     * @param ethSigner - the L1 signer
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with the created Collection
     * @throws {@link index.IMXError}
     */
    createCollection(ethSigner, request) {
        return this.immutableX.createCollection(ethSigner, request);
    }
    /**
     * Get details of a Collection at the given address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Collection
     * @throws {@link index.IMXError}
     */
    getCollection(request) {
        return this.immutableX.getCollection(request);
    }
    /**
     * Get a list of Collection filters
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Collection Filters
     * @throws {@link index.IMXError}
     */
    listCollectionFilters(request) {
        return this.immutableX.listCollectionFilters(request);
    }
    /**
     * Get a list of Collections
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Collections
     * @throws {@link index.IMXError}
     */
    listCollections(request) {
        return this.collectionApi.listCollections(request).then((res) => res.data);
    }
    /**
     * Update a Collection
     * @param ethSigner - the L1 signer
     * @param collectionAddress - the Collection contract address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the updated Collection
     * @throws {@link index.IMXError}
     */
    updateCollection(ethSigner, collectionAddress, request) {
        return this.immutableX.updateCollection(ethSigner, collectionAddress, request);
    }
    /**
     * Add metadata schema to Collection
     * @param ethSigner - the L1 signer
     * @param collectionAddress - the Collection contract address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the SuccessResponse if successful
     * @throws {@link index.IMXError}
     */
    addMetadataSchemaToCollection(ethSigner, collectionAddress, request) {
        return this.immutableX.addMetadataSchemaToCollection(ethSigner, collectionAddress, request);
    }
    /**
     * Get Metadata schema
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Metadata schema
     * @throws {@link index.IMXError}
     */
    getMetadataSchema(request) {
        return this.immutableX.getMetadataSchema(request);
    }
    /**
     * Update metadata schema by name
     * @param ethSigner - the L1 signer
     * @param collectionAddress - the Collection contract address
     * @param name - the Metadata schema name
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the SuccessResponse if successful
     * @throws {@link index.IMXError}
     */
    updateMetadataSchemaByName(ethSigner, collectionAddress, name, request) {
        return this.immutableX.updateMetadataSchemaByName(ethSigner, collectionAddress, name, request);
    }
    /**
     * Get a list of metadata refreshes
     * @param ethSigner - the L1 signer
     * @param collectionAddress - the Collection contract address
     * @param pageSize - the page size of the result
     * @param cursor - the cursor
     * @returns a promise that resolves with the requested metadata refreshes
     * @throws {@link index.IMXError}
     */
    listMetadataRefreshes(ethSigner, collectionAddress, pageSize, cursor) {
        return this.immutableX.listMetadataRefreshes(ethSigner, collectionAddress, pageSize, cursor);
    }
    /**
     * Get a list of metadata refresh errors
     * @param ethSigner - the L1 signer
     * @param refreshId - the metadata refresh ID
     * @param pageSize - the page size of the result
     * @param cursor - the cursor
     * @returns a promise that resolves with the requested metadata refresh errors
     * @throws {@link index.IMXError}
     */
    getMetadataRefreshErrors(ethSigner, refreshId, pageSize, cursor) {
        return this.immutableX.getMetadataRefreshErrors(ethSigner, refreshId, pageSize, cursor);
    }
    /**
     * Get a list of metadata refresh results
     * @param ethSigner - the L1 signer
     * @param refreshId - the metadata refresh ID
     * @returns a promise that resolves with the requested metadata refresh results
     * @throws {@link index.IMXError}
     */
    getMetadataRefreshResults(ethSigner, refreshId) {
        return this.immutableX.getMetadataRefreshResults(ethSigner, refreshId);
    }
    /**
     * Request a metadata refresh
     * @param ethSigner - the L1 signer
     * @param request the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested metadata refresh
     * @throws {@link index.IMXError}
     */
    createMetadataRefresh(ethSigner, request) {
        return this.immutableX.createMetadataRefresh(ethSigner, request);
    }
    /**
     * Create a Project
     * @param ethSigner - the L1 signer
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the created Project
     * @throws {@link index.IMXError}
     */
    async createProject(ethSigner, request) {
        return this.immutableX.createProject(ethSigner, request);
    }
    /**
     * Get a Project
     * @param ethSigner - the L1 signer
     * @param id - the Project ID
     * @returns a promise that resolves with the requested Project
     * @throws {@link index.IMXError}
     */
    async getProject(ethSigner, id) {
        return this.immutableX.getProject(ethSigner, id);
    }
    /**
     * Get Projects owned by the given User
     * @param ethSigner - the L1 signer
     * @param pageSize - the page size of the result
     * @param cursor - the cursor
     * @param orderBy - the property to sort by
     * @param direction - direction to sort (asc/desc)
     * @returns a promise that resolves with the requested Projects
     * @throws {@link index.IMXError}
     */
    async getProjects(ethSigner, pageSize, cursor, orderBy, direction) {
        return this.immutableX.getProjects(ethSigner, pageSize, cursor, orderBy, direction);
    }
    /**
     * Get the token Balances of the User
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Balance
     * @throws {@link index.IMXError}
     */
    getBalance(request) {
        return this.immutableX.getBalance(request);
    }
    /**
     * Get a list of Balances for given User
     * @param request the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Balances
     * @throws {@link index.IMXError}
     */
    listBalances(request) {
        return this.immutableX.listBalances(request);
    }
    /**
     * Get details of a Mint with the given ID
     * @param request the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Mint
     * @throws {@link index.IMXError}
     */
    getMint(request) {
        return this.immutableX.getMint(request);
    }
    /**
     * Get a list of Mints
     * @param request optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Mints
     * @throws {@link index.IMXError}
     */
    listMints(request) {
        return this.immutableX.listMints(request);
    }
    /**
     * Mint tokens in a batch with fees
     * @param ethSigner - the L1 signer
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with the minted tokens
     * @throws {@link index.IMXError}
     */
    mint(ethSigner, request) {
        return this.immutableX.mint(ethSigner, request);
    }
    /**
     * Get a list of Withdrawals
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Withdrawals
     * @throws {@link index.IMXError}
     */
    listWithdrawals(request) {
        return this.immutableX.listWithdrawals(request);
    }
    /**
     * Get details of Withdrawal with the given ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Withdrawal
     * @throws {@link index.IMXError}
     */
    getWithdrawal(request) {
        return this.immutableX.getWithdrawal(request);
    }
    /**
     * Get details of an Order with the given ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Order
     * @throws {@link index.IMXError}
     */
    getOrder(request) {
        return this.immutableX.getOrder(request);
    }
    /**
     * Get a list of Orders
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Orders
     * @throws {@link index.IMXError}
     */
    listOrders(request) {
        return this.immutableX.listOrders(request);
    }
    /**
     * Get details of a Trade with the given ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Trade
     * @throws {@link index.IMXError}
     */
    getTrade(request) {
        return this.immutableX.getTrade(request);
    }
    /**
     * Get a list of Trades
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Trades
     * @throws {@link index.IMXError}
     */
    listTrades(request) {
        return this.immutableX.listTrades(request);
    }
    /**
     * Get details of a Token
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Token
     * @throws {@link index.IMXError}
     */
    getToken(request) {
        return this.immutableX.getToken(request);
    }
    /**
     * Get a list of Tokens
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Tokens
     * @throws {@link index.IMXError}
     */
    listTokens(request) {
        return this.immutableX.listTokens(request);
    }
    /**
     * Get details of a Transfer with the given ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested Transfer
     * @throws {@link index.IMXError}
     */
    getTransfer(request) {
        return this.immutableX.getTransfer(request);
    }
    /**
     * Get a list of Transfers
     * @param request - optional object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the requested list of Transfers
     * @throws {@link index.IMXError}
     */
    listTransfers(request) {
        return this.immutableX.listTransfers(request);
    }
    /**
     * Create a new Exchange transaction
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with the created Exchange Transaction
     * @throws {@link index.IMXError}
     */
    createExchange(request) {
        return this.immutableX.createExchange(request).then((res) => res.data);
    }
    /**
     * Get an Exchange transaction
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with the Exchange Transaction
     * @throws {@link index.IMXError}
     */
    getExchange(request) {
        return this.immutableX.getExchange(request).then((res) => res.data);
    }
    /**
     * Get Exchange transactions
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with Exchange Transactions
     * @throws {@link index.IMXError}
     */
    getExchanges(request) {
        return this.immutableX.getExchanges(request).then((res) => res.data);
    }
    /**
     * Create a new Transfer request
     * @param walletConnection - the pair of Eth/Stark signers
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with the created Exchange Transfer
     * @throws {@link index.IMXError}
     */
    exchangeTransfer(walletConnection, request) {
        return this.immutableX.exchangeTransfer(walletConnection, request);
    }
    /**
     * Create a new nft primary transaction
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with the created nft primary Transaction
     * @throws {@link index.IMXError}
     */
    createNftPrimary(request) {
        return this.immutableX.createNftPrimary(request).then((res) => res.data);
    }
    /**
     * Get nft primary supported currencies and their limits
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with nft primary Currencies
     * @throws {@link index.IMXError}
     */
    getCurrenciesNFTCheckoutPrimary(request) {
        return this.immutableX
            .getCurrenciesNFTCheckoutPrimary(request)
            .then((res) => res.data);
    }
    /**
     * Get nft primary transaction by transaction id
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with nft primary transaction
     * @throws {@link index.IMXError}
     */
    getNftPrimaryTransaction(request) {
        return this.immutableX
            .getNftPrimaryTransaction(request)
            .then((res) => res.data);
    }
    /**
     * Get list of nft primary transactions
     * @param request - the request object to be provided in the API request
     * @returns a promise that resolves with nft primary transaction
     * @throws {@link index.IMXError}
     */
    getNftPrimaryTransactions(request) {
        return this.immutableX
            .getNftPrimaryTransactions(request)
            .then((res) => res.data);
    }
}

export { ImmutableXClient };
