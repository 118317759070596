import { Environment, ImmutableConfiguration } from '@imtbl/config';
import { BigNumber, utils, Contract, ethers } from 'ethers';
import axios, { HttpStatusCode } from 'axios';
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers';
import detectEthereumProvider from '@metamask/detect-provider';
import { BridgeConfiguration, TokenBridge, ETH_SEPOLIA_TO_ZKEVM_TESTNET, ETH_SEPOLIA_TO_ZKEVM_DEVNET, ETH_MAINNET_TO_ZKEVM_MAINNET } from '@imtbl/bridge-sdk';
import { Exchange } from '@imtbl/dex-sdk';
import { Orderbook, ActionType, TransactionPurpose, SignablePurpose, constants, OrderStatusName } from '@imtbl/orderbook';
import { BlockchainData } from '@imtbl/blockchain-data';

/**
 * Enum representing the events emitted by the widgets.
 */
var IMTBLWidgetEvents;
(function (IMTBLWidgetEvents) {
    IMTBLWidgetEvents["IMTBL_WIDGETS_PROVIDER"] = "imtbl-widgets-provider";
    IMTBLWidgetEvents["IMTBL_CONNECT_WIDGET_EVENT"] = "imtbl-connect-widget";
    IMTBLWidgetEvents["IMTBL_WALLET_WIDGET_EVENT"] = "imtbl-wallet-widget";
    IMTBLWidgetEvents["IMTBL_SWAP_WIDGET_EVENT"] = "imtbl-swap-widget";
    IMTBLWidgetEvents["IMTBL_BRIDGE_WIDGET_EVENT"] = "imtbl-bridge-widget";
    IMTBLWidgetEvents["IMTBL_ONRAMP_WIDGET_EVENT"] = "imtbl-onramp-widget";
    IMTBLWidgetEvents["IMTBL_SALE_WIDGET_EVENT"] = "imtbl-sale-widget";
})(IMTBLWidgetEvents || (IMTBLWidgetEvents = {}));
/**
 * Enum for events raised for about provider objects
 */
var ProviderEventType;
(function (ProviderEventType) {
    ProviderEventType["PROVIDER_UPDATED"] = "PROVIDER_UPDATED";
})(ProviderEventType || (ProviderEventType = {}));

/**
 * Enum representing possible Connect Widget event types.
 */
var ConnectEventType;
(function (ConnectEventType) {
    ConnectEventType["CLOSE_WIDGET"] = "close-widget";
    ConnectEventType["SUCCESS"] = "success";
    ConnectEventType["FAILURE"] = "failure";
})(ConnectEventType || (ConnectEventType = {}));

/**
 * Enum representing possible Wallet Widget event types.
 */
var WalletEventType;
(function (WalletEventType) {
    WalletEventType["CLOSE_WIDGET"] = "close-widget";
    WalletEventType["NETWORK_SWITCH"] = "network-switch";
    WalletEventType["DISCONNECT_WALLET"] = "disconnect-wallet";
})(WalletEventType || (WalletEventType = {}));

/**
 * Enum representing possible Swap Widget event types.
 */
var SwapEventType;
(function (SwapEventType) {
    SwapEventType["CLOSE_WIDGET"] = "close-widget";
    SwapEventType["SUCCESS"] = "success";
    SwapEventType["FAILURE"] = "failure";
    SwapEventType["REJECTED"] = "rejected";
})(SwapEventType || (SwapEventType = {}));

/**
 * Enum representing possible Sale Widget event types.
 */
var SaleEventType;
(function (SaleEventType) {
    SaleEventType["CLOSE_WIDGET"] = "close-widget";
    SaleEventType["SUCCESS"] = "success";
    SaleEventType["FAILURE"] = "failure";
    SaleEventType["REJECTED"] = "rejected";
    SaleEventType["TRANSACTION_SUCCESS"] = "transaction-success";
})(SaleEventType || (SaleEventType = {}));

/**
 * Enum of possible Bridge Widget event types.
 */
var BridgeEventType;
(function (BridgeEventType) {
    BridgeEventType["CLOSE_WIDGET"] = "close-widget";
    BridgeEventType["SUCCESS"] = "success";
    BridgeEventType["FAILURE"] = "failure";
})(BridgeEventType || (BridgeEventType = {}));

/**
 * Enum representing different types of orchestration events.
 */
var OrchestrationEventType;
(function (OrchestrationEventType) {
    OrchestrationEventType["REQUEST_CONNECT"] = "request-connect";
    OrchestrationEventType["REQUEST_WALLET"] = "request-wallet";
    OrchestrationEventType["REQUEST_SWAP"] = "request-swap";
    OrchestrationEventType["REQUEST_BRIDGE"] = "request-bridge";
    OrchestrationEventType["REQUEST_ONRAMP"] = "request-onramp";
})(OrchestrationEventType || (OrchestrationEventType = {}));

/**
 * Enum of possible OnRamp Widget event types.
 */
var OnRampEventType;
(function (OnRampEventType) {
    OnRampEventType["CLOSE_WIDGET"] = "close-widget";
    OnRampEventType["SUCCESS"] = "success";
    OnRampEventType["FAILURE"] = "failure";
})(OnRampEventType || (OnRampEventType = {}));

/**
 * Enum representing the list of widget types.
 */
var WidgetType;
(function (WidgetType) {
    WidgetType["CONNECT"] = "connect";
    WidgetType["WALLET"] = "wallet";
    WidgetType["SWAP"] = "swap";
    WidgetType["BRIDGE"] = "bridge";
    WidgetType["ONRAMP"] = "onramp";
    WidgetType["SALE"] = "sale";
})(WidgetType || (WidgetType = {}));

var ConnectTargetLayer;
(function (ConnectTargetLayer) {
    ConnectTargetLayer["LAYER1"] = "LAYER1";
    ConnectTargetLayer["LAYER2"] = "LAYER2";
})(ConnectTargetLayer || (ConnectTargetLayer = {}));

/**
 * Enum representing the themes for the widgets.
 */
var WidgetTheme;
(function (WidgetTheme) {
    WidgetTheme["LIGHT"] = "light";
    WidgetTheme["DARK"] = "dark";
})(WidgetTheme || (WidgetTheme = {}));

/**
 * Enum representing different chain IDs.
 * @enum {number}
 * @property {number} IMTBL_ZKEVM_MAINNET - The chain ID for IMTBL ZKEVM Mainnet.
 * @property {number} IMTBL_ZKEVM_TESTNET - The chain ID for IMTBL ZKEVM Testnet.
 * @property {number} IMTBL_ZKEVM_DEVNET - The chain ID for IMTBL ZKEVM Devnet.
 * @property {number} ETHEREUM - The chain ID for Ethereum.
 * @property {number} SEPOLIA - The chain ID for Sepolia.
 */
var ChainId;
(function (ChainId) {
    ChainId[ChainId["IMTBL_ZKEVM_MAINNET"] = 13371] = "IMTBL_ZKEVM_MAINNET";
    ChainId[ChainId["IMTBL_ZKEVM_TESTNET"] = 13473] = "IMTBL_ZKEVM_TESTNET";
    ChainId[ChainId["IMTBL_ZKEVM_DEVNET"] = 13433] = "IMTBL_ZKEVM_DEVNET";
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["SEPOLIA"] = 11155111] = "SEPOLIA";
})(ChainId || (ChainId = {}));
/**
 * Enum representing different chain names.
 * @enum {number}
 * @property {number} IMTBL_ZKEVM_MAINNET - The chain name for IMTBL ZKEVM Mainnet.
 * @property {number} IMTBL_ZKEVM_TESTNET - The chain name for IMTBL ZKEVM Testnet.
 * @property {number} IMTBL_ZKEVM_DEVNET - The chain name for IMTBL ZKEVM Devnet.
 * @property {number} ETHEREUM - The chain name for Ethereum.
 * @property {number} SEPOLIA - The chain name for Sepolia.
 */
var ChainName;
(function (ChainName) {
    ChainName["ETHEREUM"] = "Ethereum";
    ChainName["SEPOLIA"] = "Sepolia";
    ChainName["IMTBL_ZKEVM_TESTNET"] = "Immutable zkEVM Test";
    ChainName["IMTBL_ZKEVM_DEVNET"] = "Immutable zkEVM Dev";
    ChainName["IMTBL_ZKEVM_MAINNET"] = "Immutable zkEVM";
})(ChainName || (ChainName = {}));

var OnRampProvider;
(function (OnRampProvider) {
    OnRampProvider["TRANSAK"] = "201811419111";
})(OnRampProvider || (OnRampProvider = {}));

/**
 * An enum representing the type of gas estimate.
 * @enum {string}
 * @property {string} BRIDGE_TO_L2 - The gas estimate type for a bridge to L2 transaction.
 * @property {string} SWAP - The gas estimate type for a swap transaction.
 */
var GasEstimateType;
(function (GasEstimateType) {
    GasEstimateType["BRIDGE_TO_L2"] = "BRIDGE_TO_L2";
    GasEstimateType["SWAP"] = "SWAP";
})(GasEstimateType || (GasEstimateType = {}));

/**
 * Enum representing the types of filters that can be applied to get the allow list of networks.
 */
var NetworkFilterTypes;
(function (NetworkFilterTypes) {
    NetworkFilterTypes["ALL"] = "all";
})(NetworkFilterTypes || (NetworkFilterTypes = {}));

/**
 * Enum representing the types of token filters available.
 */
var TokenFilterTypes;
(function (TokenFilterTypes) {
    TokenFilterTypes["ALL"] = "all";
    TokenFilterTypes["SWAP"] = "swap";
    TokenFilterTypes["BRIDGE"] = "bridge";
    TokenFilterTypes["ONRAMP"] = "onramp";
})(TokenFilterTypes || (TokenFilterTypes = {}));

var WalletAction;
(function (WalletAction) {
    WalletAction["CHECK_CONNECTION"] = "eth_accounts";
    WalletAction["CONNECT"] = "eth_requestAccounts";
    WalletAction["ADD_NETWORK"] = "wallet_addEthereumChain";
    WalletAction["SWITCH_NETWORK"] = "wallet_switchEthereumChain";
    WalletAction["GET_CHAINID"] = "eth_chainId";
})(WalletAction || (WalletAction = {}));
/**
 * Enum representing the platform filters used in {@link GetWalletAllowListParams}.
 */
var WalletFilterTypes;
(function (WalletFilterTypes) {
    WalletFilterTypes["ALL"] = "all";
})(WalletFilterTypes || (WalletFilterTypes = {}));

/**
 * Enum representing the names of different wallet providers.
 */
var WalletProviderName;
(function (WalletProviderName) {
    WalletProviderName["PASSPORT"] = "passport";
    WalletProviderName["METAMASK"] = "metamask";
})(WalletProviderName || (WalletProviderName = {}));
const validateProviderDefaults = {
    allowMistmatchedChainId: false,
    allowUnsupportedProvider: false,
};

/**
 * An enum representing the checkout status types
 * @enum {string}
 * @property {string} SUCCESS - If checkout succeeded as the transactions were able to be processed
 * @property {string} FAILED - If checkout failed due to transactions not settling on chain
 * @property {string} INSUFFICIENT_FUNDS - If checkout failed due to insufficient funds
 */
var CheckoutStatus;
(function (CheckoutStatus) {
    CheckoutStatus["SUCCESS"] = "SUCCESS";
    CheckoutStatus["FAILED"] = "FAILED";
    CheckoutStatus["INSUFFICIENT_FUNDS"] = "INSUFFICIENT_FUNDS";
})(CheckoutStatus || (CheckoutStatus = {}));
/**
 * An enum representing the item types
 * @enum {string}
 * @property {string} NATIVE - If the item is a native token.
 * @property {string} ERC20 - If the item is an ERC20 token.
 * @property {string} ERC721 - If the item is an ERC721 token.
 */
var ItemType;
(function (ItemType) {
    ItemType["NATIVE"] = "NATIVE";
    ItemType["ERC20"] = "ERC20";
    ItemType["ERC721"] = "ERC721";
})(ItemType || (ItemType = {}));
/**
 * An enum representing transaction or gas types
 * @enum {string}
 * @property {string} TRANSACTION - If the type is a transaction
 * @property {string} GAS - If the type is the gas amount
 */
var TransactionOrGasType;
(function (TransactionOrGasType) {
    TransactionOrGasType["TRANSACTION"] = "TRANSACTION";
    TransactionOrGasType["GAS"] = "GAS";
})(TransactionOrGasType || (TransactionOrGasType = {}));
/**
 * An enum representing the gas token types
 * @enum {string}
 * @property {string} NATIVE - If the gas token is a native token.
 * @property {string} ERC20 - If the gas token is an ERC20 token.
 */
var GasTokenType;
(function (GasTokenType) {
    GasTokenType["NATIVE"] = "NATIVE";
    GasTokenType["ERC20"] = "ERC20";
})(GasTokenType || (GasTokenType = {}));
/**
 * An enum representing the routing outcome types
 * @enum {string}
 * @property {string} ROUTES_FOUND - If funding routes were found for the transaction.
 * @property {string} NO_ROUTES_FOUND - If no funding routes were found for the transaction.
 * @property {string} NO_ROUTE_OPTIONS - If no routing options were available for the transaction.
 */
var RoutingOutcomeType;
(function (RoutingOutcomeType) {
    RoutingOutcomeType["ROUTES_FOUND"] = "ROUTES_FOUND";
    RoutingOutcomeType["NO_ROUTES_FOUND"] = "NO_ROUTES_FOUND";
    RoutingOutcomeType["NO_ROUTE_OPTIONS"] = "NO_ROUTE_OPTIONS";
})(RoutingOutcomeType || (RoutingOutcomeType = {}));
/**
 * An enum representing the funding step types
 * @enum {string}
 * @property {string} BRIDGE - If the funding step is a bridge.
 * @property {string} SWAP - If the funding step is a swap.
 * @property {string} ONRAMP - If the funding step is an onramp.
 */
var FundingStepType;
(function (FundingStepType) {
    FundingStepType["BRIDGE"] = "BRIDGE";
    FundingStepType["SWAP"] = "SWAP";
    FundingStepType["ONRAMP"] = "ONRAMP";
})(FundingStepType || (FundingStepType = {}));

// import { Passport } from '@imtbl/passport';
/**
 * An enum representing the type of exchange.
 * @enum {string}
 * @property {string} ONRAMP - The exchange type for transacting.
 */
var ExchangeType;
(function (ExchangeType) {
    ExchangeType["ONRAMP"] = "onramp";
})(ExchangeType || (ExchangeType = {}));

/**
 * Enum representing different types of errors that can occur during the checkout process.
 */
var CheckoutErrorType;
(function (CheckoutErrorType) {
    CheckoutErrorType["WEB3_PROVIDER_ERROR"] = "WEB3_PROVIDER_ERROR";
    CheckoutErrorType["PROVIDER_ERROR"] = "PROVIDER_ERROR";
    CheckoutErrorType["DEFAULT_PROVIDER_ERROR"] = "DEFAULT_PROVIDER_ERROR";
    CheckoutErrorType["CONNECT_PROVIDER_ERROR"] = "CONNECT_PROVIDER_ERROR";
    CheckoutErrorType["GET_BALANCE_ERROR"] = "GET_BALANCE_ERROR";
    CheckoutErrorType["GET_INDEXER_BALANCE_ERROR"] = "GET_INDEXER_BALANCE_ERROR";
    CheckoutErrorType["GET_ERC20_BALANCE_ERROR"] = "GET_ERC20_BALANCE_ERROR";
    CheckoutErrorType["GET_ERC721_BALANCE_ERROR"] = "GET_ERC721_BALANCE_ERROR";
    CheckoutErrorType["GET_NETWORK_INFO_ERROR"] = "GET_NETWORK_INFO_ERROR";
    CheckoutErrorType["METAMASK_PROVIDER_ERROR"] = "METAMASK_PROVIDER_ERROR";
    CheckoutErrorType["CHAIN_NOT_SUPPORTED_ERROR"] = "CHAIN_NOT_SUPPORTED_ERROR";
    CheckoutErrorType["PROVIDER_REQUEST_MISSING_ERROR"] = "PROVIDER_REQUEST_MISSING_ERROR";
    CheckoutErrorType["PROVIDER_REQUEST_FAILED_ERROR"] = "PROVIDER_REQUEST_FAILED_ERROR";
    CheckoutErrorType["USER_REJECTED_REQUEST_ERROR"] = "USER_REJECTED_REQUEST_ERROR";
    CheckoutErrorType["TRANSACTION_FAILED"] = "TRANSACTION_FAILED";
    CheckoutErrorType["INSUFFICIENT_FUNDS"] = "INSUFFICIENT_FUNDS";
    CheckoutErrorType["UNPREDICTABLE_GAS_LIMIT"] = "UNPREDICTABLE_GAS_LIMIT";
    CheckoutErrorType["INVALID_GAS_ESTIMATE_TYPE"] = "INVALID_GAS_ESTIMATE_TYPE";
    CheckoutErrorType["UNSUPPORTED_TOKEN_TYPE_ERROR"] = "UNSUPPORTED_TOKEN_TYPE_ERROR";
    CheckoutErrorType["UNSUPPORTED_BALANCE_REQUIREMENT_ERROR"] = "UNSUPPORTED_BALANCE_REQUIREMENT_ERROR";
    CheckoutErrorType["GET_ORDER_LISTING_ERROR"] = "GET_ORDER_LISTING_ERROR";
    CheckoutErrorType["CANCEL_ORDER_LISTING_ERROR"] = "CANCEL_ORDER_LISTING_ERROR";
    CheckoutErrorType["PREPARE_ORDER_LISTING_ERROR"] = "PREPARE_ORDER_LISTING_ERROR";
    CheckoutErrorType["CREATE_ORDER_LISTING_ERROR"] = "CREATE_ORDER_LISTING_ERROR";
    CheckoutErrorType["FULFILL_ORDER_LISTING_ERROR"] = "FULFILL_ORDER_LISTING_ERROR";
    CheckoutErrorType["SWITCH_NETWORK_UNSUPPORTED"] = "SWITCH_NETWORK_UNSUPPORTED";
    CheckoutErrorType["GET_ERC20_ALLOWANCE_ERROR"] = "GET_ERC20_ALLOWANCE_ERROR";
    CheckoutErrorType["GET_ERC721_ALLOWANCE_ERROR"] = "GET_ERC721_ALLOWANCE_ERROR";
    CheckoutErrorType["EXECUTE_APPROVAL_TRANSACTION_ERROR"] = "EXECUTE_APPROVAL_TRANSACTION_ERROR";
    CheckoutErrorType["EXECUTE_FULFILLMENT_TRANSACTION_ERROR"] = "EXECUTE_FULFILLMENT_TRANSACTION_ERROR";
    CheckoutErrorType["SIGN_MESSAGE_ERROR"] = "SIGN_MESSAGE_ERROR";
    CheckoutErrorType["BRIDGE_GAS_ESTIMATE_ERROR"] = "BRIDGE_GAS_ESTIMATE_ERROR";
    CheckoutErrorType["ORDER_FEE_ERROR"] = "ORDER_FEE_ERROR";
    CheckoutErrorType["ITEM_REQUIREMENTS_ERROR"] = "ITEM_REQUIREMENTS_ERROR";
    CheckoutErrorType["API_ERROR"] = "API_ERROR";
    CheckoutErrorType["ORDER_EXPIRED_ERROR"] = "ORDER_EXPIRED_ERROR";
    CheckoutErrorType["WIDGETS_SCRIPT_LOAD_ERROR"] = "WIDGETS_SCRIPT_LOAD_ERROR";
})(CheckoutErrorType || (CheckoutErrorType = {}));
/* The CheckoutError class is a custom error class in TypeScript that includes a message, type, and
optional data object. */
class CheckoutError extends Error {
    message;
    type;
    data;
    constructor(message, type, data) {
        super(message);
        this.message = message;
        this.type = type;
        this.data = data;
    }
}
var CheckoutInternalErrorType;
(function (CheckoutInternalErrorType) {
    CheckoutInternalErrorType["REJECTED_SWITCH_AFTER_ADDING_NETWORK"] = "REJECTED_SWITCH_AFTER_ADDING_NETWORK";
})(CheckoutInternalErrorType || (CheckoutInternalErrorType = {}));
const withCheckoutError = async (
/**
 * Wraps a function that returns a Promise and catches any errors that occur. If an error is caught,
 * it is wrapped in a CheckoutError and rethrown.
 */
fn, customError) => {
    try {
        return await fn();
    }
    catch (error) {
        const cause = `${error.message}` || 'UnknownError';
        const errorMessage = customError.message
            ? `[${customError.type}]:${customError.message}. Cause:${cause}`
            : `[${customError.type}] Cause:${cause}`;
        if (error instanceof CheckoutError) {
            throw new CheckoutError(errorMessage, customError.type, {
                ...customError.data,
                innerErrorType: error.type,
                ...error.data,
            });
        }
        throw new CheckoutError(errorMessage, customError.type, {
            ...customError.data,
        });
    }
};

// this function needs to be in a separate file to prevent circular dependencies with ./network
// this gives us access to the properties of the underlying provider object
async function getUnderlyingChainId(web3Provider) {
    if (!web3Provider.provider?.request) {
        throw new CheckoutError('Parsed provider is not a valid Web3Provider', CheckoutErrorType.WEB3_PROVIDER_ERROR);
    }
    const chainId = await web3Provider.provider.request({
        method: WalletAction.GET_CHAINID,
        params: [],
    });
    return parseInt(chainId, 16);
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const UNRECOGNISED_CHAIN_ERROR_CODE = 4902; // error code (MetaMask)
// these functions should not be exported. These functions should be used as part of an exported function e.g switchWalletNetwork() above.
// make sure to check if(provider.provider?.request) in the exported function and throw an error
// eslint-disable-next-line consistent-return
async function switchNetworkInWallet(networkMap, web3Provider, chainId) {
    if (web3Provider.provider?.request) {
        return await web3Provider.provider.request({
            method: WalletAction.SWITCH_NETWORK,
            params: [
                {
                    chainId: networkMap.get(chainId)?.chainIdHex,
                },
            ],
        });
    }
}
// TODO: Should these functions always return something?
// eslint-disable-next-line consistent-return
async function addNetworkToWallet(networkMap, web3Provider, chainId) {
    if (web3Provider.provider?.request) {
        const networkDetails = networkMap.get(chainId);
        const addNetwork = {
            chainId: networkDetails?.chainIdHex,
            chainName: networkDetails?.chainName,
            rpcUrls: networkDetails?.rpcUrls,
            nativeCurrency: networkDetails?.nativeCurrency,
            blockExplorerUrls: networkDetails?.blockExplorerUrls,
        };
        return await web3Provider.provider.request({
            method: WalletAction.ADD_NETWORK,
            params: [addNetwork],
        });
    }
}
async function getNetworkAllowList(config, { type = NetworkFilterTypes.ALL, exclude }) {
    const { networkMap } = config;
    const allowedNetworkConfig = (await config.remote.getConfig('allowedNetworks'));
    const list = allowedNetworkConfig.filter((network) => {
        const allowAllTokens = type === NetworkFilterTypes.ALL;
        const networkNotExcluded = !(exclude || [])
            .map((exc) => exc.chainId)
            .includes(network.chainId);
        return allowAllTokens && networkNotExcluded;
    });
    const allowedNetworks = [];
    list.forEach((element) => {
        const newNetwork = networkMap.get(element.chainId);
        if (newNetwork) {
            allowedNetworks.push({
                name: newNetwork.chainName,
                chainId: parseInt(newNetwork.chainIdHex, 16),
                nativeCurrency: newNetwork.nativeCurrency,
                isSupported: true,
            });
        }
    });
    return {
        networks: allowedNetworks,
    };
}
async function getNetworkInfo(config, web3Provider) {
    const { networkMap } = config;
    return withCheckoutError(async () => {
        try {
            const network = await web3Provider.getNetwork();
            if (Array.from(networkMap.keys()).includes(network.chainId)) {
                const chainIdNetworkInfo = networkMap.get(network.chainId);
                return {
                    name: chainIdNetworkInfo.chainName,
                    chainId: parseInt(chainIdNetworkInfo.chainIdHex, 16),
                    nativeCurrency: chainIdNetworkInfo.nativeCurrency,
                    isSupported: true,
                };
            }
            return {
                chainId: network.chainId,
                name: network.name,
                isSupported: false,
            };
        }
        catch (err) {
            const chainId = await getUnderlyingChainId(web3Provider);
            const isSupported = Array.from(networkMap.keys()).includes(chainId);
            return {
                chainId,
                isSupported,
            };
        }
    }, {
        type: CheckoutErrorType.GET_NETWORK_INFO_ERROR,
    });
}
async function switchWalletNetwork(config, web3Provider, chainId) {
    const { networkMap } = config;
    const allowedNetworks = await getNetworkAllowList(config, {
        type: NetworkFilterTypes.ALL,
    });
    if (!allowedNetworks.networks.some((network) => network.chainId === chainId)) {
        throw new CheckoutError(`Chain:${chainId} is not a supported chain`, CheckoutErrorType.CHAIN_NOT_SUPPORTED_ERROR);
    }
    if (web3Provider.provider?.isPassport) {
        throw new CheckoutError('Switching networks with Passport provider is not supported', CheckoutErrorType.SWITCH_NETWORK_UNSUPPORTED);
    }
    // WT-1146 - Refer to the README in this folder for explanation on the switch network flow
    try {
        await switchNetworkInWallet(networkMap, web3Provider, chainId);
    }
    catch (err) {
        if (err.code === UNRECOGNISED_CHAIN_ERROR_CODE) {
            try {
                await addNetworkToWallet(networkMap, web3Provider, chainId);
                // eslint-disable-next-line @typescript-eslint/no-shadow
            }
            catch (err) {
                throw new CheckoutError('User cancelled add network request', CheckoutErrorType.USER_REJECTED_REQUEST_ERROR);
            }
        }
        else {
            throw new CheckoutError('User cancelled switch network request', CheckoutErrorType.USER_REJECTED_REQUEST_ERROR);
        }
    }
    const newProvider = new Web3Provider(web3Provider.provider);
    const newProviderNetwork = await newProvider.getNetwork();
    if (newProviderNetwork.chainId !== chainId) {
        throw new CheckoutError('User cancelled switch network request', CheckoutErrorType.USER_REJECTED_REQUEST_ERROR);
    }
    const networkInfo = await getNetworkInfo(config, newProvider);
    return {
        network: networkInfo,
        provider: newProvider,
    };
}

const ENV_DEVELOPMENT = 'development';
const DEFAULT_TOKEN_DECIMALS$1 = 18;
const NATIVE = 'native';
const ZKEVM_NATIVE_TOKEN = {
    name: 'IMX',
    symbol: 'IMX',
    decimals: DEFAULT_TOKEN_DECIMALS$1,
};
/**
 * Base URL for the Immutable API based on the environment.
 * @property {string} DEVELOPMENT - The base URL for the development environment.
 * @property {string} SANDBOX - The base URL for the sandbox environment.
 * @property {string} PRODUCTION - The base URL for the production environment.
 */
const IMMUTABLE_API_BASE_URL = {
    [ENV_DEVELOPMENT]: 'https://api.dev.immutable.com',
    [Environment.SANDBOX]: 'https://api.sandbox.immutable.com',
    [Environment.PRODUCTION]: 'https://api.immutable.com',
};
/**
 * Base URL for the checkout API based on the environment.
 * @property {string} DEVELOPMENT - The base URL for the development environment.
 * @property {string} SANDBOX - The base URL for the sandbox environment.
 * @property {string} PRODUCTION - The base URL for the production environment.
 */
const CHECKOUT_API_BASE_URL = {
    [ENV_DEVELOPMENT]: 'https://checkout-api.dev.immutable.com',
    [Environment.SANDBOX]: 'https://checkout-api.sandbox.immutable.com',
    [Environment.PRODUCTION]: 'https://checkout-api.immutable.com',
};
/**
 * Smart Checkout routing default onramp enabled flag
 */
const DEFAULT_ON_RAMP_ENABLED = true;
/**
 * Smart Checkout routing default swap enabled flag
 */
const DEFAULT_SWAP_ENABLED = true;
/**
 * Smart Checkout routing default bridge enabled flag
 */
const DEFAULT_BRIDGE_ENABLED = true;
const TRANSAK_API_BASE_URL = {
    [Environment.SANDBOX]: 'https://global-stg.transak.com',
    [Environment.PRODUCTION]: 'https://global.transak.com/',
};
const PRODUCTION_CHAIN_ID_NETWORK_MAP = new Map([
    [
        ChainId.ETHEREUM,
        {
            chainIdHex: `0x${ChainId.ETHEREUM.toString(16)}`,
            chainName: ChainName.ETHEREUM,
            rpcUrls: ['https://checkout-api.immutable.com/v1/rpc/eth-mainnet'],
            nativeCurrency: {
                name: ChainName.ETHEREUM,
                symbol: 'ETH',
                decimals: 18,
            },
            blockExplorerUrls: ['https://etherscan.io/'],
        },
    ],
    [
        ChainId.IMTBL_ZKEVM_MAINNET,
        {
            chainIdHex: `0x${ChainId.IMTBL_ZKEVM_MAINNET.toString(16)}`,
            chainName: ChainName.IMTBL_ZKEVM_MAINNET,
            rpcUrls: ['https://rpc.immutable.com'],
            nativeCurrency: ZKEVM_NATIVE_TOKEN,
        },
    ],
]);
const SANDBOX_CHAIN_ID_NETWORK_MAP = new Map([
    [
        ChainId.SEPOLIA,
        {
            chainIdHex: `0x${ChainId.SEPOLIA.toString(16)}`,
            chainName: ChainName.SEPOLIA,
            rpcUrls: [
                'https://checkout-api.sandbox.immutable.com/v1/rpc/eth-sepolia',
            ],
            nativeCurrency: {
                name: 'Sep Eth',
                symbol: 'ETH',
                decimals: 18,
            },
            blockExplorerUrls: ['https://sepolia.etherscan.io/'],
        },
    ],
    [
        ChainId.IMTBL_ZKEVM_TESTNET,
        {
            chainIdHex: `0x${ChainId.IMTBL_ZKEVM_TESTNET.toString(16)}`,
            chainName: ChainName.IMTBL_ZKEVM_TESTNET,
            rpcUrls: ['https://rpc.testnet.immutable.com'],
            nativeCurrency: ZKEVM_NATIVE_TOKEN,
        },
    ],
]);
const DEV_CHAIN_ID_NETWORK_MAP = new Map([
    [
        ChainId.SEPOLIA,
        {
            chainIdHex: `0x${ChainId.SEPOLIA.toString(16)}`,
            chainName: ChainName.SEPOLIA,
            rpcUrls: ['https://checkout-api.dev.immutable.com/v1/rpc/eth-sepolia'],
            nativeCurrency: {
                name: 'Sep Eth',
                symbol: 'ETH',
                decimals: 18,
            },
            blockExplorerUrls: ['https://sepolia.etherscan.io/'],
        },
    ],
    [
        ChainId.IMTBL_ZKEVM_DEVNET,
        {
            chainIdHex: `0x${ChainId.IMTBL_ZKEVM_DEVNET.toString(16)}`,
            chainName: ChainName.IMTBL_ZKEVM_DEVNET,
            rpcUrls: ['https://rpc.dev.immutable.com'],
            nativeCurrency: ZKEVM_NATIVE_TOKEN,
        },
    ],
]);
/**
 * Blockscout API configuration per chain
 */
const BLOCKSCOUT_CHAIN_URL_MAP = {
    [ChainId.IMTBL_ZKEVM_TESTNET]: {
        url: 'https://explorer.testnet.immutable.com',
        nativeToken: SANDBOX_CHAIN_ID_NETWORK_MAP.get(ChainId.IMTBL_ZKEVM_TESTNET).nativeCurrency,
    },
    [ChainId.IMTBL_ZKEVM_MAINNET]: {
        url: 'https://explorer.mainnet.immutable.com',
        nativeToken: PRODUCTION_CHAIN_ID_NETWORK_MAP.get(ChainId.IMTBL_ZKEVM_MAINNET).nativeCurrency,
    },
    [ChainId.SEPOLIA]: {
        url: 'https://eth-sepolia.blockscout.com',
        nativeToken: SANDBOX_CHAIN_ID_NETWORK_MAP.get(ChainId.SEPOLIA).nativeCurrency,
    },
    [ChainId.ETHEREUM]: {
        url: 'https://eth.blockscout.com/',
        nativeToken: PRODUCTION_CHAIN_ID_NETWORK_MAP.get(ChainId.ETHEREUM).nativeCurrency,
    },
};
const ERC20ABI = [
    {
        constant: true,
        inputs: [],
        name: 'name',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [
            {
                name: '',
                type: 'uint8',
            },
        ],
        payable: false,
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                name: 'balance',
                type: 'uint256',
            },
        ],
        payable: false,
        type: 'function',
    },
    {
        constant: true,
        inputs: [],
        name: 'symbol',
        outputs: [
            {
                name: '',
                type: 'string',
            },
        ],
        payable: false,
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                name: '_owner',
                type: 'address',
            },
            {
                name: '_spender',
                type: 'address',
            },
        ],
        name: 'allowance',
        outputs: [
            {
                name: '',
                type: 'uint256',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: false,
        inputs: [
            {
                name: '_spender',
                type: 'address',
            },
            {
                name: '_value',
                type: 'uint256',
            },
        ],
        name: 'approve',
        outputs: [
            {
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
const ERC721ABI = [
    {
        constant: false,
        inputs: [
            {
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'approve',
        outputs: [],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
            {
                internalType: 'address',
                name: 'operator',
                type: 'address',
            },
        ],
        name: 'isApprovedForAll',
        outputs: [
            {
                internalType: 'bool',
                name: '',
                type: 'bool',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        constant: true,
        inputs: [
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'getApproved',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        payable: false,
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
        ],
        name: 'ownerOf',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
// Gas overrides -- Anti-spam mechanism for when the baseFee drops low
// https://github.com/immutable/imx-docs/blob/main/docs/main/zkEVM/overview/gas-configuration.md
const GAS_OVERRIDES = {
    maxFeePerGas: BigNumber.from(102e9),
    maxPriorityFeePerGas: BigNumber.from(101e9),
};

const SDK_VERSION_MARKER = '__SDK_VERSION__';
// This SDK version is replaced by the `yarn build` command ran on the root level
const globalPackageVersion = () => SDK_VERSION_MARKER;

class RemoteConfigFetcher {
    isDevelopment;
    isProduction;
    configCache;
    tokensCache;
    version = 'v1';
    constructor(params) {
        this.isDevelopment = params.isDevelopment;
        this.isProduction = params.isProduction;
    }
    static async makeHttpRequest(url) {
        let response;
        try {
            response = await axios.get(url);
        }
        catch (error) {
            throw new CheckoutError(`Error fetching from api: ${error.message}`, CheckoutErrorType.API_ERROR);
        }
        if (response.status !== 200) {
            throw new CheckoutError(`Error fetching from api: ${response.status} ${response.statusText}`, CheckoutErrorType.API_ERROR);
        }
        return response;
    }
    getEndpoint = () => {
        if (this.isDevelopment)
            return CHECKOUT_API_BASE_URL[ENV_DEVELOPMENT];
        if (this.isProduction)
            return CHECKOUT_API_BASE_URL[Environment.PRODUCTION];
        return CHECKOUT_API_BASE_URL[Environment.SANDBOX];
    };
    async loadConfig() {
        if (this.configCache)
            return this.configCache;
        const response = await RemoteConfigFetcher.makeHttpRequest(`${this.getEndpoint()}/${this.version}/config`);
        this.configCache = response.data;
        return this.configCache;
    }
    async loadConfigTokens() {
        if (this.tokensCache)
            return this.tokensCache;
        const response = await RemoteConfigFetcher.makeHttpRequest(`${this.getEndpoint()}/${this.version}/config/tokens`);
        this.tokensCache = response.data;
        return this.tokensCache;
    }
    async getConfig(key) {
        const config = await this.loadConfig();
        if (!config)
            return undefined;
        if (!key)
            return config;
        return config[key];
    }
    async getTokensConfig(chainId) {
        const config = await this.loadConfigTokens();
        if (!config || !config[chainId])
            return {};
        return config[chainId] ?? [];
    }
}

class CheckoutConfigurationError extends Error {
    message;
    constructor(message) {
        super(message);
        this.message = message;
    }
}
const networkMap = (prod, dev) => {
    if (dev)
        return DEV_CHAIN_ID_NETWORK_MAP;
    if (prod)
        return PRODUCTION_CHAIN_ID_NETWORK_MAP;
    return SANDBOX_CHAIN_ID_NETWORK_MAP;
};
// **************************************************** //
// This is duplicated in the widget-lib project.        //
// We are not exposing these functions given that this  //
// to keep the Checkout SDK interface as minimal as     //
// possible.                                            //
// **************************************************** //
const getL1ChainId = (config) => {
    // DevMode and Sandbox will both use Sepolia.
    if (!config.isProduction)
        return ChainId.SEPOLIA;
    return ChainId.ETHEREUM;
};
const getL2ChainId = (config) => {
    if (config.isDevelopment)
        return ChainId.IMTBL_ZKEVM_DEVNET;
    if (config.isProduction)
        return ChainId.IMTBL_ZKEVM_MAINNET;
    return ChainId.IMTBL_ZKEVM_TESTNET;
};
// **************************************************** //
// **************************************************** //
class CheckoutConfiguration {
    // This is a hidden feature that is only available
    // when building the project from source code.
    // This will be used to get around the lack of
    // Environment.DEVELOPMENT
    isDevelopment = "false" === 'true';
    isProduction;
    isOnRampEnabled;
    isSwapEnabled;
    isBridgeEnabled;
    remote;
    environment;
    networkMap;
    constructor(config) {
        if (!Object.values(Environment).includes(config.baseConfig.environment)) {
            throw new CheckoutConfigurationError('Invalid checkout configuration of environment');
        }
        this.environment = config.baseConfig.environment;
        // Developer mode will super set any environment configuration
        this.isProduction = !this.isDevelopment && this.environment === Environment.PRODUCTION;
        this.isOnRampEnabled = config.onRamp?.enable ?? DEFAULT_ON_RAMP_ENABLED;
        this.isSwapEnabled = config.swap?.enable ?? DEFAULT_SWAP_ENABLED;
        this.isBridgeEnabled = config.bridge?.enable ?? DEFAULT_BRIDGE_ENABLED;
        this.networkMap = networkMap(this.isProduction, this.isDevelopment);
        this.remote = new RemoteConfigFetcher({
            isDevelopment: this.isDevelopment,
            isProduction: this.isProduction,
        });
    }
}

const getTokenAllowList = async (config, { type = TokenFilterTypes.ALL, chainId, exclude, }) => {
    let tokens = [];
    let onRampConfig;
    switch (type) {
        case TokenFilterTypes.SWAP:
            // Fetch tokens from dex-tokens config because
            // Dex needs to have a whitelisted list of tokens due to
            // legal reasons.
            tokens = (await config.remote.getConfig('dex'))
                .tokens || [];
            break;
        case TokenFilterTypes.ONRAMP:
            onRampConfig = (await config.remote.getConfig('onramp'));
            // Only using Transak as it's the only on-ramp provider at the moment
            if (!onRampConfig) {
                tokens = [];
            }
            tokens = onRampConfig[OnRampProvider.TRANSAK]?.tokens || [];
            break;
        case TokenFilterTypes.BRIDGE:
        case TokenFilterTypes.ALL:
        default:
            tokens = (await config.remote.getTokensConfig(chainId || getL1ChainId(config))).allowed;
    }
    if (!exclude || exclude?.length === 0)
        return { tokens };
    return {
        tokens: tokens.filter((token) => !exclude.map((e) => e.address).includes(token.address || '')),
    };
};
const isNativeToken = (address) => !address || address.toLocaleLowerCase() === NATIVE;

const CACHE_DATA_TTL = 60; // seconds
/**
 * Blockscout class provides a client abstraction for the Immutable 3rd party indexer.
 */
class Blockscout {
    url;
    nativeToken;
    ttl;
    chainId;
    cacheMap;
    static async makeHttpRequest(url) {
        return axios.get(url);
    }
    setCache(key, data) {
        this.cacheMap[key] = { data, ttl: new Date().getTime() + this.ttl * 1000 };
    }
    getCache(key) {
        const d = this.cacheMap[key];
        if (!d || d.ttl <= new Date().getTime())
            return null;
        return d.data;
    }
    /**
     * Blockscout constructor
     * @param chainId target chain
     * @param ttl cache TTL
     */
    constructor(params) {
        this.chainId = params.chainId;
        this.url = BLOCKSCOUT_CHAIN_URL_MAP[this.chainId].url;
        const native = BLOCKSCOUT_CHAIN_URL_MAP[this.chainId].nativeToken;
        this.nativeToken = {
            address: native.address ?? '',
            decimals: native.decimals.toString(),
            name: native.name,
            symbol: native.symbol,
        };
        this.cacheMap = {};
        this.ttl = params.ttl !== undefined ? params.ttl : CACHE_DATA_TTL;
    }
    /**
     * isChainSupported verifies if the chain is supported by Blockscout
     * @param chainId
     */
    static isChainSupported = (chainId) => Boolean(BLOCKSCOUT_CHAIN_URL_MAP[chainId]);
    /**
     * isBlockscoutError verifies if the error is a Blockscout client error
     * @param err error to evaluate
     */
    static isBlockscoutError = (err) => 'code' in err;
    /**
     * getTokensByWalletAddress fetches the list of tokens (by type) owned by the wallet address.
     * @param walletAddress wallet address
     * @param tokenType token type
     * @param nextPage parameters for the next page, to be provided alongside walletAddress and tokenType
     * @returns list of tokens given the wallet address and the token types
     */
    async getTokensByWalletAddress(params) {
        try {
            let url = `${this.url}/api/v2/addresses/${params.walletAddress}/tokens?type=${params.tokenType}`;
            if (params.nextPage)
                url += `&${new URLSearchParams(params.nextPage)}`;
            // Cache response data to prevent unnecessary requests
            const cached = this.getCache(url);
            if (cached)
                return Promise.resolve(cached);
            const response = await Blockscout.makeHttpRequest(url);
            if (response.status >= 400) {
                return Promise.reject({
                    code: response.status,
                    message: response.statusText,
                });
            }
            // To get around an issue with native tokens being an ERC-20, there is the need
            // to remove IMX from `resp` and add it back in using getNativeTokenByWalletAddress.
            // This has affected some of the early wallets, and it might not be an issue in mainnet
            // however, let's enforce it.
            const data = {
                items: response.data?.items?.filter((token) => token.token.address && token.token.address !== this.nativeToken.address),
                // eslint-disable-next-line @typescript-eslint/naming-convention
                next_page_params: response.data?.next_page_params,
            };
            this.setCache(url, data);
            return Promise.resolve(data);
        }
        catch (err) {
            let code = HttpStatusCode.InternalServerError;
            let message = 'InternalServerError';
            if (axios.isAxiosError(err)) {
                code = err.response?.status || code;
                message = err.message;
            }
            return Promise.reject({ code, message });
        }
    }
    /**
     * getNativeTokenByWalletAddress fetches the native token owned by the wallet address.
     * @param walletAddress wallet address
     * @returns list of tokens given the wallet address and the token types
     */
    async getNativeTokenByWalletAddress(params) {
        try {
            const url = `${this.url}/api/v2/addresses/${params.walletAddress}`;
            // Cache response data to prevent unnecessary requests
            const cached = this.getCache(url);
            if (cached)
                return Promise.resolve(cached);
            const response = await Blockscout.makeHttpRequest(url);
            if (response.status >= 400) {
                return Promise.reject({
                    code: response.status,
                    message: response.statusText,
                });
            }
            const data = {
                token: this.nativeToken,
                value: response.data.coin_balance,
            };
            this.setCache(url, data);
            return Promise.resolve(data);
        }
        catch (err) {
            let code = HttpStatusCode.InternalServerError;
            let message = 'InternalServerError';
            if (axios.isAxiosError(err)) {
                code = err.response?.status || code;
                message = err.message;
            }
            return Promise.reject({ code, message });
        }
    }
}

/* eslint @typescript-eslint/naming-convention: off */
var BlockscoutTokenType;
(function (BlockscoutTokenType) {
    BlockscoutTokenType["ERC20"] = "ERC-20";
})(BlockscoutTokenType || (BlockscoutTokenType = {}));

const debugLogger = (config, debugString, seconds) => {
    // eslint-disable-next-line no-console
    if (!config.isProduction)
        console.info(debugString, seconds);
};
const measureAsyncExecution = async (config, debugString, promise) => {
    const startTime = performance.now();
    const result = await promise;
    const endTime = performance.now();
    const elapsedTimeInSeconds = (endTime - startTime) / 1000;
    debugLogger(config, debugString, elapsedTimeInSeconds);
    return result;
};

const getBalance = async (config, web3Provider, walletAddress) => await withCheckoutError(async () => {
    const networkInfo = await getNetworkInfo(config, web3Provider);
    if (!networkInfo.isSupported) {
        throw new CheckoutError(`Chain:${networkInfo.chainId} is not a supported chain`, CheckoutErrorType.CHAIN_NOT_SUPPORTED_ERROR, { chainName: networkInfo.name });
    }
    const balance = await web3Provider.getBalance(walletAddress);
    return {
        balance,
        formattedBalance: utils.formatUnits(balance, networkInfo.nativeCurrency.decimals),
        token: networkInfo.nativeCurrency,
    };
}, { type: CheckoutErrorType.GET_BALANCE_ERROR });
async function getERC20Balance(web3Provider, walletAddress, contractAddress) {
    return await withCheckoutError(async () => {
        const contract = new Contract(contractAddress, JSON.stringify(ERC20ABI), web3Provider);
        return Promise.all([
            contract.name(),
            contract.symbol(),
            contract.balanceOf(walletAddress),
            contract.decimals(),
        ])
            .then(([name, symbol, balance, decimals]) => {
            const formattedBalance = utils.formatUnits(balance, decimals);
            return {
                balance,
                formattedBalance,
                token: {
                    name,
                    symbol,
                    decimals,
                    address: contractAddress,
                },
            };
        });
    }, { type: CheckoutErrorType.GET_ERC20_BALANCE_ERROR });
}
// Blockscout client singleton per chain id
const blockscoutClientMap = new Map();
// This function is a utility function that can be used to reset the
// blockscout map and therefore clear all the cache.
const resetBlockscoutClientMap = () => blockscoutClientMap.clear();
const getIndexerBalance = async (walletAddress, chainId, filterTokens) => {
    // Shuffle the mapping of the tokens configuration so it is a hashmap
    // for faster access to tokens config objects.
    const shouldFilter = filterTokens !== undefined;
    const mapFilterTokens = Object.assign({}, ...((filterTokens ?? []).map((t) => ({ [t.address || NATIVE]: t }))));
    // Get blockscout client for the given chain
    let blockscoutClient = blockscoutClientMap.get(chainId);
    if (!blockscoutClient) {
        blockscoutClient = new Blockscout({ chainId });
        blockscoutClientMap.set(chainId, blockscoutClient);
    }
    // Hold the items in an array for post-fetching processing
    const items = [];
    const tokenType = BlockscoutTokenType.ERC20;
    const erc20Balances = async (client) => {
        // Given that the widgets aren't yet designed to support pagination,
        // fetch all the possible tokens associated to a given wallet address.
        let resp;
        try {
            do {
                // eslint-disable-next-line no-await-in-loop
                resp = await client.getTokensByWalletAddress({
                    walletAddress,
                    tokenType,
                    nextPage: resp?.next_page_params,
                });
                items.push(...resp.items);
            } while (resp.next_page_params);
        }
        catch (err) {
            // In case of a 404, the wallet is a new wallet that hasn't been indexed by
            // the Blockscout just yet. This happens when a wallet hasn't had any
            // activity on the chain. In this case, simply ignore the error and return
            // no currencies.
            // In case of a malformed wallet address, Blockscout returns a 422, which
            // means we are safe to assume that a 404 is a missing wallet due to inactivity
            // or simply an incorrect wallet address was provided.
            if (err?.code !== HttpStatusCode.NotFound) {
                throw new CheckoutError(err.message || 'InternalServerError | getTokensByWalletAddress', CheckoutErrorType.GET_INDEXER_BALANCE_ERROR, err);
            }
        }
    };
    const nativeBalances = async (client) => {
        try {
            const respNative = await client.getNativeTokenByWalletAddress({ walletAddress });
            respNative.token.address ||= NATIVE;
            items.push(respNative);
        }
        catch (err) {
            // In case of a 404, the wallet is a new wallet that hasn't been indexed by
            // the Blockscout just yet. This happens when a wallet hasn't had any
            // activity on the chain. In this case, simply ignore the error and return
            // no currencies.
            // In case of a malformed wallet address, Blockscout returns a 422, which
            // means we are safe to assume that a 404 is a missing wallet due to inactivity
            // or simply an incorrect wallet address was provided.
            if (err?.code !== HttpStatusCode.NotFound) {
                throw new CheckoutError(err.message || 'InternalServerError | getNativeTokenByWalletAddress', CheckoutErrorType.GET_INDEXER_BALANCE_ERROR, err);
            }
        }
    };
    // Promise all() rather than allSettled() so that the function can fail fast.
    await Promise.all([
        erc20Balances(blockscoutClient),
        nativeBalances(blockscoutClient),
    ]);
    const balances = [];
    items.forEach((item) => {
        if (shouldFilter && !mapFilterTokens[item.token.address])
            return;
        const tokenData = item.token || {};
        const balance = BigNumber.from(item.value);
        let decimals = parseInt(tokenData.decimals, 10);
        if (Number.isNaN(decimals))
            decimals = DEFAULT_TOKEN_DECIMALS$1;
        const token = {
            ...tokenData,
            decimals,
        };
        const formattedBalance = utils.formatUnits(item.value, token.decimals);
        balances.push({ balance, formattedBalance, token });
    });
    return { balances };
};
const getBalances = async (config, web3Provider, walletAddress, tokens) => {
    const allBalancePromises = [];
    tokens
        .forEach((token) => {
        if (!token.address || token.address.toLocaleLowerCase() === NATIVE) {
            allBalancePromises.push(getBalance(config, web3Provider, walletAddress));
        }
        else {
            allBalancePromises.push(getERC20Balance(web3Provider, walletAddress, token.address));
        }
    });
    const balanceResults = await Promise.allSettled(allBalancePromises);
    const balances = balanceResults.filter((result) => result.status === 'fulfilled').map((result) => result.value);
    return { balances };
};
const getAllBalances = async (config, web3Provider, walletAddress, chainId) => {
    // eslint-disable-next-line no-param-reassign
    chainId ||= await web3Provider.getSigner().getChainId();
    const { tokens } = await getTokenAllowList(config, {
        type: TokenFilterTypes.ALL,
        chainId,
    });
    // In order to prevent unnecessary RPC calls
    // let's use the Indexer if available for the
    // given chain.
    let flag = false;
    try {
        flag = (await config.remote.getTokensConfig(chainId)).blockscout || flag;
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
    if (flag && Blockscout.isChainSupported(chainId)) {
        // This is a hack because the widgets are still using the tokens symbol
        // to drive the conversions. If we remove all the token symbols from e.g. zkevm
        // then we would not have fiat conversions.
        // Please remove this hack once https://immutable.atlassian.net/browse/WT-1710
        // is done.
        const isL1Chain = getL1ChainId(config) === chainId;
        return await measureAsyncExecution(config, `Time to fetch balances using blockscout for ${chainId}`, getIndexerBalance(walletAddress, chainId, isL1Chain ? tokens : undefined));
    }
    // This fallback to use ERC20s calls which is a best effort solution
    // Fails in fetching data from the RCP calls might result in some
    // missing data.
    return await measureAsyncExecution(config, `Time to fetch balances using RPC for ${chainId}`, getBalances(config, web3Provider, walletAddress, tokens));
};

async function checkIsWalletConnected(web3Provider) {
    if (!web3Provider?.provider?.request) {
        throw new CheckoutError('Check wallet connection request failed', CheckoutErrorType.PROVIDER_REQUEST_FAILED_ERROR, {
            rpcMethod: WalletAction.CHECK_CONNECTION,
        });
    }
    let accounts = [];
    try {
        accounts = await web3Provider.provider.request({
            method: WalletAction.CHECK_CONNECTION,
            params: [],
        });
    }
    catch (err) {
        throw new CheckoutError('Check wallet connection request failed', CheckoutErrorType.PROVIDER_REQUEST_FAILED_ERROR, {
            rpcMethod: WalletAction.CHECK_CONNECTION,
        });
    }
    // accounts[0] will have the active account if connected.
    return {
        isConnected: accounts && accounts.length > 0,
        walletAddress: accounts[0] ?? '',
    };
}
async function connectSite(web3Provider) {
    if (!web3Provider || !web3Provider.provider?.request) {
        throw new CheckoutError('Incompatible provider', CheckoutErrorType.PROVIDER_REQUEST_MISSING_ERROR, { details: 'Attempting to connect with an incompatible provider' });
    }
    await withCheckoutError(async () => {
        if (!web3Provider.provider.request)
            return;
        // this makes the request to the wallet to connect i.e request eth accounts ('eth_requestAccounts')
        await web3Provider.provider.request({
            method: WalletAction.CONNECT,
            params: [],
        });
    }, { type: CheckoutErrorType.USER_REJECTED_REQUEST_ERROR });
    return web3Provider;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
async function getMetaMaskProvider() {
    const provider = await withCheckoutError(async () => await detectEthereumProvider(), { type: CheckoutErrorType.METAMASK_PROVIDER_ERROR });
    if (!provider || !provider.request) {
        throw new CheckoutError('No MetaMask provider installed.', CheckoutErrorType.METAMASK_PROVIDER_ERROR);
    }
    return new Web3Provider(provider);
}
async function createProvider(walletProviderName, passport) {
    let provider = null;
    switch (walletProviderName) {
        case WalletProviderName.PASSPORT: {
            if (passport) {
                provider = new Web3Provider(passport.connectEvm());
            }
            else {
                // eslint-disable-next-line no-console
                console.error('WalletProviderName was PASSPORT but the passport instance was not provided to the Checkout constructor');
                throw new CheckoutError('Passport not provided', CheckoutErrorType.DEFAULT_PROVIDER_ERROR);
            }
            break;
        }
        case WalletProviderName.METAMASK: {
            provider = await getMetaMaskProvider();
            break;
        }
        default:
            // eslint-disable-next-line no-console
            console.error('The WalletProviderName that was provided is not supported');
            throw new CheckoutError('Provider not supported', CheckoutErrorType.DEFAULT_PROVIDER_ERROR);
    }
    return {
        provider,
        walletProviderName,
    };
}

// this function needs to be in a separate file to prevent circular dependencies with ./network
function isWeb3Provider(web3Provider) {
    if (web3Provider && web3Provider.provider?.request && typeof web3Provider.provider.request === 'function') {
        return true;
    }
    return false;
}
async function validateProvider(config, web3Provider, validateProviderOptions) {
    return withCheckoutError(async () => {
        if (web3Provider.provider?.isPassport) {
            // if Passport skip the validation checks
            return web3Provider;
        }
        if (!isWeb3Provider(web3Provider)) {
            throw new CheckoutError('Parsed provider is not a valid Web3Provider', CheckoutErrorType.WEB3_PROVIDER_ERROR);
        }
        // this sets the default options and overrides them with any parsed options
        const options = {
            ...validateProviderDefaults,
            ...validateProviderOptions,
        };
        const underlyingChainId = await getUnderlyingChainId(web3Provider);
        let web3ChainId = web3Provider.network?.chainId;
        try {
            web3ChainId = web3Provider.network?.chainId;
            if (!web3ChainId) {
                web3ChainId = (await web3Provider.getNetwork()).chainId;
            }
        }
        catch (err) {
            throw new CheckoutError('Unable to detect the web3Provider network', CheckoutErrorType.WEB3_PROVIDER_ERROR);
        }
        if (web3ChainId !== underlyingChainId && !options.allowMistmatchedChainId) {
            throw new CheckoutError('Your wallet has changed network, please switch to a supported network', CheckoutErrorType.WEB3_PROVIDER_ERROR);
        }
        const allowedNetworks = await getNetworkAllowList(config, {
            type: NetworkFilterTypes.ALL,
        });
        const isAllowed = allowedNetworks.networks.some((network) => network.chainId === underlyingChainId);
        if (!isAllowed && !options.allowUnsupportedProvider) {
            throw new CheckoutError('Your wallet is connected to an unsupported network, please switch to a supported network', CheckoutErrorType.WEB3_PROVIDER_ERROR);
        }
        return web3Provider;
    }, {
        type: CheckoutErrorType.WEB3_PROVIDER_ERROR,
    });
}

async function getWalletAllowList(params) {
    const walletList = [];
    const excludedWalletProvider = params.exclude?.map((wp) => wp.walletProviderName) ?? [];
    let walletProviderNames = Object.values(WalletProviderName);
    if (excludedWalletProvider.length !== 0) {
        walletProviderNames = walletProviderNames.filter((wp) => !excludedWalletProvider.includes(wp));
    }
    for (const value of walletProviderNames) {
        walletList.push({
            walletProviderName: value,
        });
    }
    return {
        wallets: walletList,
    };
}

const setTransactionGasLimits = (transaction) => {
    const rawTx = transaction;
    rawTx.maxFeePerGas = GAS_OVERRIDES.maxFeePerGas;
    rawTx.maxPriorityFeePerGas = GAS_OVERRIDES.maxPriorityFeePerGas;
    return rawTx;
};
const sendTransaction = async (web3Provider, transaction) => {
    try {
        const signer = web3Provider.getSigner();
        const rawTx = setTransactionGasLimits(transaction);
        const transactionResponse = await signer.sendTransaction(rawTx);
        return {
            transactionResponse,
        };
    }
    catch (err) {
        if (err.code === ethers.errors.INSUFFICIENT_FUNDS) {
            throw new CheckoutError(err.message, CheckoutErrorType.INSUFFICIENT_FUNDS);
        }
        if (err.code === ethers.errors.ACTION_REJECTED) {
            throw new CheckoutError(err.message, CheckoutErrorType.USER_REJECTED_REQUEST_ERROR);
        }
        if (err.code === ethers.errors.UNPREDICTABLE_GAS_LIMIT) {
            throw new CheckoutError(err.message, CheckoutErrorType.UNPREDICTABLE_GAS_LIMIT);
        }
        throw new CheckoutError(err.message, CheckoutErrorType.TRANSACTION_FAILED);
    }
};

const doesChainSupportEIP1559 = (feeData) => !!feeData.maxFeePerGas && !!feeData.maxPriorityFeePerGas;
const getGasPriceInWei = (feeData) => {
    if (doesChainSupportEIP1559(feeData)) {
        return BigNumber.from(feeData.maxFeePerGas).add(BigNumber.from(feeData.maxPriorityFeePerGas));
    }
    if (feeData.gasPrice)
        return BigNumber.from(feeData.gasPrice);
    return null;
};

const GAS_LIMIT = 140000;
const getGasEstimates = async (provider) => {
    const txnGasLimitInWei = GAS_LIMIT; // todo: fetch gasLimit from bridgeSDK when they add new fn
    const feeData = await provider.getFeeData();
    const gasPriceInWei = getGasPriceInWei(feeData);
    if (!gasPriceInWei)
        return undefined;
    return gasPriceInWei.mul(txnGasLimitInWei);
};
async function getBridgeEstimatedGas(provider, withApproval) {
    const estimatedAmount = await getGasEstimates(provider);
    // Return an undefined value for estimatedAmount
    if (!estimatedAmount)
        return { estimatedAmount };
    if (!withApproval)
        return { estimatedAmount };
    return { estimatedAmount: estimatedAmount.add(estimatedAmount) };
}
async function getBridgeFeeEstimate$1(tokenBridge, tokenAddress) {
    const bridgeFeeResponse = await tokenBridge.getFee({ token: tokenAddress });
    return {
        bridgeFee: { estimatedAmount: bridgeFeeResponse.feeAmount },
        bridgeable: bridgeFeeResponse.bridgeable,
    };
}

async function createBridgeInstance(fromChainId, toChainId, readOnlyProviders, config) {
    const rootChainProvider = readOnlyProviders.get(fromChainId);
    const childChainProvider = readOnlyProviders.get(toChainId);
    if (!rootChainProvider) {
        throw new CheckoutError(`Chain:${fromChainId} is not a supported chain`, CheckoutErrorType.CHAIN_NOT_SUPPORTED_ERROR);
    }
    if (!childChainProvider) {
        throw new CheckoutError(`Chain:${toChainId} is not a supported chain`, CheckoutErrorType.CHAIN_NOT_SUPPORTED_ERROR);
    }
    let bridgeInstance = ETH_SEPOLIA_TO_ZKEVM_TESTNET;
    if (config.isDevelopment)
        bridgeInstance = ETH_SEPOLIA_TO_ZKEVM_DEVNET;
    if (config.isProduction)
        bridgeInstance = ETH_MAINNET_TO_ZKEVM_MAINNET;
    const bridgeConfig = new BridgeConfiguration({
        baseConfig: new ImmutableConfiguration({ environment: config.environment }),
        bridgeInstance,
        rootProvider: rootChainProvider,
        childProvider: childChainProvider,
    });
    return new TokenBridge(bridgeConfig);
}
async function createExchangeInstance(chainId, config) {
    const dexConfig = (await config.remote.getConfig('dex'));
    return new Exchange({
        chainId,
        baseConfig: new ImmutableConfiguration({
            environment: config.environment,
        }),
        overrides: dexConfig?.overrides,
    });
}
function createOrderbookInstance(config) {
    return new Orderbook({
        baseConfig: {
            environment: config.environment,
        },
    });
}
function createBlockchainDataInstance(config) {
    return new BlockchainData({
        baseConfig: {
            environment: config.environment,
        },
    });
}

function getTokenContract(address, contractInterface, signerOrProvider) {
    return new Contract(address, contractInterface, signerOrProvider);
}

const DUMMY_WALLET_ADDRESS = '0x0000000000000000000000000000000000000001';
const DEFAULT_TOKEN_DECIMALS = 18;
async function bridgeToL2GasEstimator(readOnlyProviders, config, isSpendingCapApprovalRequired) {
    const fromChainId = getL1ChainId(config);
    const toChainId = getL2ChainId(config);
    const gasEstimateTokensConfig = (await config.remote.getConfig('gasEstimateTokens'));
    const { fromAddress } = gasEstimateTokensConfig[fromChainId].bridgeToL2Addresses;
    const provider = readOnlyProviders.get(fromChainId);
    if (!provider)
        throw new Error(`Missing JsonRpcProvider for chain id: ${fromChainId}`);
    try {
        let gasFees = {};
        const getGasFees = async () => {
            gasFees = await getBridgeEstimatedGas(provider, isSpendingCapApprovalRequired);
            gasFees.token = config.networkMap.get(fromChainId)?.nativeCurrency;
        };
        let bridgeFees = {
            bridgeFee: {},
            bridgeable: false,
        };
        const getBridgeFees = async () => {
            const tokenBridge = await createBridgeInstance(fromChainId, toChainId, readOnlyProviders, config);
            bridgeFees = await getBridgeFeeEstimate$1(tokenBridge, fromAddress);
        };
        await Promise.all([
            getGasFees(),
            getBridgeFees(),
        ]);
        return {
            gasEstimateType: GasEstimateType.BRIDGE_TO_L2,
            gasFee: gasFees,
            bridgeFee: {
                estimatedAmount: bridgeFees.bridgeFee?.estimatedAmount,
                token: bridgeFees.bridgeFee?.token,
            },
            bridgeable: bridgeFees.bridgeable,
        };
    }
    catch {
        // In the case of an error, just return an empty gas & bridge fee estimate
        return {
            gasEstimateType: GasEstimateType.BRIDGE_TO_L2,
            gasFee: {},
            bridgeFee: {},
            bridgeable: false,
        };
    }
}
async function swapGasEstimator(config) {
    const chainId = getL2ChainId(config);
    const gasEstimateTokensConfig = (await config.remote.getConfig('gasEstimateTokens'));
    const { inAddress, outAddress } = gasEstimateTokensConfig[chainId]
        .swapAddresses;
    try {
        const exchange = await createExchangeInstance(chainId, config);
        // Create a fake transaction to get the gas from the quote
        const { swap } = await exchange.getUnsignedSwapTxFromAmountIn(DUMMY_WALLET_ADDRESS, inAddress, outAddress, BigNumber.from(utils.parseUnits('1', DEFAULT_TOKEN_DECIMALS)));
        if (!swap.gasFeeEstimate) {
            return {
                gasEstimateType: GasEstimateType.SWAP,
                gasFee: {},
            };
        }
        return {
            gasEstimateType: GasEstimateType.SWAP,
            gasFee: {
                estimatedAmount: swap.gasFeeEstimate.value ? BigNumber.from(swap.gasFeeEstimate.value) : undefined,
                token: {
                    address: swap.gasFeeEstimate.token.address,
                    symbol: swap.gasFeeEstimate.token.symbol ?? '',
                    name: swap.gasFeeEstimate.token.name ?? '',
                    decimals: swap.gasFeeEstimate.token.decimals ?? DEFAULT_TOKEN_DECIMALS,
                },
            },
        };
    }
    catch {
        // In the case of an error, just return an empty gas fee estimate
        return {
            gasEstimateType: GasEstimateType.SWAP,
            gasFee: {},
        };
    }
}
async function gasEstimator(params, readOnlyProviders, config) {
    switch (params.gasEstimateType) {
        case GasEstimateType.BRIDGE_TO_L2:
            return await bridgeToL2GasEstimator(readOnlyProviders, config, params.isSpendingCapApprovalRequired);
        case GasEstimateType.SWAP:
            return await swapGasEstimator(config);
        default:
            throw new CheckoutError('Invalid type provided for gasEstimateType', CheckoutErrorType.INVALID_GAS_ESTIMATE_TYPE);
    }
}

const allowanceAggregator = (erc20allowances, erc721allowances) => {
    const aggregatedAllowances = [];
    if (!erc20allowances.sufficient) {
        for (const allowance of erc20allowances.allowances) {
            if (!allowance.sufficient)
                aggregatedAllowances.push(allowance);
        }
    }
    if (!erc721allowances.sufficient) {
        for (const allowance of erc721allowances.allowances) {
            if (!allowance.sufficient)
                aggregatedAllowances.push(allowance);
        }
    }
    return aggregatedAllowances;
};

const nativeAggregator = (itemRequirements) => {
    const aggregatedMap = new Map();
    const aggregatedItemRequirements = [];
    itemRequirements.forEach((itemRequirement) => {
        const { type } = itemRequirement;
        if (type !== ItemType.NATIVE) {
            aggregatedItemRequirements.push(itemRequirement);
            return;
        }
        const { amount } = itemRequirement;
        const aggregateItem = aggregatedMap.get(type);
        if (aggregateItem && aggregateItem.type === ItemType.NATIVE) {
            aggregateItem.amount = BigNumber.from(aggregateItem.amount).add(amount);
        }
        else {
            aggregatedMap.set(type, { ...itemRequirement });
        }
    });
    return aggregatedItemRequirements.concat(Array.from(aggregatedMap.values()));
};
const erc20ItemAggregator = (itemRequirements) => {
    const aggregatedMap = new Map();
    const aggregatedItemRequirements = [];
    itemRequirements.forEach((itemRequirement) => {
        const { type } = itemRequirement;
        if (type !== ItemType.ERC20) {
            aggregatedItemRequirements.push(itemRequirement);
            return;
        }
        const { contractAddress, spenderAddress, amount } = itemRequirement;
        const key = `${contractAddress}${spenderAddress}`;
        const aggregateItem = aggregatedMap.get(key);
        if (aggregateItem && aggregateItem.type === ItemType.ERC20) {
            aggregateItem.amount = BigNumber.from(aggregateItem.amount).add(amount);
        }
        else {
            aggregatedMap.set(key, { ...itemRequirement });
        }
    });
    return aggregatedItemRequirements.concat(Array.from(aggregatedMap.values()));
};
const erc721ItemAggregator = (itemRequirements) => {
    const aggregatedMap = new Map();
    const aggregatedItemRequirements = [];
    itemRequirements.forEach((itemRequirement) => {
        const { type } = itemRequirement;
        if (type !== ItemType.ERC721) {
            aggregatedItemRequirements.push(itemRequirement);
            return;
        }
        const { contractAddress, spenderAddress, id } = itemRequirement;
        const key = `${contractAddress}${spenderAddress}${id}`;
        const aggregateItem = aggregatedMap.get(key);
        if (!aggregateItem)
            aggregatedMap.set(key, { ...itemRequirement });
    });
    return aggregatedItemRequirements.concat(Array.from(aggregatedMap.values()));
};
const itemAggregator = (itemRequirements) => erc721ItemAggregator(erc20ItemAggregator(nativeAggregator(itemRequirements)));

// Gets the amount an address has allowed to be spent by the spender for the ERC20.
const getERC20Allowance = async (provider, ownerAddress, contractAddress, spenderAddress) => {
    try {
        const contract = new Contract(contractAddress, JSON.stringify(ERC20ABI), provider);
        return await contract.allowance(ownerAddress, spenderAddress);
    }
    catch (err) {
        throw new CheckoutError('Failed to get the allowance for ERC20', CheckoutErrorType.GET_ERC20_ALLOWANCE_ERROR, { contractAddress });
    }
};
// Returns the approval transaction for the ERC20 that the owner can sign
// to approve the spender spending the provided amount of ERC20.
const getERC20ApprovalTransaction = async (provider, ownerAddress, contractAddress, spenderAddress, amount) => {
    try {
        const contract = new Contract(contractAddress, JSON.stringify(ERC20ABI), provider);
        const approveTransaction = await contract.populateTransaction.approve(spenderAddress, amount);
        if (approveTransaction)
            approveTransaction.from = ownerAddress;
        return approveTransaction;
    }
    catch {
        throw new CheckoutError('Failed to get the approval transaction for ERC20', CheckoutErrorType.GET_ERC20_ALLOWANCE_ERROR, { contractAddress });
    }
};
const hasERC20Allowances = async (provider, ownerAddress, itemRequirements) => {
    let sufficient = true;
    const sufficientAllowances = [];
    const erc20s = new Map();
    const allowancePromises = new Map();
    const insufficientERC20s = new Map();
    const transactionPromises = new Map();
    // Populate maps for both the ERC20 data and promises to get the allowance using the same key
    // so the promise and data can be linked together when the promise resolves
    for (const itemRequirement of itemRequirements) {
        if (itemRequirement.type !== ItemType.ERC20)
            continue;
        const { contractAddress, spenderAddress } = itemRequirement;
        const key = `${contractAddress}${spenderAddress}`;
        erc20s.set(key, itemRequirement);
        allowancePromises.set(key, getERC20Allowance(provider, ownerAddress, contractAddress, spenderAddress));
    }
    const allowances = await Promise.all(allowancePromises.values());
    const allowancePromiseIds = Array.from(allowancePromises.keys());
    // Iterate through the allowance promises and get the ERC20 data from the ERC20 map
    // If the allowance returned for that ERC20 is sufficient then just set the item requirements
    // If the allowance is insufficient then set the delta and a promise for the approval transaction
    for (let index = 0; index < allowances.length; index++) {
        const itemRequirement = erc20s.get(allowancePromiseIds[index]);
        if (!itemRequirement || itemRequirement.type !== ItemType.ERC20)
            continue;
        if (allowances[index].gte(itemRequirement.amount)) {
            sufficientAllowances.push({
                sufficient: true,
                itemRequirement,
            });
            continue;
        }
        sufficient = false; // Set sufficient false on the root of the return object when an ERC20 is insufficient
        const { contractAddress, spenderAddress } = itemRequirement;
        const key = `${contractAddress}${spenderAddress}`;
        const delta = itemRequirement.amount.sub(allowances[index]);
        // Create maps for both the insufficient ERC20 data and the transaction promises using the same key so the results can be merged
        insufficientERC20s.set(key, {
            type: ItemType.ERC20,
            sufficient: false,
            delta,
            itemRequirement,
            approvalTransaction: undefined,
        });
        transactionPromises.set(key, getERC20ApprovalTransaction(provider, ownerAddress, contractAddress, spenderAddress, delta));
    }
    // Resolves the approval transactions and merges them with the insufficient ERC20 data
    const transactions = await Promise.all(transactionPromises.values());
    const transactionPromiseIds = Array.from(transactionPromises.keys());
    transactions.forEach((transaction, index) => {
        const insufficientERC20 = insufficientERC20s.get(transactionPromiseIds[index]);
        if (!insufficientERC20)
            return;
        if (insufficientERC20.sufficient)
            return;
        insufficientERC20.approvalTransaction = transaction;
    });
    // Merge the allowance arrays to get both the sufficient allowances and the insufficient ERC20 allowances
    return { sufficient, allowances: sufficientAllowances.concat(Array.from(insufficientERC20s.values())) };
};

// Returns true if the spender address is approved for all ERC721s of this collection
const getERC721ApprovedForAll = async (provider, ownerAddress, contractAddress, spenderAddress) => {
    try {
        const contract = new Contract(contractAddress, JSON.stringify(ERC721ABI), provider);
        return await contract.isApprovedForAll(ownerAddress, spenderAddress);
    }
    catch (err) {
        throw new CheckoutError('Failed to check approval for all ERC721s of collection', CheckoutErrorType.GET_ERC721_ALLOWANCE_ERROR, { ownerAddress, contractAddress, spenderAddress });
    }
};
// Returns a populated transaction to approve the ERC721 for the spender.
const getApproveTransaction = async (provider, ownerAddress, contractAddress, spenderAddress, id) => {
    try {
        const contract = new Contract(contractAddress, JSON.stringify(ERC721ABI), provider);
        const transaction = await contract.populateTransaction.approve(spenderAddress, id);
        if (transaction)
            transaction.from = ownerAddress;
        return transaction;
    }
    catch (err) {
        throw new CheckoutError('Failed to get the approval transaction for ERC721', CheckoutErrorType.GET_ERC721_ALLOWANCE_ERROR, {
            id: id.toString(), contractAddress, spenderAddress, ownerAddress,
        });
    }
};
// Returns the address that is approved for the ERC721.
// This is sufficient when the spender is the approved address
const getERC721ApprovedAddress = async (provider, contractAddress, id) => {
    try {
        const contract = new Contract(contractAddress, JSON.stringify(ERC721ABI), provider);
        return await contract.getApproved(id);
    }
    catch (err) {
        throw new CheckoutError('Failed to get approved address for ERC721', CheckoutErrorType.GET_ERC721_ALLOWANCE_ERROR, { id: id.toString(), contractAddress });
    }
};
const convertIdToNumber = (id, contractAddress) => {
    const parsedId = parseInt(id, 10);
    if (Number.isNaN(parsedId)) {
        throw new CheckoutError('Invalid ERC721 ID', CheckoutErrorType.GET_ERC721_ALLOWANCE_ERROR, { id, contractAddress });
    }
    return parsedId;
};
const getApprovedCollections = async (provider, itemRequirements, owner) => {
    const approvedCollections = new Map();
    const approvedForAllPromises = new Map();
    for (const itemRequirement of itemRequirements) {
        if (itemRequirement.type !== ItemType.ERC721)
            continue;
        const { contractAddress, spenderAddress } = itemRequirement;
        const key = `${contractAddress}-${spenderAddress}`;
        approvedCollections.set(key, false);
        if (approvedForAllPromises.has(key))
            continue;
        approvedForAllPromises.set(key, getERC721ApprovedForAll(provider, owner, contractAddress, spenderAddress));
    }
    const approvals = await Promise.all(approvedForAllPromises.values());
    const keys = Array.from(approvedForAllPromises.keys());
    approvals.forEach((approval, index) => {
        approvedCollections.set(keys[index], approval);
    });
    return approvedCollections;
};
const hasERC721Allowances = async (provider, ownerAddress, itemRequirements) => {
    let sufficient = true;
    const sufficientAllowances = [];
    // Setup maps to be able to link data back to the associated promises
    const erc721s = new Map();
    const approvedAddressPromises = new Map();
    const insufficientERC721s = new Map();
    const transactionPromises = new Map();
    // Check if there are any collections with approvals for all ERC721s for a given spender
    const approvedCollections = await getApprovedCollections(provider, itemRequirements, ownerAddress);
    // Populate maps for both the ERC721 data and promises to get the approved addresses using the same key
    // so the promise and data can be linked together when the promise is resolved
    for (const itemRequirement of itemRequirements) {
        if (itemRequirement.type !== ItemType.ERC721)
            continue;
        const { contractAddress, id, spenderAddress } = itemRequirement;
        // If the collection is approved for all then just set the item requirements and sufficient true
        const approvedForAllKey = `${contractAddress}-${spenderAddress}`;
        const approvedForAll = approvedCollections.get(approvedForAllKey);
        if (approvedForAll) {
            sufficientAllowances.push({
                sufficient: true,
                itemRequirement,
            });
            continue;
        }
        // If collection not approved for all then check if the given ERC721 is approved for the spender
        const key = `${contractAddress}-${id}`;
        const convertedId = convertIdToNumber(id, contractAddress);
        erc721s.set(key, itemRequirement);
        approvedAddressPromises.set(key, getERC721ApprovedAddress(provider, contractAddress, convertedId));
    }
    const approvedAddresses = await Promise.all(approvedAddressPromises.values());
    const approvedAddressPromiseIds = Array.from(approvedAddressPromises.keys());
    // Iterate through the approved address promises and get the ERC721 data from the ERC721 map
    // If the approved address returned for that ERC721 is for the spender then just set the item requirements and sufficient true
    // If the approved address does not match the spender then return the approval transaction
    for (let index = 0; index < approvedAddresses.length; index++) {
        const itemRequirement = erc721s.get(approvedAddressPromiseIds[index]);
        if (!itemRequirement || itemRequirement.type !== ItemType.ERC721)
            continue;
        if (approvedAddresses[index] === itemRequirement.spenderAddress) {
            sufficientAllowances.push({
                sufficient: true,
                itemRequirement,
            });
            continue;
        }
        sufficient = false; // Set sufficient false on the root of the return object when an ERC721 is insufficient
        const { contractAddress, id, spenderAddress } = itemRequirement;
        const key = `${contractAddress}-${id}`;
        const convertedId = convertIdToNumber(id, contractAddress);
        // Create maps for both the insufficient ERC721 data and the transaction promises using the same key so the results can be merged
        insufficientERC721s.set(key, {
            type: ItemType.ERC721,
            sufficient: false,
            itemRequirement,
            approvalTransaction: undefined,
        });
        transactionPromises.set(key, getApproveTransaction(provider, ownerAddress, contractAddress, spenderAddress, convertedId));
    }
    // Resolves the approval transactions and merges them with the insufficient ERC721 data
    const transactions = await Promise.all(transactionPromises.values());
    const transactionPromiseIds = Array.from(transactionPromises.keys());
    transactions.forEach((transaction, index) => {
        const insufficientERC721 = insufficientERC721s.get(transactionPromiseIds[index]);
        if (!insufficientERC721)
            return;
        if (insufficientERC721.sufficient)
            return;
        insufficientERC721.approvalTransaction = transaction;
    });
    // Merge the allowance arrays to get both the sufficient allowances and the insufficient ERC721 allowances
    return { sufficient, allowances: sufficientAllowances.concat(Array.from(insufficientERC721s.values())) };
};

const nativeBalanceAggregator = (itemRequirements) => {
    const aggregatedMap = new Map();
    const aggregatedItemRequirements = [];
    itemRequirements.forEach((itemRequirement) => {
        const { type } = itemRequirement;
        if (type !== ItemType.NATIVE) {
            aggregatedItemRequirements.push(itemRequirement);
            return;
        }
        const { amount } = itemRequirement;
        const aggregateItem = aggregatedMap.get(type);
        if (aggregateItem && aggregateItem.type === ItemType.NATIVE) {
            aggregateItem.amount = BigNumber.from(aggregateItem.amount).add(amount);
        }
        else {
            aggregatedMap.set(type, { ...itemRequirement });
        }
    });
    return aggregatedItemRequirements.concat(Array.from(aggregatedMap.values()));
};
const erc20BalanceAggregator = (itemRequirements) => {
    const aggregatedMap = new Map();
    const aggregatedItemRequirements = [];
    itemRequirements.forEach((itemRequirement) => {
        const { type } = itemRequirement;
        if (type !== ItemType.ERC20) {
            aggregatedItemRequirements.push(itemRequirement);
            return;
        }
        const { contractAddress, amount } = itemRequirement;
        const key = `${contractAddress}`;
        const aggregateItem = aggregatedMap.get(key);
        if (aggregateItem && aggregateItem.type === ItemType.ERC20) {
            aggregateItem.amount = BigNumber.from(aggregateItem.amount).add(amount);
        }
        else {
            aggregatedMap.set(key, { ...itemRequirement });
        }
    });
    return aggregatedItemRequirements.concat(Array.from(aggregatedMap.values()));
};
const erc721BalanceAggregator = (itemRequirements) => {
    const aggregatedMap = new Map();
    const aggregatedItemRequirements = [];
    itemRequirements.forEach((itemRequirement) => {
        const { type } = itemRequirement;
        if (type !== ItemType.ERC721) {
            aggregatedItemRequirements.push(itemRequirement);
            return;
        }
        const { contractAddress, id } = itemRequirement;
        const key = `${contractAddress}${id}`;
        const aggregateItem = aggregatedMap.get(key);
        if (!aggregateItem)
            aggregatedMap.set(key, { ...itemRequirement });
    });
    return aggregatedItemRequirements.concat(Array.from(aggregatedMap.values()));
};
const balanceAggregator = (itemRequirements) => erc721BalanceAggregator(erc20BalanceAggregator(nativeBalanceAggregator(itemRequirements)));

/* eslint-disable arrow-body-style */
const getTokensFromRequirements = (itemRequirements) => itemRequirements
    .map((itemRequirement) => {
    if (itemRequirement.type === ItemType.NATIVE) {
        return {
            address: NATIVE,
        };
    }
    return {
        address: itemRequirement.contractAddress,
    };
});
/**
 * Gets the balance requirement with delta for an ERC721 requirement.
 */
const getERC721BalanceRequirement = (itemRequirement, balances) => {
    const requiredBalance = BigNumber.from(1);
    // Find the requirements related balance
    const itemBalanceResult = balances.find((balance) => {
        const balanceERC721Result = balance;
        return balanceERC721Result.contractAddress === itemRequirement.contractAddress
            && balanceERC721Result.id === itemRequirement.id;
    });
    // Calculate the balance delta
    const sufficient = (requiredBalance.isNegative() || requiredBalance.isZero())
        || (itemBalanceResult?.balance.gte(requiredBalance) ?? false);
    const delta = requiredBalance.sub(itemBalanceResult?.balance ?? BigNumber.from(0));
    let erc721BalanceResult = itemBalanceResult;
    if (!erc721BalanceResult) {
        erc721BalanceResult = {
            type: ItemType.ERC721,
            balance: BigNumber.from(0),
            formattedBalance: '0',
            contractAddress: itemRequirement.contractAddress,
            id: itemRequirement.id,
        };
    }
    return {
        sufficient,
        type: ItemType.ERC721,
        delta: {
            balance: delta,
            formattedBalance: delta.toString(),
        },
        current: erc721BalanceResult,
        required: {
            ...erc721BalanceResult,
            balance: BigNumber.from(1),
            formattedBalance: '1',
        },
    };
};
/**
 * Gets the balance requirement for a NATIVE or ERC20 requirement.
 */
const getTokenBalanceRequirement = (itemRequirement, balances) => {
    let itemBalanceResult;
    // Get the requirements related balance
    if (itemRequirement.type === ItemType.ERC20) {
        itemBalanceResult = balances.find((balance) => {
            return balance.token?.address === itemRequirement.contractAddress;
        });
    }
    else if (itemRequirement.type === ItemType.NATIVE) {
        itemBalanceResult = balances.find((balance) => {
            return isNativeToken(balance.token?.address);
        });
    }
    // Calculate the balance delta
    const requiredBalance = itemRequirement.amount;
    const sufficient = (requiredBalance.isNegative() || requiredBalance.isZero())
        || (itemBalanceResult?.balance.gte(requiredBalance) ?? false);
    const delta = requiredBalance.sub(itemBalanceResult?.balance ?? BigNumber.from(0));
    let name = '';
    let symbol = '';
    let decimals = DEFAULT_TOKEN_DECIMALS$1;
    if (itemBalanceResult) {
        decimals = itemBalanceResult.token?.decimals ?? DEFAULT_TOKEN_DECIMALS$1;
        name = itemBalanceResult.token.name;
        symbol = itemBalanceResult.token.symbol;
    }
    let tokenBalanceResult = itemBalanceResult;
    if (itemRequirement.type === ItemType.NATIVE) {
        // No token balance so mark as zero native
        if (!tokenBalanceResult) {
            tokenBalanceResult = {
                type: ItemType.NATIVE,
                balance: BigNumber.from(0),
                formattedBalance: '0',
                token: {
                    name,
                    symbol,
                    decimals: DEFAULT_TOKEN_DECIMALS$1,
                },
            };
        }
        return {
            sufficient,
            type: ItemType.NATIVE,
            delta: {
                balance: delta,
                formattedBalance: utils.formatUnits(delta, decimals),
            },
            current: {
                ...tokenBalanceResult,
                type: ItemType.NATIVE,
            },
            required: {
                ...tokenBalanceResult,
                type: ItemType.NATIVE,
                balance: BigNumber.from(itemRequirement.amount),
                formattedBalance: utils.formatUnits(itemRequirement.amount, decimals),
            },
        };
    }
    // No token balance so mark as zero
    if (!tokenBalanceResult) {
        tokenBalanceResult = {
            type: itemRequirement.type,
            balance: BigNumber.from(0),
            formattedBalance: '0',
            token: {
                name,
                symbol,
                address: itemRequirement.contractAddress,
                decimals,
            },
        };
    }
    return {
        sufficient,
        type: ItemType.ERC20,
        delta: {
            balance: delta,
            formattedBalance: utils.formatUnits(delta, decimals),
        },
        current: tokenBalanceResult,
        required: {
            ...tokenBalanceResult,
            balance: BigNumber.from(itemRequirement.amount),
            formattedBalance: utils.formatUnits(itemRequirement.amount, decimals),
        },
    };
};

/**
 * Gets the balances for all NATIVE and ERC20 balance requirements.
 */
const getTokenBalances = async (config, provider, ownerAddress, itemRequirements) => {
    try {
        const tokenMap = new Map();
        getTokensFromRequirements(itemRequirements).forEach((item) => {
            if (!item.address)
                return;
            tokenMap.set(item.address.toLocaleLowerCase(), item);
        });
        const { balances } = await getAllBalances(config, provider, ownerAddress, getL2ChainId(config));
        return balances.filter((balance) => tokenMap.get((balance.token.address || NATIVE).toLocaleLowerCase()));
    }
    catch (error) {
        throw new CheckoutError('Failed to get balances', CheckoutErrorType.GET_BALANCE_ERROR);
    }
};
/**
 * Gets the balances for all ERC721 balance requirements.
 */
const getERC721Balances = async (provider, ownerAddress, itemRequirements) => {
    const erc721Balances = [];
    // Setup maps to be able to link data back to the associated promises
    const erc721s = new Map();
    const erc721OwnershipPromises = new Map();
    itemRequirements
        .forEach((itemRequirement) => {
        if (itemRequirement.type !== ItemType.ERC721)
            return;
        const contract = new Contract(itemRequirement.contractAddress, JSON.stringify(ERC721ABI), provider);
        erc721s.set(itemRequirement.contractAddress, itemRequirement);
        erc721OwnershipPromises.set(itemRequirement.contractAddress, contract.ownerOf(itemRequirement.id));
    });
    try {
        // Convert ERC721 ownership into a balance result
        const erc721Owners = await Promise.all(erc721OwnershipPromises.values());
        const erc721OwnersPromiseIds = Array.from(erc721OwnershipPromises.keys());
        erc721Owners.forEach((erc721OwnerAddress, index) => {
            const itemRequirement = erc721s.get(erc721OwnersPromiseIds[index]);
            let itemCount = 0;
            if (itemRequirement && ownerAddress === erc721OwnerAddress) {
                itemCount = 1;
            }
            erc721Balances.push({
                type: ItemType.ERC721,
                balance: BigNumber.from(itemCount),
                formattedBalance: itemCount.toString(),
                contractAddress: itemRequirement.contractAddress,
                id: itemRequirement.id,
            });
        });
    }
    catch (error) {
        throw new CheckoutError('Failed to get ERC721 balances', CheckoutErrorType.GET_ERC721_BALANCE_ERROR);
    }
    return erc721Balances;
};
/**
 * Checks the item requirements against the owner balances.
 */
const balanceCheck = async (config, provider, ownerAddress, itemRequirements) => {
    const aggregatedItems = balanceAggregator(itemRequirements);
    const requiredToken = [];
    const requiredERC721 = [];
    aggregatedItems.forEach((item) => {
        switch (item.type) {
            case ItemType.ERC20:
            case ItemType.NATIVE:
                requiredToken.push(item);
                break;
            case ItemType.ERC721:
                requiredERC721.push(item);
                break;
        }
    });
    if (requiredERC721.length === 0 && requiredToken.length === 0) {
        throw new CheckoutError('Unsupported item requirement balance check', CheckoutErrorType.UNSUPPORTED_BALANCE_REQUIREMENT_ERROR);
    }
    // Get all ERC20 and NATIVE balances
    const balancePromises = [];
    if (requiredToken.length > 0) {
        balancePromises.push(getTokenBalances(config, provider, ownerAddress, aggregatedItems));
    }
    // Get all ERC721 balances
    if (requiredERC721.length > 0) {
        balancePromises.push(getERC721Balances(provider, ownerAddress, aggregatedItems));
    }
    // Wait for all balances and calculate the requirements
    const promisesResponses = await Promise.all(balancePromises);
    const balanceRequirements = [];
    // Get all ERC20 and NATIVE balances
    if (requiredToken.length > 0 && promisesResponses.length > 0) {
        const result = promisesResponses.shift();
        if (result) {
            requiredToken.forEach((item) => {
                balanceRequirements.push(getTokenBalanceRequirement(item, result));
            });
        }
    }
    // Get all ERC721 balances
    if (requiredERC721.length > 0 && promisesResponses.length > 0) {
        const result = promisesResponses.shift();
        if (result) {
            requiredERC721.forEach((item) => {
                balanceRequirements.push(getERC721BalanceRequirement(item, result));
            });
        }
    }
    // Find if there are any requirements that aren't sufficient.
    // If there is not item with sufficient === false then the requirements
    // are satisfied.
    const sufficient = balanceRequirements.find((req) => req.sufficient === false) === undefined;
    return {
        sufficient,
        balanceRequirements,
    };
};

const estimateGas = async (provider, transaction) => {
    try {
        return await provider.estimateGas(transaction);
    }
    catch (err) {
        throw new CheckoutError('Failed to estimate gas for transaction', CheckoutErrorType.UNPREDICTABLE_GAS_LIMIT);
    }
};
const getGasItemRequirement = (gas, transactionOrGas) => {
    if (transactionOrGas.type === TransactionOrGasType.TRANSACTION
        || transactionOrGas.gasToken.type === GasTokenType.NATIVE) {
        return {
            type: ItemType.NATIVE,
            amount: gas,
        };
    }
    return {
        type: ItemType.ERC20,
        amount: gas,
        contractAddress: transactionOrGas.gasToken.contractAddress,
        spenderAddress: '',
    };
};
const gasCalculator = async (provider, insufficientItems, transactionOrGas) => {
    const estimateGasPromises = [];
    let totalGas = BigNumber.from(0);
    // Get all the gas estimate promises for the approval transactions
    for (const item of insufficientItems) {
        if (item.approvalTransaction === undefined)
            continue;
        estimateGasPromises.push(estimateGas(provider, item.approvalTransaction));
    }
    // If the transaction is a fulfillment transaction get the estimate gas promise
    // Otherwise use the gas amount with the limit to estimate the gas
    if (transactionOrGas.type === TransactionOrGasType.TRANSACTION) {
        estimateGasPromises.push(estimateGas(provider, transactionOrGas.transaction));
    }
    else {
        const feeData = await provider.getFeeData();
        const gasPrice = getGasPriceInWei(feeData);
        if (gasPrice !== null) {
            const gas = gasPrice?.mul(transactionOrGas.gasToken.limit);
            if (gas)
                totalGas = totalGas.add(gas);
        }
    }
    // Get the gas estimates for all the transactions and calculate the total gas
    const gasEstimatePromises = await Promise.all(estimateGasPromises);
    gasEstimatePromises.forEach((gasEstimate) => {
        totalGas = totalGas.add(gasEstimate);
    });
    if (totalGas.eq(0))
        return null;
    return getGasItemRequirement(totalGas, transactionOrGas);
};

const availabilityService = (isDevelopment, isProduction) => {
    const postEndpoint = () => {
        if (isDevelopment)
            return IMMUTABLE_API_BASE_URL[ENV_DEVELOPMENT];
        if (isProduction)
            return IMMUTABLE_API_BASE_URL[Environment.PRODUCTION];
        return IMMUTABLE_API_BASE_URL[Environment.SANDBOX];
    };
    const checkDexAvailability = async () => {
        let response;
        try {
            response = await axios.post(`${postEndpoint()}/v1/availability/checkout/swap`);
        }
        catch (error) {
            response = error.response;
        }
        if (response.status === 403) {
            return false;
        }
        if (response.status === 204) {
            return true;
        }
        throw new CheckoutError(`Error fetching from api: ${response.status} ${response.statusText}`, CheckoutErrorType.API_ERROR);
    };
    return {
        checkDexAvailability,
    };
};

const isOnRampAvailable = async () => true;
const isSwapAvailable = async (config) => {
    const availability = availabilityService(config.isDevelopment, config.isProduction);
    try {
        return await availability.checkDexAvailability();
    }
    catch {
        return false;
    }
};

const isPassportProvider = (provider) => provider.provider?.isPassport === true ?? false;
/**
 * Determines which routing options are available.
 */
const getAvailableRoutingOptions = async (config, provider) => {
    const availableRoutingOptions = {
        onRamp: config.isOnRampEnabled,
        swap: config.isSwapEnabled,
        bridge: config.isBridgeEnabled,
    };
    // Geo-blocking checks
    const geoBlockingChecks = [];
    if (availableRoutingOptions.onRamp) {
        geoBlockingChecks.push({ id: 'onRamp', promise: isOnRampAvailable() });
    }
    if (availableRoutingOptions.swap) {
        geoBlockingChecks.push({ id: 'swap', promise: isSwapAvailable(config) });
    }
    if (geoBlockingChecks.length > 0) {
        const promises = geoBlockingChecks.map((geoBlockingCheck) => geoBlockingCheck.promise);
        const geoBlockingStatus = await Promise.allSettled(promises);
        geoBlockingStatus.forEach((result, index) => {
            const statusId = geoBlockingChecks[index].id;
            availableRoutingOptions[statusId] = availableRoutingOptions[statusId]
                && result.status === 'fulfilled'
                && result.value;
        });
    }
    // Bridge not available if passport provider
    availableRoutingOptions.bridge = availableRoutingOptions.bridge && !isPassportProvider(provider);
    return availableRoutingOptions;
};

const getAllTokenBalances = async (config, readOnlyProviders, ownerAddress, availableRoutingOptions) => {
    const chainBalances = new Map();
    const chainBalancePromises = new Map();
    if (readOnlyProviders.size === 0) {
        const noProviderResult = {
            success: false,
            error: new CheckoutError('No L1 or L2 provider available', CheckoutErrorType.PROVIDER_ERROR),
            balances: [],
        };
        chainBalances.set(getL1ChainId(config), noProviderResult);
        chainBalances.set(getL2ChainId(config), noProviderResult);
        return chainBalances;
    }
    // Only get L1 Balances if we can bridge
    if (availableRoutingOptions.bridge) {
        const chainId = getL1ChainId(config);
        if (readOnlyProviders.has(chainId)) {
            chainBalancePromises.set(chainId, getAllBalances(config, readOnlyProviders.get(chainId), ownerAddress, chainId));
        }
        else {
            chainBalances.set(getL1ChainId(config), {
                success: false,
                error: new CheckoutError(`No L1 provider available for ${chainId}`, CheckoutErrorType.PROVIDER_ERROR),
                balances: [],
            });
        }
    }
    const chainId = getL2ChainId(config);
    if (readOnlyProviders.has(chainId)) {
        chainBalancePromises.set(chainId, getAllBalances(config, readOnlyProviders.get(chainId), ownerAddress, chainId));
    }
    else {
        chainBalances.set(getL2ChainId(config), {
            success: false,
            error: new CheckoutError(`No L2 provider available for ${chainId}`, CheckoutErrorType.PROVIDER_ERROR),
            balances: [],
        });
    }
    if (chainBalancePromises.size > 0) {
        const chainIds = Array.from(chainBalancePromises.keys());
        const balanceSettledResults = await Promise.allSettled(chainBalancePromises.values());
        balanceSettledResults.forEach((balanceSettledResult, index) => {
            const balanceChainId = chainIds[index];
            if (balanceSettledResult.status === 'fulfilled') {
                chainBalances.set(balanceChainId, {
                    success: true,
                    balances: balanceSettledResult.value.balances,
                });
            }
            else {
                chainBalances.set(balanceChainId, {
                    success: false,
                    error: new CheckoutError(`Error getting ${chainId} balances`, CheckoutErrorType.GET_BALANCE_ERROR),
                    balances: [],
                });
            }
        });
    }
    return chainBalances;
};

async function createReadOnlyProviders(config, existingReadOnlyProviders) {
    if (config.isProduction && existingReadOnlyProviders?.has(ChainId.ETHEREUM))
        return existingReadOnlyProviders;
    if (existingReadOnlyProviders?.has(ChainId.SEPOLIA))
        return existingReadOnlyProviders;
    const readOnlyProviders = new Map();
    const allowedNetworks = await getNetworkAllowList(config, {
        type: NetworkFilterTypes.ALL,
    });
    allowedNetworks.networks.forEach((networkInfo) => {
        const rpcUrl = config.networkMap.get(networkInfo.chainId)?.rpcUrls[0];
        const provider = new JsonRpcProvider(rpcUrl);
        readOnlyProviders.set(networkInfo.chainId, provider);
    });
    return readOnlyProviders;
}

const quoteFetcher = async (config, chainId, walletAddress, requiredToken, swappableTokens) => {
    const dexQuotes = new Map();
    // Apply a small slippage percent as a buffer to cover price fluctuations between token pairs
    const slippagePercent = 1;
    try {
        const exchange = await createExchangeInstance(chainId, config);
        const dexTransactionResponsePromises = [];
        const fromToken = [];
        // Create a quote for each swappable token
        for (const swappableToken of swappableTokens) {
            if (swappableToken === requiredToken.address)
                continue;
            dexTransactionResponsePromises.push(exchange.getUnsignedSwapTxFromAmountOut(walletAddress, swappableToken, requiredToken.address, requiredToken.amount, slippagePercent));
            fromToken.push(swappableToken);
        }
        // Resolve all the quotes and link them back to the swappable token
        // The swappable token array is in the same position in the array as the quote in the promise array
        const dexTransactionResponse = await measureAsyncExecution(config, 'Time to resolve swap quotes from the dex', Promise.allSettled(dexTransactionResponsePromises));
        dexTransactionResponse.forEach((response, index) => {
            if (response.status === 'rejected')
                return; // Ignore any requests to dex that failed to resolve
            const swappableToken = fromToken[index];
            dexQuotes.set(swappableToken, {
                quote: response.value.quote,
                approval: response.value.approval?.gasFeeEstimate ?? null,
                swap: response.value.swap.gasFeeEstimate,
            });
        });
        return dexQuotes;
    }
    catch {
        return dexQuotes;
    }
};

const constructFees$1 = (approvalGasFees, swapGasFees, swapFees) => {
    let approvalGasFeeAmount = BigNumber.from(0);
    let approvalGasFeeFormatted = '0';
    let approvalToken;
    if (approvalGasFees) {
        approvalGasFeeAmount = approvalGasFees.value;
        approvalGasFeeFormatted = utils.formatUnits(approvalGasFees.value, approvalGasFees.token.decimals);
        approvalToken = {
            name: approvalGasFees.token.name ?? '',
            symbol: approvalGasFees.token.symbol ?? '',
            address: approvalGasFees.token.address,
            decimals: approvalGasFees.token.decimals,
        };
    }
    let swapGasFeeAmount = BigNumber.from(0);
    let swapGasFeeFormatted = '0';
    let swapGasToken;
    if (swapGasFees) {
        swapGasFeeAmount = swapGasFees.value;
        swapGasFeeFormatted = utils.formatUnits(swapGasFees.value, swapGasFees.token.decimals);
        swapGasToken = {
            name: swapGasFees.token.name ?? '',
            symbol: swapGasFees.token.symbol ?? '',
            address: swapGasFees.token.address,
            decimals: swapGasFees.token.decimals,
        };
    }
    const fees = [];
    for (const swapFee of swapFees) {
        fees.push({
            amount: swapFee.amount.value,
            formattedAmount: utils.formatUnits(swapFee.amount.value, swapFee.amount.token.decimals),
            token: {
                name: swapFee.amount.token.name ?? '',
                symbol: swapFee.amount.token.symbol ?? '',
                address: swapFee.amount.token.address,
                decimals: swapFee.amount.token.decimals,
            },
        });
    }
    return {
        approvalGasFees: {
            amount: approvalGasFeeAmount,
            formattedAmount: approvalGasFeeFormatted,
            token: approvalToken,
        },
        swapGasFees: {
            amount: swapGasFeeAmount,
            formattedAmount: swapGasFeeFormatted,
            token: swapGasToken,
        },
        swapFees: fees,
    };
};
const constructSwapRoute = (chainId, fundsRequired, userBalance, fees) => {
    const tokenAddress = userBalance.token.address;
    let type = ItemType.ERC20;
    if (isNativeToken(tokenAddress)) {
        type = ItemType.NATIVE;
    }
    return {
        type: FundingStepType.SWAP,
        chainId,
        fundingItem: {
            type,
            fundsRequired: {
                amount: fundsRequired,
                formattedAmount: utils.formatUnits(fundsRequired, userBalance.token.decimals),
            },
            userBalance: {
                balance: userBalance.balance,
                formattedBalance: userBalance.formattedBalance,
            },
            token: userBalance.token,
        },
        fees,
    };
};
const isBalanceRequirementTokenValid = (balanceRequirement) => {
    if (balanceRequirement.type === ItemType.ERC20) {
        return !!balanceRequirement.required.token.address;
    }
    if (balanceRequirement.type === ItemType.NATIVE) {
        return isNativeToken(balanceRequirement.required.token.address);
    }
    return false;
};
const getRequiredToken = (balanceRequirement) => {
    let address = '';
    let amount = BigNumber.from(0);
    switch (balanceRequirement.type) {
        case ItemType.ERC20:
            address = balanceRequirement.required.token.address;
            amount = balanceRequirement.delta.balance;
            break;
        case ItemType.NATIVE:
            amount = balanceRequirement.delta.balance;
            break;
    }
    return { address, amount };
};
const checkUserCanCoverApprovalFees = (l2Balances, approval) => {
    // Check if approval required
    if (!approval)
        return { sufficient: true, approvalGasFee: BigNumber.from(0), approvalGasTokenAddress: '' };
    const approvalGasFee = approval.value;
    const approvalGasTokenAddress = approval.token.address;
    // No balance on L2 to cover approval fees
    if (l2Balances.length === 0) {
        return {
            sufficient: false,
            approvalGasFee,
            approvalGasTokenAddress,
        };
    }
    // Find the users balance of the approval token
    const l2BalanceOfApprovalToken = l2Balances.find((balance) => (isNativeToken(balance.token.address) && isNativeToken(approvalGasTokenAddress))
        || balance.token.address === approvalGasTokenAddress);
    if (!l2BalanceOfApprovalToken)
        return { sufficient: false, approvalGasFee, approvalGasTokenAddress };
    // If the user does not have enough of the token to cover approval fees then return sufficient false
    if (l2BalanceOfApprovalToken.balance.lt(approvalGasFee)) {
        return {
            sufficient: false,
            approvalGasFee,
            approvalGasTokenAddress,
        };
    }
    // The user has enough to cover approval gas fees
    return { sufficient: true, approvalGasFee, approvalGasTokenAddress };
};
const checkUserCanCoverSwapFees = (l2Balances, approvalFees, swapGasFees, swapFees, tokenBeingSwapped) => {
    // Set up a map of token addresses to amounts for each of the swap fees
    const feeMap = new Map();
    // Add the approval fee to list of fees
    if (approvalFees.approvalGasFee.gt(BigNumber.from(0))) {
        feeMap.set(approvalFees.approvalGasTokenAddress, approvalFees.approvalGasFee);
    }
    // Add the swap gas fee to list of fees
    if (swapGasFees) {
        const fee = feeMap.get(swapGasFees.token.address);
        if (fee) {
            feeMap.set(swapGasFees.token.address, fee.add(swapGasFees.value));
        }
        else {
            feeMap.set(swapGasFees.token.address, swapGasFees.value);
        }
    }
    // Add the token being swapped to list of fees to ensure the user can cover the fee + the token swap
    if (tokenBeingSwapped) {
        const fee = feeMap.get(tokenBeingSwapped.address);
        if (fee) { // Token being swapped is the same as gas token
            feeMap.set(tokenBeingSwapped.address, fee.add(tokenBeingSwapped.amount));
        }
        else {
            feeMap.set(tokenBeingSwapped.address, tokenBeingSwapped.amount);
        }
    }
    // Get all the fees and key them by their token id
    for (const swapFee of swapFees) {
        const fee = feeMap.get(swapFee.amount.token.address);
        if (fee) {
            feeMap.set(swapFee.amount.token.address, fee.add(swapFee.amount.value));
            continue;
        }
        feeMap.set(swapFee.amount.token.address, swapFee.amount.value);
    }
    // Go through the map and for each token address check if the user has enough balance to cover the fee
    for (const [tokenAddress, fee] of feeMap.entries()) {
        if (fee === BigNumber.from(0))
            continue;
        const l2BalanceOfFeeToken = l2Balances.find((balance) => (isNativeToken(balance.token.address) && isNativeToken(tokenAddress))
            || balance.token.address === tokenAddress);
        if (!l2BalanceOfFeeToken) {
            return false;
        }
        if (l2BalanceOfFeeToken.balance.lt(fee)) {
            return false;
        }
    }
    return true;
};
// The item for swapping may also be a balance requirement
// for the action. Need to ensure that if the user does a swap
// this token to cover the insufficient balance that the user
// still has enough funds of this token to fulfill the balance
// requirement.
const checkIfUserCanCoverRequirement = (l2balance, balanceRequirements, quoteTokenAddress, amountBeingSwapped, approvalFees, swapFees) => {
    let remainingBalance = BigNumber.from(0);
    let balanceRequirementToken = '';
    let requirementExists = false;
    balanceRequirements.balanceRequirements.forEach((requirement) => {
        if (requirement.type === ItemType.NATIVE || requirement.type === ItemType.ERC20) {
            if (requirement.required.token.address === quoteTokenAddress) {
                balanceRequirementToken = requirement.required.token.address;
                requirementExists = true;
                // Get the balance that would remain if the requirement was removed from the users balance
                remainingBalance = l2balance.sub(requirement.required.balance);
            }
        }
    });
    // No requirement exists matching this token so no need to check if user can cover requirement
    if (!requirementExists)
        return true;
    // Remove approval fees from the remainder if token matches as these need to be taken out to cover the swap
    if (approvalFees.approvalGasTokenAddress === balanceRequirementToken) {
        remainingBalance = remainingBalance.sub(approvalFees.approvalGasFee);
    }
    // Remove swap fees from the remainder if token matches as these need to be taken out to cover the swap
    for (const swapFee of swapFees) {
        if (swapFee.amount.token.address === balanceRequirementToken) {
            remainingBalance = remainingBalance.sub(swapFee.amount.value);
        }
    }
    // If the users current balance can cover the balance after fees + the amount
    // that is going to be swapped from another item requirement then return true
    return remainingBalance.gte(amountBeingSwapped);
};
const swapRoute = async (config, availableRoutingOptions, walletAddress, balanceRequirement, tokenBalanceResults, swappableTokens, balanceRequirements) => {
    const fundingSteps = [];
    if (!availableRoutingOptions.swap)
        return fundingSteps;
    if (swappableTokens.length === 0)
        return fundingSteps;
    if (!isBalanceRequirementTokenValid(balanceRequirement))
        return fundingSteps;
    const requiredToken = getRequiredToken(balanceRequirement);
    const chainId = getL2ChainId(config);
    const l2TokenBalanceResult = tokenBalanceResults.get(chainId);
    if (!l2TokenBalanceResult)
        return fundingSteps;
    const l2Balances = l2TokenBalanceResult.balances;
    if (l2Balances.length === 0)
        return fundingSteps;
    const quotes = await quoteFetcher(config, getL2ChainId(config), walletAddress, requiredToken, swappableTokens);
    const quoteTokenAddresses = Array.from(quotes.keys());
    for (const quoteTokenAddress of quoteTokenAddresses) {
        const quote = quotes.get(quoteTokenAddress);
        if (!quote)
            continue;
        // Find the balance the user has for this quoted token
        const userBalanceOfQuotedToken = l2Balances.find((balance) => balance.token.address === quoteTokenAddress);
        // If no balance found on L2 for this quoted token then continue
        if (!userBalanceOfQuotedToken)
            continue;
        // Check the amount of quoted token required against the user balance
        const amountOfQuoteTokenRequired = quote.quote.amount;
        // If user does not have enough balance to perform the swap with this token then continue
        if (userBalanceOfQuotedToken.balance.lt(amountOfQuoteTokenRequired.value))
            continue;
        const approvalFees = checkUserCanCoverApprovalFees(l2Balances, quote.approval);
        // If user does not have enough to cover approval fees then continue
        if (!approvalFees.sufficient)
            continue;
        // If user does not have enough to cover swap fees then continue
        if (!checkUserCanCoverSwapFees(l2Balances, approvalFees, quote.swap, quote.quote.fees, {
            amount: amountOfQuoteTokenRequired.value,
            address: quoteTokenAddress,
        }))
            continue;
        if (!checkIfUserCanCoverRequirement(userBalanceOfQuotedToken.balance, balanceRequirements, quoteTokenAddress, amountOfQuoteTokenRequired.value, approvalFees, quote.quote.fees))
            continue;
        const fees = constructFees$1(quote.approval, quote.swap, quote.quote.fees);
        // User has sufficient funds of this token to cover any gas fees, swap fees and balance requirements
        // so add this token to the possible swap options
        fundingSteps.push(constructSwapRoute(chainId, amountOfQuoteTokenRequired.value, userBalanceOfQuotedToken, fees));
    }
    return fundingSteps;
};

const filterTokens = (allowedTokens, balances) => {
    if (balances && balances.success) {
        return allowedTokens.filter((token) => {
            if ('address' in token) {
                return balances.balances.find((balance) => balance.token.address === token.address && balance.balance.gt(0));
            }
            return balances.balances.find((balance) => !('address' in balance.token) && balance.balance.gt(0));
        });
    }
    return [];
};
const allowListCheckForOnRamp = async (config, availableRoutingOptions) => {
    if (availableRoutingOptions.onRamp) {
        const onRampOptions = await config.remote.getConfig('onramp');
        const onRampAllowList = {};
        Object.entries(onRampOptions)
            .forEach(([onRampProvider, onRampProviderConfig]) => {
            // Allowed list per onRamp provider
            onRampAllowList[onRampProvider] = onRampProviderConfig.tokens ?? [];
        });
        return onRampAllowList;
    }
    return {};
};
const allowListCheckForBridge = async (config, tokenBalances, availableRoutingOptions) => {
    if (availableRoutingOptions.bridge) {
        const allowedTokens = (await config.remote.getConfig('bridge'))?.tokens ?? [];
        const balances = tokenBalances.get(getL1ChainId(config));
        return filterTokens(allowedTokens, balances);
    }
    return [];
};
const allowListCheckForSwap = async (config, tokenBalances, availableRoutingOptions) => {
    if (availableRoutingOptions.swap) {
        const allowedTokens = (await config.remote.getConfig('dex'))?.tokens ?? [];
        const balances = tokenBalances.get(getL2ChainId(config));
        return filterTokens(allowedTokens, balances);
    }
    return [];
};
/**
 * Checks the user balances against the route option allowlists.
 */
const allowListCheck = async (config, tokenBalances, availableRoutingOptions) => {
    const tokenAllowList = {};
    tokenAllowList.swap = await allowListCheckForSwap(config, tokenBalances, availableRoutingOptions);
    tokenAllowList.bridge = await allowListCheckForBridge(config, tokenBalances, availableRoutingOptions);
    tokenAllowList.onRamp = await allowListCheckForOnRamp(config, availableRoutingOptions);
    return tokenAllowList;
};

const getEthBalance = (balances) => {
    for (const balance of balances.balances) {
        if (isNativeToken(balance.token.address)) {
            return balance.balance;
        }
    }
    return BigNumber.from(0);
};

const getBridgeFeeEstimate = async (config, readOnlyProviders) => {
    try {
        const estimate = await gasEstimator({
            gasEstimateType: GasEstimateType.BRIDGE_TO_L2,
            isSpendingCapApprovalRequired: false,
        }, readOnlyProviders, config);
        const gasEstimate = estimate.gasFee.estimatedAmount;
        const bridgeFee = estimate.bridgeFee.estimatedAmount;
        let totalFees = BigNumber.from(0);
        if (gasEstimate)
            totalFees = totalFees.add(gasEstimate);
        if (bridgeFee)
            totalFees = totalFees.add(bridgeFee);
        return {
            type: FundingStepType.BRIDGE,
            gasFee: {
                estimatedAmount: gasEstimate ?? BigNumber.from(0),
                token: estimate.gasFee.token,
            },
            bridgeFee: {
                estimatedAmount: bridgeFee ?? BigNumber.from(0),
                token: estimate.bridgeFee.token,
            },
            totalFees,
        };
    }
    catch (err) {
        throw new CheckoutError('Error estimating gas for bridge', CheckoutErrorType.BRIDGE_GAS_ESTIMATE_ERROR, { message: err.message });
    }
};

// If the root address evaluates to this then its ETH
const INDEXER_ETH_ROOT_CONTRACT_ADDRESS = '0x0000000000000000000000000000000000000001';
const getIndexerChainName = (chainId) => {
    if (chainId === ChainId.IMTBL_ZKEVM_MAINNET)
        return 'imtbl-zkevm-mainnet';
    if (chainId === ChainId.IMTBL_ZKEVM_TESTNET)
        return 'imtbl-zkevm-testnet';
    if (chainId === ChainId.IMTBL_ZKEVM_DEVNET)
        return 'imtbl-zkevm-devent';
    return '';
};
// Indexer ERC20 call does not support IMX so cannot get root chain mapping from this endpoint.
// Use the remote config instead to find IMX address mapping.
const getImxL1Representation = async (chainId, config) => {
    const imxMappingConfig = (await config.remote.getConfig('imxAddressMapping'));
    return imxMappingConfig[chainId] ?? '';
};
const fetchL1Representation = async (config, l2address) => {
    if (isNativeToken(l2address)) {
        return {
            l1address: await getImxL1Representation(getL1ChainId(config), config),
            l2address: NATIVE,
        };
    }
    const chainName = getIndexerChainName(getL2ChainId(config));
    const blockchainData = createBlockchainDataInstance(config);
    const tokenData = await blockchainData.getToken({
        chainName,
        contractAddress: l2address,
    });
    // TODO: When bridge is ready we need to understand how L2 ETH will be mapped back to L1 ETH
    const l1address = tokenData.result.root_contract_address;
    if (l1address === INDEXER_ETH_ROOT_CONTRACT_ADDRESS) {
        return {
            l1address: 'native',
            l2address,
        };
    }
    if (l1address === null)
        return undefined; // No L1 representation of this token
    return {
        l1address,
        l2address,
    };
};

const estimateApprovalGas = async (config, readOnlyProviders, l1provider, depositorAddress, fromChainId, toChainId, token, depositAmount) => {
    try {
        const tokenBridge = await createBridgeInstance(fromChainId, toChainId, readOnlyProviders, config);
        const { unsignedTx } = await tokenBridge.getUnsignedApproveDepositBridgeTx({
            depositorAddress,
            token,
            depositAmount,
        });
        if (unsignedTx === null)
            return BigNumber.from(0);
        return await l1provider.estimateGas(unsignedTx);
    }
    catch (err) {
        throw new CheckoutError('Error occurred while attempting ot estimate gas for approval transaction', CheckoutErrorType.BRIDGE_GAS_ESTIMATE_ERROR, { message: err.message });
    }
};
const estimateGasForBridgeApproval = async (config, readOnlyProviders, l1provider, depositorAddress, l1Address, delta) => {
    if (l1Address === INDEXER_ETH_ROOT_CONTRACT_ADDRESS) {
        return BigNumber.from(0); // Native ETH does not require approval
    }
    const fromChainId = getL1ChainId(config);
    const toChainId = getL2ChainId(config);
    return await estimateApprovalGas(config, readOnlyProviders, l1provider, depositorAddress, fromChainId, toChainId, l1Address, delta);
};

const hasSufficientL1Eth = (tokenBalanceResult, totalFees) => {
    const balance = getEthBalance(tokenBalanceResult);
    return balance.gte(totalFees);
};
const getBridgeGasEstimate = async (config, readOnlyProviders, feeEstimates) => {
    let bridgeFeeEstimate = feeEstimates.get(FundingStepType.BRIDGE);
    if (bridgeFeeEstimate) {
        return bridgeFeeEstimate;
    }
    bridgeFeeEstimate = await getBridgeFeeEstimate(config, readOnlyProviders);
    feeEstimates.set(FundingStepType.BRIDGE, bridgeFeeEstimate);
    return bridgeFeeEstimate;
};
const constructFees = (approvalGasFees, bridgeGasFees, bridgeFee) => {
    const bridgeFeeDecimals = bridgeFee.token?.decimals ?? DEFAULT_TOKEN_DECIMALS$1;
    return {
        approvalGasFees: {
            amount: approvalGasFees,
            formattedAmount: utils.formatUnits(approvalGasFees, DEFAULT_TOKEN_DECIMALS$1),
            token: bridgeGasFees.token,
        },
        bridgeGasFees: {
            amount: bridgeGasFees.estimatedAmount,
            formattedAmount: utils.formatUnits(bridgeGasFees.estimatedAmount, DEFAULT_TOKEN_DECIMALS$1),
            token: bridgeGasFees.token,
        },
        bridgeFees: [{
                amount: bridgeFee.estimatedAmount,
                formattedAmount: utils.formatUnits(bridgeFee.estimatedAmount, bridgeFeeDecimals),
                token: bridgeFee.token,
            }],
    };
};
const constructBridgeFundingRoute = (chainId, balance, bridgeRequirement, itemType, fees) => ({
    type: FundingStepType.BRIDGE,
    chainId,
    fundingItem: {
        type: itemType,
        fundsRequired: {
            amount: bridgeRequirement.amount,
            formattedAmount: bridgeRequirement.formattedAmount,
        },
        userBalance: {
            balance: balance.balance,
            formattedBalance: balance.formattedBalance,
        },
        token: {
            name: balance.token.name,
            symbol: balance.token.symbol,
            address: balance.token.address,
            decimals: balance.token.decimals,
        },
    },
    fees,
});
const bridgeRoute = async (config, readOnlyProviders, depositorAddress, availableRoutingOptions, bridgeRequirement, tokenBalanceResults, feeEstimates) => {
    if (!availableRoutingOptions.bridge)
        return undefined;
    const chainId = getL1ChainId(config);
    const tokenBalanceResult = tokenBalanceResults.get(chainId);
    const l1provider = readOnlyProviders.get(chainId);
    if (!l1provider) {
        throw new CheckoutError('No L1 provider available', CheckoutErrorType.PROVIDER_ERROR, { chainId: chainId.toString() });
    }
    // If no balances on layer 1 then Bridge cannot be an option
    if (tokenBalanceResult === undefined || tokenBalanceResult.success === false)
        return undefined;
    const allowedTokenList = await allowListCheckForBridge(config, tokenBalanceResults, availableRoutingOptions);
    if (allowedTokenList.length === 0)
        return undefined;
    const bridgeFeeEstimate = await getBridgeGasEstimate(config, readOnlyProviders, feeEstimates);
    // If the user has no ETH to cover the bridge fees or approval fees then bridge cannot be an option
    if (!hasSufficientL1Eth(tokenBalanceResult, bridgeFeeEstimate.totalFees))
        return undefined;
    const l1RepresentationResult = await fetchL1Representation(config, bridgeRequirement.l2address);
    if (!l1RepresentationResult)
        return undefined;
    // Ensure l1address is in the allowed token list
    const { l1address } = l1RepresentationResult;
    if (isNativeToken(l1address)) {
        if (!allowedTokenList.find((token) => !('address' in token)))
            return undefined;
    }
    else if (!allowedTokenList.find((token) => token.address === l1address)) {
        return undefined;
    }
    const gasForApproval = await estimateGasForBridgeApproval(config, readOnlyProviders, l1provider, depositorAddress, l1address, bridgeRequirement.amount);
    let totalFees = bridgeFeeEstimate.bridgeFee.estimatedAmount;
    // If the L1 representation of the requirement is ETH then find the ETH balance and check if the balance covers the delta
    if (isNativeToken(l1address)) {
        const nativeETHBalance = tokenBalanceResult.balances
            .find((balance) => isNativeToken(balance.token.address));
        if (bridgeFeeEstimate.gasFee.estimatedAmount) {
            totalFees = totalFees.add(bridgeFeeEstimate.gasFee.estimatedAmount);
        }
        if (!hasSufficientL1Eth(tokenBalanceResult, totalFees))
            return undefined;
        if (nativeETHBalance && nativeETHBalance.balance.gte(bridgeRequirement.amount.add(totalFees))) {
            const bridgeFees = constructFees(gasForApproval, bridgeFeeEstimate.gasFee, bridgeFeeEstimate.bridgeFee);
            return constructBridgeFundingRoute(chainId, nativeETHBalance, bridgeRequirement, ItemType.NATIVE, bridgeFees);
        }
        return undefined;
    }
    totalFees.add(gasForApproval).add(bridgeFeeEstimate.gasFee.estimatedAmount);
    if (!hasSufficientL1Eth(tokenBalanceResult, totalFees))
        return undefined;
    // Find the balance of the L1 representation of the token and check if the balance covers the delta
    const erc20balance = tokenBalanceResult.balances.find((balance) => balance.token.address === l1address);
    if (erc20balance && erc20balance.balance.gte(bridgeRequirement.amount)) {
        const bridgeFees = constructFees(gasForApproval, bridgeFeeEstimate.gasFee, bridgeFeeEstimate.bridgeFee);
        return constructBridgeFundingRoute(chainId, erc20balance, bridgeRequirement, ItemType.ERC20, bridgeFees);
    }
    return undefined;
};

const getBalancesByChain = (config, tokenBalances) => {
    const balances = { l1balances: [], l2balances: [] };
    const l1balancesResult = tokenBalances.get(getL1ChainId(config));
    const l2balancesResult = tokenBalances.get(getL2ChainId(config));
    // If there are no l1 balance then cannot bridge
    if (!l1balancesResult)
        return balances;
    if (l1balancesResult.error !== undefined)
        return balances;
    if (!l1balancesResult.success)
        return balances;
    // If there are no l2 balance then cannot swap
    if (!l2balancesResult)
        return balances;
    if (l2balancesResult.error !== undefined)
        return balances;
    if (!l2balancesResult.success)
        return balances;
    const l1balances = l1balancesResult.balances;
    const l2balances = l2balancesResult.balances;
    return { l1balances, l2balances };
};

// The dex will return all the fees which is in a particular token (currently always IMX)
// If any of the fees are in the same token that is trying to be swapped (e.g. trying to swap IMX)
// then these fees need to be added to the amount to bridge, otherwise not enough of the token
// will be bridged over to cover the amount to swap and any fees associated with the swap
const getFeesForTokenAddress = (dexQuote, tokenAddress) => {
    let fees = BigNumber.from(0);
    dexQuote.quote.fees.forEach((fee) => {
        if (fee.amount.token.address === tokenAddress) {
            fees = fees.add(fee.amount.value);
        }
    });
    if (dexQuote.approval) {
        if (dexQuote.approval.token.address === tokenAddress) {
            fees = fees.add(dexQuote.approval.value);
        }
    }
    return fees;
};
// The token that is being bridged may also be a balance requirement
// Since this token is going to be swapped after bridging then get
// the amount of the current balance requirement
const getAmountFromBalanceRequirement = (balanceRequirements, quotedTokenAddress) => {
    // Find if there is an existing balance requirement of the token attempting to be bridged->swapped
    for (const requirement of balanceRequirements.balanceRequirements) {
        if (requirement.type === ItemType.NATIVE || requirement.type === ItemType.ERC20) {
            if (requirement.required.token.address === quotedTokenAddress) {
                return requirement.required.balance;
            }
        }
    }
    return BigNumber.from(0);
};
// Get the total amount to bridge factoring in any balance requirements
// of this token and the current balance on L2
const getAmountToBridge = (quotedAmountWithFees, amountFromBalanceRequirement, l2balance) => {
    const balance = l2balance?.balance ?? BigNumber.from(0);
    // Balance is fully covered and does not require bridging
    // then the one swap route will be suggested
    if (balance.gte(quotedAmountWithFees.add(amountFromBalanceRequirement))) {
        return BigNumber.from(0);
    }
    // If no balance on L2 then bridge full amount and balance requirement amount if any
    if (balance.lte(0)) {
        return quotedAmountWithFees.add(amountFromBalanceRequirement);
    }
    // Get the remainder from the balance after subtracting the balance requirement amount
    const remainder = balance.sub(amountFromBalanceRequirement);
    // Remove the remainder from the amount needed as the user has some balance left over
    // after covering the balance requirement or the remainder is 0 indicating they have
    // just enough to cover the balance requirement
    if (remainder.gte(0)) {
        return quotedAmountWithFees.sub(remainder);
    }
    // If the remainder is less than 0 then add the quoted amount with the balance requirement
    // and sub the users current balance to get the total amount needed to be bridged to cover
    // the quoted amount + balance requirement
    return quotedAmountWithFees.add(amountFromBalanceRequirement).sub(balance);
};
// to be sent to the bridge route
const constructBridgeRequirements = (dexQuotes, l1balances, l2balances, l1tol2addresses, balanceRequirements) => {
    const bridgeRequirements = [];
    for (const [tokenAddress, quote] of dexQuotes) {
        // Get the L2 balance for the token address
        const l2balance = l2balances.find((balance) => balance.token.address === tokenAddress);
        const l1tol2TokenMapping = l1tol2addresses.find((token) => token.l2address === tokenAddress);
        if (!l1tol2TokenMapping)
            continue;
        const { l1address, l2address } = l1tol2TokenMapping;
        if (!l1address)
            continue;
        // If the user does not have any L1 balance for this token then cannot bridge
        const l1balance = l1balances.find((balance) => {
            if (balance.token.address === undefined
                && l1address === INDEXER_ETH_ROOT_CONTRACT_ADDRESS) {
                return true;
            }
            return balance.token.address === l1address;
        });
        if (!l1balance)
            continue;
        // Get the total amount using slippage to ensure a small buffer is added to cover price fluctuations
        const quotedAmount = quote.quote.amountWithMaxSlippage.value;
        // Add fees to the quoted amount if the fees are in the same token as the token being swapped
        const fees = getFeesForTokenAddress(quote, tokenAddress);
        const quotedAmountWithFees = quotedAmount.add(fees);
        // Get the amount from the balance requirement if the token is also a balance requirement
        const amountFromBalanceRequirement = getAmountFromBalanceRequirement(balanceRequirements, tokenAddress);
        // Get the amount to bridge factoring in any balance requirements for this swappable token
        // and the current balance on L2
        const amountToBridge = getAmountToBridge(quotedAmountWithFees, amountFromBalanceRequirement, l2balance);
        // No amount to bridge as user has sufficient balance for one swap
        if (amountToBridge.lte(0)) {
            continue;
        }
        // If the amount to bridge is greater than the L1 balance then cannot bridge
        if (amountToBridge.gte(l1balance.balance)) {
            continue;
        }
        bridgeRequirements.push({
            amount: amountToBridge,
            formattedAmount: utils.formatUnits(amountToBridge, l1balance.token.decimals),
            // L2 address is used for the bridge requirement as the bridge route uses the indexer to find L1 address
            l2address,
        });
    }
    return bridgeRequirements;
};

const fetchL1ToL2Mappings = async (config, swappableTokens) => {
    const l1tol2addressMappingPromises = swappableTokens
        .map((token) => fetchL1Representation(config, token.address ?? ''));
    const mappings = await Promise.all(l1tol2addressMappingPromises);
    return mappings.filter((mapping) => mapping !== undefined);
};

// Fetch all the dex quotes from the list of swappable tokens
const getDexQuotes = async (config, ownerAddress, requiredTokenAddress, insufficientRequirement, filteredSwappableTokens) => {
    const filteredSwappableTokensAddresses = [];
    for (const token of filteredSwappableTokens) {
        if (!token.address)
            continue;
        filteredSwappableTokensAddresses.push(token.address);
    }
    const dexQuotes = await quoteFetcher(config, getL2ChainId(config), ownerAddress, {
        address: requiredTokenAddress,
        amount: insufficientRequirement.delta.balance,
    }, filteredSwappableTokensAddresses);
    return dexQuotes;
};

const abortBridgeAndSwap = (bridgeableTokens, swappableTokens, l1balances, l2balances, availableRoutingOptions, requiredTokenAddress) => {
    if (bridgeableTokens.length === 0)
        return true;
    if (swappableTokens.length === 0)
        return true;
    if (l1balances.length === 0)
        return true;
    if (l2balances.length === 0)
        return true;
    if (!availableRoutingOptions.bridge)
        return true;
    if (!availableRoutingOptions.swap)
        return true;
    if (requiredTokenAddress === undefined)
        return true;
    if (requiredTokenAddress === '')
        return true;
    return false;
};
const filterSwappableTokensByBridgeableAddresses = (requiredTokenAddress, bridgeableTokens, swappableTokens, l1tol2Addresses) => {
    const filteredSwappableTokens = [];
    for (const addresses of l1tol2Addresses) {
        // TODO: Check for ETH (native) L1 in bridgeableTokens first
        if (!bridgeableTokens.includes(addresses.l1address))
            continue;
        // Filter out the token that is required from the swappable tokens list
        if (addresses.l2address === requiredTokenAddress)
            continue;
        const tokenInfo = swappableTokens.find((token) => token.address === addresses.l2address);
        if (!tokenInfo)
            continue;
        filteredSwappableTokens.push(tokenInfo);
    }
    return filteredSwappableTokens;
};
// Modifies a users balance to include the amount as if the user successfully bridged
// This is so the swap route can check against the balance once the user has performed a bridge
const modifyTokenBalancesWithBridgedAmount = (config, tokenBalances, l2balances, bridgedTokens, swappableTokens) => {
    const modifiedTokenBalances = new Map();
    for (const [chainId, tokenBalance] of tokenBalances) {
        modifiedTokenBalances.set(chainId, {
            success: tokenBalance.success,
            balances: tokenBalance.balances,
        });
    }
    // Construct a map of balances to the L2 token address to make
    // it easier to adjust the balances for the tokens that can be bridged
    const balanceMap = new Map();
    for (const balance of l2balances) {
        if (!balance.token.address)
            continue;
        balanceMap.set(balance.token.address, balance);
    }
    // Go through each of the tokens that can be bridged
    // and adjust the balances to fake the bridge
    for (const bridgedToken of bridgedTokens) {
        const { amount, l2address } = bridgedToken;
        if (l2address === '')
            continue;
        let l2balance = BigNumber.from(0);
        // Find the current balance of this token
        const currentBalance = balanceMap.get(l2address);
        if (currentBalance)
            l2balance = currentBalance.balance;
        const newBalance = l2balance.add(amount);
        const tokenInfo = swappableTokens.find((token) => token.address === l2address);
        balanceMap.set(l2address, {
            balance: newBalance,
            formattedBalance: utils.formatUnits(newBalance, tokenInfo.decimals),
            token: tokenInfo,
        });
    }
    const updatedBalances = Array.from(balanceMap.values());
    modifiedTokenBalances.set(getL2ChainId(config), {
        success: true,
        balances: updatedBalances,
    });
    return modifiedTokenBalances;
};
// Reapply the original swap balances after the
// swap route was modified to fake the bridge
const reapplyOriginalSwapBalances = (tokenBalances, swapRoutes) => {
    const originalSwapSteps = [];
    for (const route of swapRoutes) {
        const { chainId, fundingItem } = route;
        const { userBalance } = route.fundingItem;
        const tokenBalance = tokenBalances.get(chainId);
        if (!tokenBalance)
            continue;
        let originalBalance = BigNumber.from(0);
        let originalFormattedBalance = '0';
        const l2balance = tokenBalance.balances.find((balance) => balance.token.address === fundingItem.token.address);
        if (l2balance) {
            originalBalance = l2balance.balance;
            originalFormattedBalance = l2balance.formattedBalance;
        }
        userBalance.balance = originalBalance;
        userBalance.formattedBalance = originalFormattedBalance;
        originalSwapSteps.push(route);
    }
    return originalSwapSteps;
};
const constructBridgeAndSwapRoutes = (bridgeFundingSteps, swapFundingSteps, l1tol2Addresses) => {
    const bridgeAndSwapRoutes = [];
    for (const bridgeFundingStep of bridgeFundingSteps) {
        if (!bridgeFundingStep)
            continue;
        const mapping = l1tol2Addresses.find((addresses) => {
            if (bridgeFundingStep.fundingItem.token.address === undefined) {
                return addresses.l1address === INDEXER_ETH_ROOT_CONTRACT_ADDRESS && addresses.l2address;
            }
            return addresses.l1address === bridgeFundingStep.fundingItem.token.address && addresses.l2address;
        });
        if (!mapping)
            continue;
        const swapFundingStep = swapFundingSteps.find((step) => step.fundingItem.token.address === mapping.l2address);
        if (!swapFundingStep)
            continue;
        bridgeAndSwapRoutes.push({
            bridgeFundingStep,
            swapFundingStep,
        });
    }
    return bridgeAndSwapRoutes;
};
const bridgeAndSwapRoute = async (config, readOnlyProviders, availableRoutingOptions, insufficientRequirement, ownerAddress, feeEstimates, tokenBalances, bridgeableTokens, swappableTokens, balanceRequirements) => {
    const { l1balances, l2balances } = getBalancesByChain(config, tokenBalances);
    const requiredTokenAddress = insufficientRequirement.required.token.address;
    if (abortBridgeAndSwap(bridgeableTokens, swappableTokens, l1balances, l2balances, availableRoutingOptions, requiredTokenAddress))
        return [];
    // Fetch L2 to L1 address mapping and based on the L1 address existing then
    // filter the bridgeable and swappable tokens list further to only include
    // tokens that can be both swapped and bridged
    const l1tol2Addresses = await fetchL1ToL2Mappings(config, swappableTokens);
    const filteredSwappableTokens = filterSwappableTokensByBridgeableAddresses(requiredTokenAddress, bridgeableTokens, swappableTokens, l1tol2Addresses);
    if (filteredSwappableTokens.length === 0)
        return [];
    // Fetch all the dex quotes from the list of swappable tokens
    const dexQuotes = await getDexQuotes(config, ownerAddress, requiredTokenAddress, insufficientRequirement, filteredSwappableTokens);
    // Construct bridge requirements based on L2 balances, slippage and swap fees
    const bridgeRequirements = constructBridgeRequirements(dexQuotes, l1balances, l2balances, l1tol2Addresses, balanceRequirements);
    if (bridgeRequirements.length === 0)
        return [];
    // Create a mapping of bridge routes to L2 addresses
    const bridgePromises = new Map();
    // Create map of bridgeable tokens to make it easier to get the amount that was bridged when modifying the users balance later
    const bridgeableRequirementsMap = new Map();
    const bridgedTokens = [];
    for (const bridgeRequirement of bridgeRequirements) {
        if (!bridgeRequirement.l2address)
            continue;
        bridgePromises.set(bridgeRequirement.l2address, bridgeRoute(config, readOnlyProviders, ownerAddress, availableRoutingOptions, bridgeRequirement, tokenBalances, feeEstimates));
        bridgeableRequirementsMap.set(bridgeRequirement.l2address, {
            amount: bridgeRequirement.amount,
            formattedAmount: bridgeRequirement.formattedAmount,
            l2address: bridgeRequirement.l2address,
        });
    }
    const bridgeResults = await Promise.all(bridgePromises.values());
    const bridgeKeys = Array.from(bridgePromises.keys());
    // Create an array to store all the tokens that are able to be bridged
    const swappableTokensAfterBridging = [];
    // Iterate through all the bridge route results
    // If a bridge route result was successful then add this token to the
    // list of tokens that should be checked with the swap route
    bridgeResults.forEach((result, index) => {
        const key = bridgeKeys[index];
        if (result === undefined)
            return;
        swappableTokensAfterBridging.push(key);
        const bridgedToken = bridgeableRequirementsMap.get(key);
        if (!bridgedToken)
            return;
        bridgedTokens.push({
            amount: bridgedToken.amount,
            formattedAmount: bridgedToken.formattedAmount,
            l2address: bridgedToken.l2address,
        });
    });
    // Bridge route determined that no tokens could be bridged
    if (swappableTokensAfterBridging.length === 0)
        return [];
    if (bridgedTokens.length === 0)
        return []; // No tokens were bridged
    // Modify the users L2 balance to include the amount as if the user successfully bridged
    const modifiedTokenBalances = modifyTokenBalancesWithBridgedAmount(config, tokenBalances, l2balances, bridgedTokens, swappableTokens);
    // Call the swap route with the faked bridged balances
    const swapRoutes = await swapRoute(config, availableRoutingOptions, ownerAddress, insufficientRequirement, modifiedTokenBalances, swappableTokensAfterBridging, balanceRequirements);
    if (!swapRoutes)
        return [];
    const originalBalanceSwapRoutes = reapplyOriginalSwapBalances(tokenBalances, swapRoutes);
    return constructBridgeAndSwapRoutes(bridgeResults, originalBalanceSwapRoutes, l1tol2Addresses);
};

const onRampRoute = async (config, availableRoutingOptions, balanceRequirement) => {
    if (balanceRequirement.type !== ItemType.ERC20 && balanceRequirement.type !== ItemType.NATIVE)
        return undefined;
    const { required, current, delta } = balanceRequirement;
    let hasAllowList = false;
    const onRampProvidersAllowList = await allowListCheckForOnRamp(config, availableRoutingOptions);
    Object.values(onRampProvidersAllowList).forEach((onRampAllowList) => {
        if (onRampAllowList.length > 0 && !hasAllowList) {
            hasAllowList = !!onRampAllowList.find((token) => token.address === required.token?.address);
        }
    });
    if (!hasAllowList)
        return undefined;
    return {
        type: FundingStepType.ONRAMP,
        chainId: getL2ChainId(config),
        fundingItem: {
            type: isNativeToken(required.token.address) ? ItemType.NATIVE : ItemType.ERC20,
            fundsRequired: {
                amount: delta.balance,
                formattedAmount: delta.formattedBalance,
            },
            userBalance: {
                balance: current.balance,
                formattedBalance: current.formattedBalance,
            },
            token: required.token,
        },
    };
};

const hasAvailableRoutingOptions = (availableRoutingOptions) => (availableRoutingOptions.bridge || availableRoutingOptions.swap || availableRoutingOptions.onRamp);
const getInsufficientRequirement = (balanceRequirements) => {
    let insufficientBalanceCount = 0;
    let insufficientRequirement;
    for (const balanceRequirement of balanceRequirements.balanceRequirements) {
        if (!balanceRequirement.sufficient) {
            insufficientBalanceCount++;
            insufficientRequirement = balanceRequirement;
        }
    }
    if (insufficientBalanceCount === 1)
        return insufficientRequirement;
    return undefined;
};
const getBridgeFundingStep = async (config, readOnlyProviders, availableRoutingOptions, insufficientRequirement, ownerAddress, tokenBalances, feeEstimates) => {
    let bridgeFundingStep;
    if (insufficientRequirement === undefined)
        return undefined;
    if (insufficientRequirement.type !== ItemType.NATIVE && insufficientRequirement.type !== ItemType.ERC20) {
        return undefined;
    }
    const bridgeRequirement = {
        amount: insufficientRequirement.delta.balance,
        formattedAmount: insufficientRequirement.delta.formattedBalance,
        l2address: insufficientRequirement.required.token.address ?? '',
    };
    if (availableRoutingOptions.bridge && insufficientRequirement) {
        bridgeFundingStep = await bridgeRoute(config, readOnlyProviders, ownerAddress, availableRoutingOptions, bridgeRequirement, tokenBalances, feeEstimates);
    }
    return bridgeFundingStep;
};
const getSwapFundingSteps = async (config, availableRoutingOptions, insufficientRequirement, ownerAddress, tokenBalances, swapTokenAllowList, balanceRequirements) => {
    const fundingSteps = [];
    if (!availableRoutingOptions.swap)
        return fundingSteps;
    if (insufficientRequirement === undefined)
        return fundingSteps;
    if (swapTokenAllowList === undefined)
        return fundingSteps;
    const tokenBalanceResult = tokenBalances.get(getL2ChainId(config));
    if (!tokenBalanceResult)
        return fundingSteps;
    if (tokenBalanceResult.error !== undefined || !tokenBalanceResult.success)
        return fundingSteps;
    if (swapTokenAllowList.length === 0)
        return fundingSteps;
    const swappableTokens = swapTokenAllowList
        .filter((token) => token.address).map((token) => token.address);
    if (swappableTokens.length === 0)
        return fundingSteps;
    return await swapRoute(config, availableRoutingOptions, ownerAddress, insufficientRequirement, tokenBalances, swappableTokens, balanceRequirements);
};
const getBridgeAndSwapFundingSteps = async (config, readOnlyProviders, availableRoutingOptions, insufficientRequirement, ownerAddress, tokenBalances, tokenAllowList, feeEstimates, balanceRequirements) => {
    if (!insufficientRequirement)
        return [];
    const l1balancesResult = tokenBalances.get(getL1ChainId(config));
    const l2balancesResult = tokenBalances.get(getL2ChainId(config));
    // If there are no l1 balance then cannot bridge
    if (!l1balancesResult)
        return [];
    if (l1balancesResult.error !== undefined || !l1balancesResult.success)
        return [];
    // If there are no l2 balance then cannot swap
    if (!l2balancesResult)
        return [];
    if (l2balancesResult.error !== undefined || !l2balancesResult.success)
        return [];
    // Get a list of all the swappable tokens
    const bridgeTokenAllowList = tokenAllowList?.bridge ?? [];
    const bridgeableL1Addresses = bridgeTokenAllowList.map((token) => {
        if (token.address === undefined)
            return INDEXER_ETH_ROOT_CONTRACT_ADDRESS;
        return token.address;
    });
    const swapTokenAllowList = tokenAllowList?.swap ?? [];
    if (insufficientRequirement.type !== ItemType.NATIVE && insufficientRequirement.type !== ItemType.ERC20) {
        return [];
    }
    const routes = await bridgeAndSwapRoute(config, readOnlyProviders, availableRoutingOptions, insufficientRequirement, ownerAddress, feeEstimates, tokenBalances, bridgeableL1Addresses, swapTokenAllowList, balanceRequirements);
    return routes;
};
const getOnRampFundingStep = async (config, availableRoutingOptions, insufficientRequirement) => {
    if (!availableRoutingOptions.onRamp)
        return undefined;
    if (insufficientRequirement === undefined)
        return undefined;
    const onRampFundingStep = await onRampRoute(config, availableRoutingOptions, insufficientRequirement);
    return onRampFundingStep;
};
const routingCalculator = async (config, ownerAddress, balanceRequirements, availableRoutingOptions) => {
    if (!hasAvailableRoutingOptions(availableRoutingOptions)) {
        return {
            type: RoutingOutcomeType.NO_ROUTE_OPTIONS,
            message: 'No routing options are available',
        };
    }
    let readOnlyProviders;
    try {
        readOnlyProviders = await createReadOnlyProviders(config);
    }
    catch (err) {
        throw new CheckoutError('Error occurred while creating read only providers', CheckoutErrorType.PROVIDER_ERROR, { message: err.message });
    }
    const tokenBalances = await measureAsyncExecution(config, 'Time to get token balances inside router', getAllTokenBalances(config, readOnlyProviders, ownerAddress, availableRoutingOptions));
    const allowList = await measureAsyncExecution(config, 'Time to get routing allowlist', allowListCheck(config, tokenBalances, availableRoutingOptions));
    // Fee estimate cache
    const feeEstimates = new Map();
    // Ensures only 1 balance requirement is insufficient
    const insufficientRequirement = getInsufficientRequirement(balanceRequirements);
    const routePromises = [];
    routePromises.push(getBridgeFundingStep(config, readOnlyProviders, availableRoutingOptions, insufficientRequirement, ownerAddress, tokenBalances, feeEstimates));
    routePromises.push(getSwapFundingSteps(config, availableRoutingOptions, insufficientRequirement, ownerAddress, tokenBalances, allowList.swap, balanceRequirements));
    routePromises.push(getOnRampFundingStep(config, availableRoutingOptions, insufficientRequirement));
    routePromises.push(getBridgeAndSwapFundingSteps(config, readOnlyProviders, availableRoutingOptions, insufficientRequirement, ownerAddress, tokenBalances, allowList, feeEstimates, balanceRequirements));
    const resolved = await measureAsyncExecution(config, 'Time to resolve all routes', Promise.all(routePromises));
    let bridgeFundingStep;
    let swapFundingSteps = [];
    let onRampFundingStep;
    let bridgeAndSwapFundingSteps = [];
    resolved.forEach((result, index) => {
        if (index === 0)
            bridgeFundingStep = result;
        if (index === 1)
            swapFundingSteps = result;
        if (index === 2)
            onRampFundingStep = result;
        if (index === 3)
            bridgeAndSwapFundingSteps = result;
    });
    if (!bridgeFundingStep
        && swapFundingSteps.length === 0
        && !onRampFundingStep
        && bridgeAndSwapFundingSteps.length === 0) {
        return {
            type: RoutingOutcomeType.NO_ROUTES_FOUND,
            message: 'Smart Checkout did not find any funding routes to fulfill the transaction',
        };
    }
    const response = {
        type: RoutingOutcomeType.ROUTES_FOUND,
        fundingRoutes: [],
    };
    let priority = 0;
    if (swapFundingSteps.length > 0) {
        priority++;
        swapFundingSteps.forEach((swapFundingStep) => {
            response.fundingRoutes.push({
                priority,
                steps: [swapFundingStep],
            });
        });
    }
    if (bridgeFundingStep) {
        priority++;
        response.fundingRoutes.push({
            priority,
            steps: [bridgeFundingStep],
        });
    }
    if (onRampFundingStep) {
        priority++;
        response.fundingRoutes.push({
            priority,
            steps: [onRampFundingStep],
        });
    }
    if (bridgeAndSwapFundingSteps) {
        priority++;
        bridgeAndSwapFundingSteps.forEach((bridgeAndSwapFundingStep) => {
            const bridgeStep = bridgeAndSwapFundingStep.bridgeFundingStep;
            const swapStep = bridgeAndSwapFundingStep.swapFundingStep;
            response.fundingRoutes.push({
                priority,
                steps: [bridgeStep, swapStep],
            });
        });
    }
    return response;
};

const smartCheckout = async (config, provider, itemRequirements, transactionOrGasAmount) => {
    const ownerAddress = await provider.getSigner().getAddress();
    let aggregatedItems = itemAggregator(itemRequirements);
    const erc20AllowancePromise = hasERC20Allowances(provider, ownerAddress, aggregatedItems);
    const erc721AllowancePromise = hasERC721Allowances(provider, ownerAddress, aggregatedItems);
    const resolvedAllowances = await measureAsyncExecution(config, 'Time to calculate token allowances', Promise.all([erc20AllowancePromise, erc721AllowancePromise]));
    const aggregatedAllowances = allowanceAggregator(resolvedAllowances[0], resolvedAllowances[1]);
    const gasItem = await measureAsyncExecution(config, 'Time to run gas calculator', gasCalculator(provider, aggregatedAllowances, transactionOrGasAmount));
    if (gasItem !== null) {
        aggregatedItems.push(gasItem);
        aggregatedItems = itemAggregator(aggregatedItems);
    }
    const balanceCheckResult = await measureAsyncExecution(config, 'Time to run balance checks', balanceCheck(config, provider, ownerAddress, aggregatedItems));
    const { sufficient } = balanceCheckResult;
    const transactionRequirements = balanceCheckResult.balanceRequirements;
    if (sufficient) {
        return {
            sufficient,
            transactionRequirements,
        };
    }
    const availableRoutingOptions = await measureAsyncExecution(config, 'Time to fetch available routing options', getAvailableRoutingOptions(config, provider));
    const routingOutcome = await measureAsyncExecution(config, 'Total time to run the routing calculator', routingCalculator(config, ownerAddress, balanceCheckResult, availableRoutingOptions));
    return {
        sufficient,
        transactionRequirements,
        router: {
            availableRoutingOptions,
            routingOutcome,
        },
    };
};

var SignTransactionStatusType;
(function (SignTransactionStatusType) {
    SignTransactionStatusType["SUCCESS"] = "SUCCESS";
    SignTransactionStatusType["FAILED"] = "FAILED";
})(SignTransactionStatusType || (SignTransactionStatusType = {}));

const signApprovalTransactions = async (provider, approvalTransactions) => {
    let receipts = [];
    try {
        const response = await Promise.all(approvalTransactions.map((transaction) => sendTransaction(provider, transaction)));
        receipts = await Promise.all(response.map((transaction) => transaction.transactionResponse.wait()));
    }
    catch (err) {
        throw new CheckoutError('An error occurred while executing the approval transaction', CheckoutErrorType.EXECUTE_APPROVAL_TRANSACTION_ERROR, {
            message: err.message,
        });
    }
    for (const receipt of receipts) {
        if (receipt.status === 0) {
            return {
                type: SignTransactionStatusType.FAILED,
                transactionHash: receipt.transactionHash,
                reason: 'Approval transaction failed and was reverted',
            };
        }
    }
    return {
        type: SignTransactionStatusType.SUCCESS,
    };
};
const signFulfillmentTransactions = async (provider, fulfillmentTransactions) => {
    let receipts = [];
    try {
        const response = await Promise.all(fulfillmentTransactions.map((transaction) => sendTransaction(provider, transaction)));
        receipts = await Promise.all(response.map((transaction) => transaction.transactionResponse.wait()));
    }
    catch (err) {
        throw new CheckoutError('An error occurred while executing the fulfillment transaction', CheckoutErrorType.EXECUTE_FULFILLMENT_TRANSACTION_ERROR, {
            message: err.message,
        });
    }
    for (const receipt of receipts) {
        if (receipt.status === 0) {
            return {
                type: SignTransactionStatusType.FAILED,
                transactionHash: receipt.transactionHash,
                reason: 'Fulfillment transaction failed and was reverted',
            };
        }
    }
    return {
        type: SignTransactionStatusType.SUCCESS,
    };
};
const signMessage = async (provider, unsignedMessage) => {
    try {
        // eslint-disable-next-line no-underscore-dangle
        const signedMessage = await provider.getSigner()._signTypedData(unsignedMessage.unsignedMessage.domain, unsignedMessage.unsignedMessage.types, unsignedMessage.unsignedMessage.value);
        return {
            orderComponents: unsignedMessage.orderComponents,
            orderHash: unsignedMessage.orderHash,
            signedMessage,
        };
    }
    catch (err) {
        throw new CheckoutError('An error occurred while signing the message', CheckoutErrorType.SIGN_MESSAGE_ERROR, {
            message: err.message,
        });
    }
};

const getUnsignedERC721Transactions = async (actions) => {
    let approvalTransactions = [];
    let fulfillmentTransactions = [];
    const approvalPromises = [];
    const fulfillmentPromises = [];
    for (const action of actions) {
        if (action.type !== ActionType.TRANSACTION)
            continue;
        if (action.purpose === TransactionPurpose.APPROVAL) {
            approvalPromises.push(action.buildTransaction());
        }
        if (action.purpose === TransactionPurpose.FULFILL_ORDER) {
            fulfillmentPromises.push(action.buildTransaction());
        }
    }
    approvalTransactions = await Promise.all(approvalPromises);
    fulfillmentTransactions = await Promise.all(fulfillmentPromises);
    return {
        approvalTransactions,
        fulfillmentTransactions,
    };
};
const getUnsignedERC20ApprovalTransactions = async (actions) => {
    let approvalTransactions = [];
    const approvalPromises = [];
    for (const action of actions) {
        if (action.type !== ActionType.TRANSACTION)
            continue;
        if (action.purpose === TransactionPurpose.APPROVAL) {
            approvalPromises.push(action.buildTransaction());
        }
    }
    approvalTransactions = await Promise.all(approvalPromises);
    return approvalTransactions;
};
const getUnsignedFulfillmentTransactions = async (actions) => {
    let fulfillmentTransactions = [];
    const fulfillmentPromises = [];
    for (const action of actions) {
        if (action.type !== ActionType.TRANSACTION)
            continue;
        if (action.purpose === TransactionPurpose.FULFILL_ORDER) {
            fulfillmentPromises.push(action.buildTransaction());
        }
    }
    fulfillmentTransactions = await Promise.all(fulfillmentPromises);
    return fulfillmentTransactions;
};
const getUnsignedMessage = (orderHash, orderComponents, actions) => {
    let unsignedMessage;
    for (const action of actions) {
        if (action.type !== ActionType.SIGNABLE)
            continue;
        if (action.purpose === SignablePurpose.CREATE_LISTING) {
            unsignedMessage = {
                domain: action.message.domain,
                types: action.message.types,
                value: action.message.value,
            };
        }
    }
    if (!unsignedMessage)
        return undefined;
    return {
        orderHash,
        orderComponents,
        unsignedMessage,
    };
};

const MAX_FEE_PERCENTAGE_DECIMAL = 1; // 100%
const MAX_FEE_DECIMAL_PLACES = 6; // will allow 0.000001 (0.0001%) as the minimum value
const calculateFeesPercent = (orderFee, amountBn) => {
    const feePercentage = orderFee.amount;
    // note: multiply in and out of the maximum decimal places to the power of ten to do the math in big number integers
    const feePercentageMultiplier = Math.round(feePercentage.percentageDecimal * (10 ** MAX_FEE_DECIMAL_PLACES));
    const bnFeeAmount = amountBn
        .mul(BigNumber.from(feePercentageMultiplier))
        .div(10 ** MAX_FEE_DECIMAL_PLACES);
    return bnFeeAmount;
};
const calculateFeesToken = (orderFee, decimals) => {
    const feeToken = orderFee.amount;
    const bnFeeAmount = utils.parseUnits(feeToken.token, decimals);
    return bnFeeAmount;
};
const calculateFees = (orderFees, weiAmount, decimals = 18) => {
    let totalTokenFees = BigNumber.from(0);
    const amountBn = BigNumber.from(weiAmount);
    // note: multiply in and out of the maximum decimal places to the power of ten to do the math in big number integers
    const totalAllowableFees = amountBn
        .mul(MAX_FEE_PERCENTAGE_DECIMAL * (10 ** MAX_FEE_DECIMAL_PLACES))
        .div(10 ** MAX_FEE_DECIMAL_PLACES);
    const calculateFeesResult = [];
    for (const orderFee of orderFees) {
        let currentFeeBn = BigNumber.from(0);
        if (Object.hasOwn(orderFee.amount, 'percentageDecimal')) {
            currentFeeBn = calculateFeesPercent(orderFee, amountBn);
            totalTokenFees = totalTokenFees.add(currentFeeBn);
        }
        else if (Object.hasOwn(orderFee.amount, 'token')) {
            currentFeeBn = calculateFeesToken(orderFee, decimals);
            totalTokenFees = totalTokenFees.add(currentFeeBn);
        }
        else {
            throw new CheckoutError('Unknown fee type parsed, must be percentageDecimal or token', CheckoutErrorType.ORDER_FEE_ERROR);
        }
        if (totalTokenFees.gt(totalAllowableFees)) {
            throw new CheckoutError(`The combined fees are above the allowed maximum of ${MAX_FEE_PERCENTAGE_DECIMAL * 100}%`, CheckoutErrorType.ORDER_FEE_ERROR);
        }
        if (currentFeeBn.gt(0)) {
            calculateFeesResult.push({
                amount: currentFeeBn.toString(),
                recipientAddress: orderFee.recipient,
            });
        }
    } // for
    return calculateFeesResult;
};

const getItemRequirement = (type, contractAddress, amount, spenderAddress) => {
    switch (type) {
        case ItemType.ERC20:
            return {
                type,
                amount,
                contractAddress,
                spenderAddress,
            };
        case ItemType.NATIVE:
        default:
            return {
                type: ItemType.NATIVE,
                amount,
            };
    }
};
const getTransactionOrGas = (gasLimit, fulfillmentTransactions) => {
    if (fulfillmentTransactions.length > 0) {
        return {
            type: TransactionOrGasType.TRANSACTION,
            transaction: fulfillmentTransactions[0],
        };
    }
    return {
        type: TransactionOrGasType.GAS,
        gasToken: {
            type: GasTokenType.NATIVE,
            limit: BigNumber.from(gasLimit),
        },
    };
};
const buy = async (config, provider, orders) => {
    if (orders.length === 0) {
        throw new CheckoutError('No orders were provided to the orders array. Please provide at least one order.', CheckoutErrorType.FULFILL_ORDER_LISTING_ERROR);
    }
    let order;
    let spenderAddress = '';
    let decimals = 18;
    const gasLimit = constants.estimatedFulfillmentGasGwei;
    const orderbook = createOrderbookInstance(config);
    const blockchainClient = createBlockchainDataInstance(config);
    const fulfillerAddress = await measureAsyncExecution(config, 'Time to get the address from the provider', provider.getSigner().getAddress());
    // Prefetch balances and store them in memory
    resetBlockscoutClientMap();
    getAllBalances(config, provider, fulfillerAddress, getL1ChainId(config));
    getAllBalances(config, provider, fulfillerAddress, getL2ChainId(config));
    const { id, takerFees } = orders[0];
    let orderChainName;
    try {
        order = await measureAsyncExecution(config, 'Time to fetch the listing from the orderbook', orderbook.getListing(id));
        const { seaportContractAddress, chainName } = orderbook.config();
        orderChainName = chainName;
        spenderAddress = seaportContractAddress;
    }
    catch (err) {
        throw new CheckoutError('An error occurred while getting the order listing', CheckoutErrorType.GET_ORDER_LISTING_ERROR, {
            orderId: id,
            message: err.message,
        });
    }
    if (order.result.buy.length === 0) {
        throw new CheckoutError('An error occurred with the get order listing', CheckoutErrorType.GET_ORDER_LISTING_ERROR, {
            orderId: id,
            message: 'No buy side tokens found on order',
        });
    }
    const buyToken = order.result.buy[0];
    if (buyToken.type === 'ERC20') {
        const token = await measureAsyncExecution(config, 'Time to get decimals of token contract for the buy token', blockchainClient.getToken({ contractAddress: buyToken.contractAddress, chainName: orderChainName }));
        if (token.result.decimals)
            decimals = token.result.decimals;
    }
    let fees = [];
    if (takerFees && takerFees.length > 0) {
        fees = calculateFees(takerFees, buyToken.amount, decimals);
    }
    let unsignedApprovalTransactions = [];
    let unsignedFulfillmentTransactions = [];
    let orderActions = [];
    const fulfillOrderStartTime = performance.now();
    try {
        const { actions } = await measureAsyncExecution(config, 'Time to call fulfillOrder from the orderbook', orderbook.fulfillOrder(id, fulfillerAddress, fees));
        orderActions = actions;
        unsignedApprovalTransactions = await measureAsyncExecution(config, 'Time to construct the unsigned approval transactions', getUnsignedERC20ApprovalTransactions(actions));
    }
    catch (err) {
        const elapsedTimeInSeconds = (performance.now() - fulfillOrderStartTime) / 1000;
        debugLogger(config, 'Time to call fulfillOrder from the orderbook', elapsedTimeInSeconds);
        if (err.message.includes(OrderStatusName.EXPIRED)) {
            throw new CheckoutError('Order is expired', CheckoutErrorType.ORDER_EXPIRED_ERROR, { orderId: id });
        }
        // The balances error will be handled by bulk order fulfillment but for now we
        // need to assert on this string to check that the error is not a balances error
        if (!err.message.includes('The fulfiller does not have the balances needed to fulfill')) {
            throw new CheckoutError('Error occurred while trying to fulfill the order', CheckoutErrorType.FULFILL_ORDER_LISTING_ERROR, {
                orderId: id,
                message: err.message,
            });
        }
    }
    try {
        unsignedFulfillmentTransactions = await measureAsyncExecution(config, 'Time to construct the unsigned fulfillment transactions', getUnsignedFulfillmentTransactions(orderActions));
    }
    catch {
        // if cannot estimate gas then silently continue and use gas limit in smartCheckout
        // but get the fulfillment transactions after they have approved the spending
    }
    let amount = BigNumber.from('0');
    let type = ItemType.NATIVE;
    let contractAddress = '';
    const buyArray = order.result.buy;
    if (buyArray.length > 0) {
        switch (buyArray[0].type) {
            case 'NATIVE':
                type = ItemType.NATIVE;
                break;
            case 'ERC20':
                type = ItemType.ERC20;
                contractAddress = buyArray[0].contractAddress;
                break;
            default:
                throw new CheckoutError('Purchasing token type is unsupported', CheckoutErrorType.UNSUPPORTED_TOKEN_TYPE_ERROR, {
                    orderId: id,
                });
        }
    }
    buyArray.forEach((item) => {
        if (item.type !== ItemType.ERC721) {
            amount = amount.add(BigNumber.from(item.amount));
        }
    });
    const feeArray = order.result.fees;
    feeArray.forEach((item) => {
        amount = amount.add(BigNumber.from(item.amount));
    });
    const itemRequirements = [
        getItemRequirement(type, contractAddress, amount, spenderAddress),
    ];
    const smartCheckoutResult = await measureAsyncExecution(config, 'Total time running smart checkout', smartCheckout(config, provider, itemRequirements, getTransactionOrGas(gasLimit, unsignedFulfillmentTransactions)));
    if (smartCheckoutResult.sufficient) {
        const approvalResult = await signApprovalTransactions(provider, unsignedApprovalTransactions);
        if (approvalResult.type === SignTransactionStatusType.FAILED) {
            return {
                status: CheckoutStatus.FAILED,
                transactionHash: approvalResult.transactionHash,
                reason: approvalResult.reason,
                smartCheckoutResult,
            };
        }
        try {
            if (unsignedFulfillmentTransactions.length === 0) {
                unsignedFulfillmentTransactions = await getUnsignedFulfillmentTransactions(orderActions);
            }
        }
        catch (err) {
            throw new CheckoutError('Error fetching fulfillment transaction', CheckoutErrorType.FULFILL_ORDER_LISTING_ERROR, {
                message: err.message,
            });
        }
        const fulfillmentResult = await signFulfillmentTransactions(provider, unsignedFulfillmentTransactions);
        if (fulfillmentResult.type === SignTransactionStatusType.FAILED) {
            return {
                status: CheckoutStatus.FAILED,
                transactionHash: fulfillmentResult.transactionHash,
                reason: fulfillmentResult.reason,
                smartCheckoutResult,
            };
        }
        return {
            status: CheckoutStatus.SUCCESS,
            smartCheckoutResult,
        };
    }
    return {
        status: CheckoutStatus.INSUFFICIENT_FUNDS,
        smartCheckoutResult,
    };
};

const cancel = async (config, provider, orderIds) => {
    let unsignedCancelOrderTransaction;
    if (orderIds.length === 0) {
        throw new CheckoutError('No orderIds were provided to the orderIds array. Please provide at least one orderId.', CheckoutErrorType.CANCEL_ORDER_LISTING_ERROR);
    }
    // Update this when bulk cancel is supported
    const orderId = orderIds[0];
    try {
        const offererAddress = await measureAsyncExecution(config, 'Time to get the address from the provider', provider.getSigner().getAddress());
        const orderbook = createOrderbookInstance(config);
        const cancelOrderResponse = await measureAsyncExecution(config, 'Time to get the cancel order from the orderbook', orderbook.cancelOrdersOnChain([orderId], offererAddress));
        unsignedCancelOrderTransaction = await cancelOrderResponse.cancellationAction.buildTransaction();
    }
    catch (err) {
        throw new CheckoutError('An error occurred while cancelling the order listing', CheckoutErrorType.CANCEL_ORDER_LISTING_ERROR, {
            orderId,
            message: err.message,
        });
    }
    const result = await signFulfillmentTransactions(provider, [unsignedCancelOrderTransaction]);
    if (result.type === SignTransactionStatusType.FAILED) {
        return {
            status: CheckoutStatus.FAILED,
            transactionHash: result.transactionHash,
            reason: result.reason,
        };
    }
    return {
        status: CheckoutStatus.SUCCESS,
    };
};

const getERC721Requirement = (id, contractAddress, spenderAddress) => ({
    type: ItemType.ERC721,
    id,
    contractAddress,
    spenderAddress,
});
const getBuyToken = (buyToken, decimals = 18) => {
    const bnAmount = utils.parseUnits(buyToken.amount, decimals);
    if (buyToken.type === ItemType.NATIVE) {
        return {
            type: ItemType.NATIVE,
            amount: bnAmount.toString(),
        };
    }
    return {
        type: ItemType.ERC20,
        amount: bnAmount.toString(),
        contractAddress: buyToken.contractAddress,
    };
};
const sell = async (config, provider, orders) => {
    let orderbook;
    let listing;
    let spenderAddress = '';
    if (orders.length === 0) {
        throw new CheckoutError('No orders were provided to the orders array. Please provide at least one order.', CheckoutErrorType.PREPARE_ORDER_LISTING_ERROR);
    }
    const { buyToken, sellToken, makerFees } = orders[0];
    let decimals = 18;
    if (buyToken.type === ItemType.ERC20) {
        // get this from the allowed list
        const buyTokenContract = new Contract(buyToken.contractAddress, JSON.stringify(ERC20ABI), provider);
        decimals = await measureAsyncExecution(config, 'Time to get decimals of token contract for the buy token', buyTokenContract.decimals());
    }
    const buyTokenOrNative = getBuyToken(buyToken, decimals);
    try {
        const walletAddress = await measureAsyncExecution(config, 'Time to get the address from the provider', provider.getSigner().getAddress());
        orderbook = createOrderbookInstance(config);
        const { seaportContractAddress } = orderbook.config();
        spenderAddress = seaportContractAddress;
        listing = await measureAsyncExecution(config, 'Time to prepare the listing from the orderbook', orderbook.prepareListing({
            makerAddress: walletAddress,
            buy: buyTokenOrNative,
            sell: {
                type: ItemType.ERC721,
                contractAddress: sellToken.collectionAddress,
                tokenId: sellToken.id,
            },
        }));
    }
    catch (err) {
        throw new CheckoutError('An error occurred while preparing the listing', CheckoutErrorType.PREPARE_ORDER_LISTING_ERROR, {
            message: err.message,
            id: sellToken.id,
            collectionAddress: sellToken.collectionAddress,
        });
    }
    const itemRequirements = [
        getERC721Requirement(sellToken.id, sellToken.collectionAddress, spenderAddress),
    ];
    const smartCheckoutResult = await measureAsyncExecution(config, 'Total time running smart checkout', smartCheckout(config, provider, itemRequirements, {
        type: TransactionOrGasType.GAS,
        gasToken: {
            type: GasTokenType.NATIVE,
            limit: BigNumber.from(constants.estimatedFulfillmentGasGwei),
        },
    }));
    if (smartCheckoutResult.sufficient) {
        const unsignedTransactions = await getUnsignedERC721Transactions(listing.actions);
        const approvalResult = await signApprovalTransactions(provider, unsignedTransactions.approvalTransactions);
        if (approvalResult.type === SignTransactionStatusType.FAILED) {
            return {
                status: CheckoutStatus.FAILED,
                transactionHash: approvalResult.transactionHash,
                reason: approvalResult.reason,
                smartCheckoutResult,
            };
        }
        const unsignedMessage = getUnsignedMessage(listing.orderHash, listing.orderComponents, listing.actions);
        if (!unsignedMessage) {
            // For sell it is expected the orderbook will always return an unsigned message
            // If for some reason it is missing then we cannot proceed with the create listing
            throw new CheckoutError('The unsigned message is missing after preparing the listing', CheckoutErrorType.SIGN_MESSAGE_ERROR, {
                id: sellToken.id,
                collectionAddress: sellToken.collectionAddress,
            });
        }
        const signedMessage = await signMessage(provider, unsignedMessage);
        let orderId = '';
        const createListingParams = {
            orderComponents: signedMessage.orderComponents,
            orderHash: signedMessage.orderHash,
            orderSignature: signedMessage.signedMessage,
            makerFees: [],
        };
        if (makerFees !== undefined) {
            const orderBookFees = calculateFees(makerFees, buyTokenOrNative.amount, decimals);
            if (orderBookFees.length !== makerFees.length) {
                throw new CheckoutError('One of the fees is too small, must be greater than 0.000001', CheckoutErrorType.CREATE_ORDER_LISTING_ERROR);
            }
            createListingParams.makerFees = orderBookFees;
        }
        try {
            const order = await orderbook.createListing(createListingParams);
            orderId = order.result.id;
        }
        catch (err) {
            throw new CheckoutError('An error occurred while creating the listing', CheckoutErrorType.CREATE_ORDER_LISTING_ERROR, {
                message: err.message,
                collectionId: sellToken.id,
                collectionAddress: sellToken.collectionAddress,
            });
        }
        return {
            status: CheckoutStatus.SUCCESS,
            orderIds: [orderId],
            smartCheckoutResult,
        };
    }
    return {
        status: CheckoutStatus.INSUFFICIENT_FUNDS,
        smartCheckoutResult,
    };
};

class FiatRampService {
    config;
    /**
     * Constructs a new instance of the FiatRampService class.
     * @param {CheckoutConfiguration} config - The config required for the FiatRampService.
     */
    constructor(config) {
        this.config = config;
    }
    async feeEstimate() {
        const config = (await this.config.remote.getConfig('onramp'));
        return config[OnRampProvider.TRANSAK]?.fees;
    }
    async createWidgetUrl(params) {
        return (await this.getTransakWidgetUrl(params));
    }
    async getTransakWidgetUrl(params) {
        let widgetUrl = `${TRANSAK_API_BASE_URL[this.config.environment]}?`;
        const onRampConfig = (await this.config.remote.getConfig('onramp'));
        const apiKey = onRampConfig[OnRampProvider.TRANSAK].publishableApiKey;
        const transakPublishableKey = `apiKey=${apiKey}`;
        const zkevmNetwork = 'network=immutablezkevm';
        const defaultPaymentMethod = 'defaultPaymentMethod=credit_debit_card';
        const disableBankTransfer = 'disablePaymentMethods=sepa_bank_transfer,gbp_bank_transfer,'
            + 'pm_cash_app,pm_jwire,pm_paymaya,pm_bpi,pm_ubp,pm_grabpay,pm_shopeepay,pm_gcash,pm_pix,'
            + 'pm_astropay,pm_pse,inr_bank_transfer';
        const productsAvailed = 'productsAvailed=buy';
        const exchangeScreenTitle = 'exchangeScreenTitle=Buy';
        const themeColor = 'themeColor=0D0D0D';
        widgetUrl += `${transakPublishableKey}&`
            + `${zkevmNetwork}&`
            + `${defaultPaymentMethod}&`
            + `${disableBankTransfer}&`
            + `${productsAvailed}&`
            + `${exchangeScreenTitle}&`
            + `${themeColor}`;
        if (params.isPassport && params.email) {
            const encodedEmail = encodeURIComponent(params.email);
            widgetUrl += `&email=${encodedEmail}&isAutoFillUserData=true&disableWalletAddressForm=true`;
        }
        if (params.tokenAmount && params.tokenSymbol) {
            widgetUrl += `&defaultCryptoAmount=${params.tokenAmount}&cryptoCurrencyCode=${params.tokenSymbol}`;
        }
        else {
            widgetUrl += '&defaultCryptoCurrency=IMX';
        }
        if (params.walletAddress) {
            widgetUrl += `&walletAddress=${params.walletAddress}`;
        }
        return widgetUrl;
    }
}

async function getItemRequirementsFromRequirements(provider, requirements) {
    // Get all decimal values by calling contracts for each ERC20
    const decimalPromises = [];
    requirements.forEach((itemRequirementParam) => {
        if (itemRequirementParam.type === ItemType.ERC20) {
            const { contractAddress } = itemRequirementParam;
            decimalPromises.push(getTokenContract(contractAddress, ERC20ABI, provider).decimals());
        }
    });
    const decimals = await Promise.all(decimalPromises);
    // Map ItemRequirementsParam objects to ItemRequirement by parsing amounts from formatted string to BigNumebrs
    const itemRequirements = requirements.map((itemRequirementParam, index) => {
        if (itemRequirementParam.type === ItemType.NATIVE) {
            return {
                ...itemRequirementParam,
                amount: utils.parseUnits(itemRequirementParam.amount, 18),
            };
        }
        if (itemRequirementParam.type === ItemType.ERC20) {
            return {
                ...itemRequirementParam,
                amount: utils.parseUnits(itemRequirementParam.amount, decimals[index]),
            };
        }
        return itemRequirementParam;
    });
    return itemRequirements;
}

/**
 * Validates and builds a version string based on the given SemanticVersion object.
 * If the version is undefined or has an invalid major version, it returns the default checkout version.
 * If the version is all zeros, it also returns the default checkout version.
 * Otherwise, it constructs a validated version string based on the major, minor, patch, and build numbers.
 */
function validateAndBuildVersion(version) {
    const defaultPackageVersion = globalPackageVersion();
    if (version === undefined || version.major === undefined)
        return defaultPackageVersion;
    if (!Number.isInteger(version.major) || version.major < 0)
        return defaultPackageVersion;
    if (version.minor !== undefined && version.minor < 0)
        return defaultPackageVersion;
    if (version.patch !== undefined && version.patch < 0)
        return defaultPackageVersion;
    if (version.major === 0 && version.minor === undefined)
        return defaultPackageVersion;
    if (version.major === 0 && version.minor === 0 && version.patch === undefined)
        return defaultPackageVersion;
    if (version.major === 0 && version.minor === undefined && version.patch === undefined)
        return defaultPackageVersion;
    if (version.major === 0 && version.minor === 0 && version.patch === 0)
        return defaultPackageVersion;
    let validatedVersion = version.major.toString();
    if (version.minor === undefined)
        return validatedVersion;
    if (Number.isInteger(version.minor)) {
        validatedVersion += `.${version.minor.toString()}`;
    }
    if (version.patch === undefined)
        return validatedVersion;
    if (Number.isInteger(version.patch)) {
        validatedVersion += `.${version.patch.toString()}`;
    }
    if (version.prerelease === undefined || version.prerelease !== 'alpha')
        return validatedVersion;
    if (version.prerelease === 'alpha') {
        validatedVersion += `-${version.prerelease}`;
    }
    if (version.build === undefined)
        return validatedVersion;
    if (Number.isInteger(version.build) && version.build >= 0) {
        validatedVersion += `.${version.build.toString()}`;
    }
    return validatedVersion;
}

function loadUnresolved(version) {
    if (window === undefined) {
        throw new Error('missing window object: please run Checkout client side');
    }
    if (document === undefined) {
        throw new Error('missing document object: please run Checkout client side');
    }
    const scriptId = 'immutable-checkout-widgets-bundle';
    const validVersion = validateAndBuildVersion(version);
    // Prevent the script to be loaded more than once
    // by checking the presence of the script and its version.
    const initScript = document.getElementById(scriptId);
    if (initScript)
        return { loaded: true, element: initScript };
    const tag = document.createElement('script');
    let cdnUrl = `https://cdn.jsdelivr.net/npm/@imtbl/sdk@${validVersion}/dist/browser/checkout/widgets.js`;
    tag.setAttribute('id', scriptId);
    tag.setAttribute('data-version', validVersion);
    tag.setAttribute('src', cdnUrl);
    document.head.appendChild(tag);
    return { loaded: false, element: tag };
}

const SANDBOX_CONFIGURATION = {
    baseConfig: {
        environment: Environment.SANDBOX,
    },
    passport: undefined,
};
const WIDGETS_SCRIPT_TIMEOUT = 100;
// Checkout SDK
class Checkout {
    readOnlyProviders;
    config;
    fiatRampService;
    availability;
    passport;
    /**
     * Constructs a new instance of the CheckoutModule class.
     * @param {CheckoutModuleConfiguration} [config=SANDBOX_CONFIGURATION] - The configuration object for the CheckoutModule.
     */
    constructor(config = SANDBOX_CONFIGURATION) {
        this.config = new CheckoutConfiguration(config);
        this.fiatRampService = new FiatRampService(this.config);
        this.readOnlyProviders = new Map();
        this.availability = availabilityService(this.config.isDevelopment, this.config.isProduction);
        this.passport = config.passport;
    }
    /**
     * Loads the widgets bundle and initiate the widgets factory.
     * @param {WidgetsInit} init - The initialisation parameters for loading the widgets bundle and applying configuration
     */
    async widgets(init) {
        const checkout = this;
        const factory = new Promise((resolve, reject) => {
            function checkForWidgetsBundleLoaded() {
                if (typeof ImmutableCheckoutWidgets !== 'undefined') {
                    resolve(new ImmutableCheckoutWidgets.WidgetsFactory(checkout, init.config));
                }
                else {
                    // If ImmutableCheckoutWidgets is not defined, wait for set amount of time.
                    // When time has elapsed, check again if ImmutableCheckoutWidgets is defined.
                    // Once it's defined, the promise will resolve and setTimeout won't be called again.
                    setTimeout(checkForWidgetsBundleLoaded, WIDGETS_SCRIPT_TIMEOUT);
                }
            }
            try {
                const script = loadUnresolved(init.version);
                if (script.loaded && typeof ImmutableCheckoutWidgets !== 'undefined') {
                    // eslint-disable-next-line no-console
                    console.warn('Checkout widgets script is already loaded');
                    resolve(new ImmutableCheckoutWidgets.WidgetsFactory(checkout, init.config));
                }
                else {
                    checkForWidgetsBundleLoaded();
                }
            }
            catch (err) {
                reject(new CheckoutError('Failed to load widgets script', CheckoutErrorType.WIDGETS_SCRIPT_LOAD_ERROR, { message: err.message }));
            }
        });
        return factory;
    }
    /**
     * Creates a provider using the given parameters.
     * @param {CreateProviderParams} params - The parameters for creating the provider.
     * @returns {Promise<CreateProviderResult>} A promise that resolves to the created provider.
     */
    async createProvider(params) {
        return await createProvider(params.walletProviderName, this.passport);
    }
    /**
     * Checks if a wallet is connected to the specified provider.
     * @param {CheckConnectionParams} params - The parameters for checking the wallet connection.
     * @returns {Promise<CheckConnectionResult>} - A promise that resolves to the result of the check.
     */
    async checkIsWalletConnected(params) {
        const web3Provider = await validateProvider(this.config, params.provider, { allowUnsupportedProvider: true });
        return checkIsWalletConnected(web3Provider);
    }
    /**
     * Connects to a blockchain network using the specified provider.
     * @param {ConnectParams} params - The parameters for connecting to the network.
     * @returns {Promise<ConnectResult>} A promise that resolves to an object containing the provider and network information.
     * @throws {Error} If the provider is not valid or if there is an error connecting to the network.
     */
    async connect(params) {
        const web3Provider = await validateProvider(this.config, params.provider, { allowUnsupportedProvider: true });
        await connectSite(web3Provider);
        return { provider: web3Provider };
    }
    /**
     * Switches the network for the current wallet provider.
     * @param {SwitchNetworkParams} params - The parameters for switching the network.
     * @returns {Promise<SwitchNetworkResult>} - A promise that resolves to the result of switching the network.
     */
    async switchNetwork(params) {
        const web3Provider = await validateProvider(this.config, params.provider, {
            allowUnsupportedProvider: true,
            allowMistmatchedChainId: true,
        });
        const switchNetworkRes = await switchWalletNetwork(this.config, web3Provider, params.chainId);
        return switchNetworkRes;
    }
    /**
     * Retrieves the balance of a wallet address.
     * @param {GetBalanceParams} params - The parameters for retrieving the balance.
     * @returns {Promise<GetBalanceResult>} - A promise that resolves to the balance result.
     */
    async getBalance(params) {
        const web3Provider = await validateProvider(this.config, params.provider);
        if (!params.contractAddress || params.contractAddress === '') {
            return await getBalance(this.config, web3Provider, params.walletAddress);
        }
        return await getERC20Balance(web3Provider, params.walletAddress, params.contractAddress);
    }
    /**
     * Retrieves the balances of all tokens for a given wallet address on a specific chain.
     * @param {GetAllBalancesParams} params - The parameters for retrieving the balances.
     * @returns {Promise<GetAllBalancesResult>} - A promise that resolves to the result of retrieving the balances.
     */
    async getAllBalances(params) {
        const web3Provider = await validateProvider(this.config, params.provider);
        return getAllBalances(this.config, web3Provider, params.walletAddress, params.chainId);
    }
    /**
     * Retrieves the supported networks based on the provided parameters.
     * @param {GetNetworkAllowListParams} params - The parameters for retrieving the network allow list.
     * @returns {Promise<GetNetworkAllowListResult>} - A promise that resolves to the network allow list result.
     */
    async getNetworkAllowList(params) {
        return await getNetworkAllowList(this.config, params);
    }
    /**
     * Retrieves the supported tokens based on the provided parameters.
     * @param {GetTokenAllowListParams} params - The parameters for retrieving the token allow list.
     * @returns {Promise<GetTokenAllowListResult>} - A promise that resolves to the token allow list result.
     */
    async getTokenAllowList(params) {
        return await getTokenAllowList(this.config, params);
    }
    /**
     * Retrieves the default supported wallets based on the provided parameters.
     * @param {GetWalletAllowListParams} params - The parameters for retrieving the wallet allow list.
     * @returns {Promise<GetWalletAllowListResult>} - A promise that resolves to the wallet allow list result.
     */
    async getWalletAllowList(params) {
        return await getWalletAllowList(params);
    }
    /**
     * Sends a transaction using the specified provider and transaction parameters.
     * @param {SendTransactionParams} params - The parameters for sending the transaction.
     * @returns {Promise<SendTransactionResult>} A promise that resolves to the result of the transaction.
     */
    async sendTransaction(params) {
        const web3Provider = await validateProvider(this.config, params.provider);
        return await sendTransaction(web3Provider, params.transaction);
    }
    /**
     * Retrieves network information using the specified provider.
     * @param {GetNetworkParams} params - The parameters for retrieving network information.
     * @returns {Promise<NetworkInfo>} A promise that resolves to the network information.
     */
    async getNetworkInfo(params) {
        const web3Provider = await validateProvider(this.config, params.provider, {
            allowUnsupportedProvider: true,
            allowMistmatchedChainId: true,
        });
        return await getNetworkInfo(this.config, web3Provider);
    }
    /**
     * Determines the requirements for performing a buy.
     * @param {BuyParams} params - The parameters for the buy.
    */
    async buy(params) {
        if (params.orders.length > 1) {
            // eslint-disable-next-line no-console
            console.warn('This endpoint currently only processes the first order in the array.');
        }
        const web3Provider = await validateProvider(this.config, params.provider);
        return await buy(this.config, web3Provider, params.orders);
    }
    /**
     * Determines the requirements for performing a sell.
     * @param {SellParams} params - The parameters for the sell.
     * Only currently actions the first order in the array until we support batch processing.
     * Only currently actions the first fee in the fees array of each order until we support multiple fees.
    */
    async sell(params) {
        if (params.orders.length > 1) {
            // eslint-disable-next-line no-console
            console.warn('This endpoint currently only processes the first order in the array.');
        }
        const web3Provider = await validateProvider(this.config, params.provider);
        return await sell(this.config, web3Provider, params.orders);
    }
    /**
     * Cancels a sell.
     * @param {CancelParams} params - The parameters for the cancel.
     */
    async cancel(params) {
        // eslint-disable-next-line no-console
        console.warn('This endpoint currently only processes the first order in the array.');
        const web3Provider = await validateProvider(this.config, params.provider);
        return await cancel(this.config, web3Provider, params.orderIds);
    }
    /**
     * Determines the transaction requirements to complete a purchase.
     * @params {SmartCheckoutParams} params - The parameters for smart checkout.
     */
    async smartCheckout(params) {
        const web3Provider = await validateProvider(this.config, params.provider);
        let itemRequirements = [];
        try {
            itemRequirements = await getItemRequirementsFromRequirements(web3Provider, params.itemRequirements);
        }
        catch {
            throw new CheckoutError('Failed to map item requirements', CheckoutErrorType.ITEM_REQUIREMENTS_ERROR);
        }
        return await smartCheckout(this.config, web3Provider, itemRequirements, params.transactionOrGasAmount);
    }
    /**
     * Checks if the given object is a Web3 provider.
     * @param {Web3Provider} web3Provider - The object to check.
     * @returns {boolean} - True if the object is a Web3 provider, false otherwise.
     */
    static isWeb3Provider(web3Provider) {
        return isWeb3Provider(web3Provider);
    }
    /**
     * Estimates the gas required for a swap or bridge transaction.
     * @param {GasEstimateParams} params - The parameters for the gas estimation.
     * @returns {Promise<GasEstimateSwapResult | GasEstimateBridgeToL2Result>} - A promise that resolves to the gas estimation result.
     */
    async gasEstimate(params) {
        this.readOnlyProviders = await createReadOnlyProviders(this.config, this.readOnlyProviders);
        return await gasEstimator(params, this.readOnlyProviders, this.config);
    }
    /**
     * Creates and returns a URL for the fiat ramp widget.
     * @param {FiatRampParams} params - The parameters for creating the url.
     * @returns {Promise<string>} - A promise that resolves to a string url.
     */
    async createFiatRampUrl(params) {
        let tokenAmount;
        let tokenSymbol = 'IMX';
        let email;
        const walletAddress = await params.web3Provider.getSigner().getAddress();
        const isPassport = params.web3Provider.provider?.isPassport || false;
        if (isPassport && params.passport) {
            const userInfo = await params.passport.getUserInfo();
            email = userInfo?.email;
        }
        const tokenList = await getTokenAllowList(this.config, { type: TokenFilterTypes.ONRAMP });
        const token = tokenList.tokens?.find((t) => t.address?.toLowerCase() === params.tokenAddress?.toLowerCase());
        if (token) {
            tokenAmount = params.tokenAmount;
            tokenSymbol = token.symbol;
        }
        return await this.fiatRampService.createWidgetUrl({
            exchangeType: params.exchangeType,
            isPassport,
            walletAddress,
            tokenAmount,
            tokenSymbol,
            email,
        });
    }
    /**
     * Fetches fiat ramp fee estimations.
     * @returns {Promise<OnRampProviderFees>} - A promise that resolves to OnRampProviderFees.
     */
    async getExchangeFeeEstimate() {
        return await this.fiatRampService.feeEstimate();
    }
    /**
     * Fetches Swap widget availability.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean.
     */
    async isSwapAvailable() {
        return this.availability.checkDexAvailability();
    }
}

export { BridgeEventType, CHECKOUT_API_BASE_URL, ChainId, ChainName, Checkout, CheckoutConfiguration, CheckoutErrorType, CheckoutStatus, ConnectEventType, ConnectTargetLayer, ExchangeType, FundingStepType, GasEstimateType, GasTokenType, IMTBLWidgetEvents, ItemType, NetworkFilterTypes, OnRampEventType, OrchestrationEventType, ProviderEventType, RoutingOutcomeType, SaleEventType, SwapEventType, TokenFilterTypes, TransactionOrGasType, WalletEventType, WalletFilterTypes, WalletProviderName, WidgetTheme, WidgetType };
