import { useEffect } from "react";

import { config, passport } from "@imtbl/sdk";
import { ClientIdDevelopment, RedirectUrlDev } from "../../enums/Constants";

function Callback() {
  useEffect(() => {
    const passportInstance = new passport.Passport({
      baseConfig: {
        environment: config.Environment.SANDBOX,
      },
      clientId: ClientIdDevelopment,
      redirectUri: RedirectUrlDev,
    });

    passportInstance.loginCallback();
  }, []);

  return <div>You can close this window.</div>;
}

export default Callback;
