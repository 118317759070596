import { mr } from '@imtbl/generated-clients';
export { ActivitiesTypes, ChainsTypes, CollectionsTypes, MetadataTypes, NFTOwnersTypes, NFTsTypes, TokensTypes } from '@imtbl/generated-clients';
import { Environment } from '@imtbl/config';
import axios from 'axios';

/**
 * Custom Error class that is returned from the API when a request fails
 */
class APIError extends Error {
    code;
    details;
    link;
    traceId;
    constructor({ message, code, details, link, trace_id: traceId, }) {
        super(message);
        this.code = code;
        this.details = details;
        this.link = link;
        this.traceId = traceId;
    }
}

/* eslint-disable implicit-arrow-linebreak */
const defaultHeaders = {
    sdkVersion: 'ts-immutable-sdk-multi-rollup-api-client-__SDK_VERSION__',
};
/**
 * createAPIConfiguration to create a custom Configuration
 * other than the production and sandbox defined below.
 */
const createAPIConfiguration = ({ baseConfig, basePath, headers: baseHeaders, }) => {
    if (!basePath.trim()) {
        throw Error('basePath can not be empty');
    }
    const headers = { ...(baseHeaders || {}), ...defaultHeaders };
    const configParams = {
        ...baseConfig,
        basePath,
        baseOptions: { headers },
    };
    return new mr.Configuration(configParams);
};
class BlockchainDataConfiguration {
    apiConfig;
    baseConfig;
    constructor({ baseConfig, overrides }) {
        this.baseConfig = baseConfig;
        if (overrides) {
            this.apiConfig = createAPIConfiguration(overrides);
        }
        else {
            switch (baseConfig.environment) {
                case Environment.SANDBOX: {
                    this.apiConfig = createAPIConfiguration({
                        basePath: 'https://api.sandbox.immutable.com',
                        baseConfig,
                    });
                    break;
                }
                case Environment.PRODUCTION: {
                    this.apiConfig = createAPIConfiguration({
                        basePath: 'https://api.immutable.com',
                        baseConfig,
                    });
                    break;
                }
                default: {
                    this.apiConfig = createAPIConfiguration({
                        basePath: 'https://api.sandbox.immutable.com',
                        baseConfig,
                    });
                }
            }
        }
    }
}

/* eslint-disable @typescript-eslint/naming-convention */
/**
 * [Formats an error in the APIError shape](https://axios-http.com/docs/handling_errors)
 * @param error - The Error object thrown by the request
 * @returns APIError
 */
function formatError(error) {
    if (axios.isAxiosError(error) && error.response) {
        const apiError = error.response.data;
        if (apiError.code && apiError.message) {
            return new APIError({
                code: apiError.code,
                message: apiError.message,
                details: apiError.details || null,
                link: apiError.link || '',
                trace_id: apiError.trace_id || '',
            });
        }
        return new APIError({
            code: error.code ?? error.response?.status.toString() ?? 'unknown_error_code',
            message: String(error),
            details: null,
            link: '',
            trace_id: '',
        });
    }
    return new APIError({
        code: 'unknown_error_code',
        message: String(error),
        details: null,
        link: '',
        trace_id: '',
    });
}

class BlockchainData {
    config;
    activities;
    chains;
    collections;
    nfts;
    nftOwners;
    tokens;
    metadata;
    constructor(moduleConfig) {
        this.config = new BlockchainDataConfiguration(moduleConfig);
        this.activities = new mr.ActivitiesApi(this.config.apiConfig);
        this.chains = new mr.ChainsApi(this.config.apiConfig);
        this.collections = new mr.CollectionsApi(this.config.apiConfig);
        this.nfts = new mr.NftsApi(this.config.apiConfig);
        this.nftOwners = new mr.NftOwnersApi(this.config.apiConfig);
        this.tokens = new mr.TokensApi(this.config.apiConfig);
        this.metadata = new mr.MetadataApi(this.config.apiConfig);
    }
    /**
     * List all activities
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of activities
     * @throws {@link index.APIError}
     */
    async listActivities(request) {
        return await this.activities
            .listActivities(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Get a single activity by ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a single activity
     * @throws {@link index.APIError}
     */
    async getActivity(request) {
        return await this.activities
            .getActivity(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List supported chains
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of supported chains
     * @throws {@link index.APIError}
     */
    async listChains(request) {
        return await this.chains
            .listChains(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List all collections
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of collections
     * @throws {@link index.APIError}
     */
    async listCollections(request) {
        return await this.collections
            .listCollections(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List collections by NFT owner
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of collections
     * @throws {@link index.APIError}
     */
    async listCollectionsByNFTOwner(request) {
        return await this.collections
            .listCollectionsByNFTOwner(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Get collection by contract address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a single collection
     * @throws {@link index.APIError}
     */
    async getCollection(request) {
        return await this.collections
            .getCollection(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Get NFT by token ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a single NFT
     * @throws {@link index.APIError}
     */
    async getNFT(request) {
        return await this.nfts
            .getNFT(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List NFTs by contract address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of NFTs
     * @throws {@link index.APIError}
     */
    async listNFTs(request) {
        return await this.nfts
            .listNFTs(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List NFTs by account address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of NFTs
     * @throws {@link index.APIError}
     */
    async listNFTsByAccountAddress(request) {
        return await this.nfts
            .listNFTsByAccountAddress(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List All NFTs on a chain
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of NFTs
     * @throws {@link index.APIError}
     */
    async listAllNFTs(request) {
        return await this.nfts
            .listAllNFTs(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List NFT owners by token ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of NFT owners
     * @throws {@link index.APIError}
     */
    async listNFTOwners(request) {
        return await this.nftOwners
            .listNFTOwners(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List NFT owners by contract address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of NFT owners
     * @throws {@link index.APIError}
     */
    async listNFTOwnersByContractAddress(request) {
        return await this.nftOwners
            .listOwnersByContractAddress(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List All NFT owners on a chain
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of NFT owners
     * @throws {@link index.APIError}
     */
    async listAllNFTOwners(request) {
        return await this.nfts
            .listAllNFTOwners(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List ERC20 Token contracts
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of ERC20 Tokens
     * @throws {@link index.APIError}
     */
    async listTokens(request) {
        return await this.tokens
            .listERC20Tokens(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Get details for an ERC20 Token by contract address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of ERC20 Tokens
     * @throws {@link index.APIError}
     */
    async getToken(request) {
        return await this.tokens
            .getERC20Token(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Get metadata by ID
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a single Metadata
     * @throws {@link index.APIError}
     */
    async getMetadata(request) {
        return await this.metadata
            .getMetadata(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * List NFT Metadata by contract address
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with a list of Metadata
     * @throws {@link index.APIError}
     */
    async listNFTMetadataByContractAddress(request) {
        return await this.metadata
            .listMetadata(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Refresh collection metadata
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the updated collection
     * @throws {@link index.APIError}
     */
    async refreshCollectionMetadata(request) {
        return await this.collections
            .refreshCollectionMetadata(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Refresh metadata for specific NFTs
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the remaining rate limits
     * @throws {@link index.APIError}
     */
    async refreshNFTMetadata(request) {
        return await this.metadata
            .refreshNFTMetadataByTokenID(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
    /**
     * Refresh metadata by ID. This will refresh metadata for all NFTs that reference the given metadata ID.
     * @param request - the request object containing the parameters to be provided in the API request
     * @returns a promise that resolves with the remaining rate limits
     * @throws {@link index.APIError}
     */
    async refreshStackedMetadata(request) {
        return await this.metadata
            .refreshMetadataByID(request)
            .then((res) => res.data)
            .catch((err) => {
            throw formatError(err);
        });
    }
}

export { APIError, BlockchainData };
