import axios, { isAxiosError } from 'axios';
import { createConfig, multiRollupConfig, MultiRollupApiClients } from '@imtbl/generated-clients';
import { ImmutableXClient } from '@imtbl/immutablex-client';
import { UserManager, User, InMemoryWebStorage, WebStorageStateStore } from 'oidc-client-ts';
import jwt_decode from 'jwt-decode';
import * as crypto from 'crypto';
import { Magic } from 'magic-sdk';
import { OpenIdExtension } from '@magic-ext/oidc';
import { generateLegacyStarkPrivateKey, createStarkSigner } from '@imtbl/core-sdk';
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers';
import * as guardian from '@imtbl/guardian';
import { TransactionApprovalRequestChainTypeEnum } from '@imtbl/guardian';
import { ethers, BigNumber } from 'ethers';
import { convertToSignableToken, signRaw } from '@imtbl/toolkit';
import { Environment } from '@imtbl/config';
import { EventEmitter } from 'events';
import { walletContracts } from '@0xsequence/abi';
import { v1 } from '@0xsequence/core';

var PassportErrorType;
(function (PassportErrorType) {
    PassportErrorType["AUTHENTICATION_ERROR"] = "AUTHENTICATION_ERROR";
    PassportErrorType["INVALID_CONFIGURATION"] = "INVALID_CONFIGURATION";
    PassportErrorType["WALLET_CONNECTION_ERROR"] = "WALLET_CONNECTION_ERROR";
    PassportErrorType["NOT_LOGGED_IN_ERROR"] = "NOT_LOGGED_IN_ERROR";
    PassportErrorType["SILENT_LOGIN_ERROR"] = "SILENT_LOGIN_ERROR";
    PassportErrorType["REFRESH_TOKEN_ERROR"] = "REFRESH_TOKEN_ERROR";
    PassportErrorType["USER_REGISTRATION_ERROR"] = "USER_REGISTRATION_ERROR";
    PassportErrorType["USER_NOT_REGISTERED_ERROR"] = "USER_NOT_REGISTERED_ERROR";
    PassportErrorType["LOGOUT_ERROR"] = "LOGOUT_ERROR";
    PassportErrorType["TRANSFER_ERROR"] = "TRANSFER_ERROR";
    PassportErrorType["CREATE_ORDER_ERROR"] = "CREATE_ORDER_ERROR";
    PassportErrorType["CANCEL_ORDER_ERROR"] = "CANCEL_ORDER_ERROR";
    PassportErrorType["EXCHANGE_TRANSFER_ERROR"] = "EXCHANGE_TRANSFER_ERROR";
    PassportErrorType["CREATE_TRADE_ERROR"] = "CREATE_TRADE_ERROR";
    PassportErrorType["OPERATION_NOT_SUPPORTED_ERROR"] = "OPERATION_NOT_SUPPORTED_ERROR";
})(PassportErrorType || (PassportErrorType = {}));
function isAPIError(error) {
    return 'code' in error && 'message' in error;
}
class PassportError extends Error {
    type;
    constructor(message, type) {
        super(message);
        this.type = type;
    }
}
const withPassportError = async (fn, customErrorType) => {
    try {
        return await fn();
    }
    catch (error) {
        let errorMessage;
        if (isAxiosError(error) && error.response?.data && isAPIError(error.response.data)) {
            errorMessage = error.response.data.message;
        }
        else {
            errorMessage = error.message;
        }
        throw new PassportError(errorMessage, customErrorType);
    }
};

/**
 * Enum representing different chain IDs.
 * @enum {number}
 * @property {number} IMTBL_ZKEVM_MAINNET - The chain ID for IMTBL ZKEVM Mainnet.
 * @property {number} IMTBL_ZKEVM_TESTNET - The chain ID for IMTBL ZKEVM Testnet.
 * @property {number} IMTBL_ZKEVM_DEVNET - The chain ID for IMTBL ZKEVM Devnet.
 * @property {number} ETHEREUM - The chain ID for Ethereum.
 * @property {number} SEPOLIA - The chain ID for Sepolia.
 */
var ChainId;
(function (ChainId) {
    ChainId[ChainId["IMTBL_ZKEVM_MAINNET"] = 13371] = "IMTBL_ZKEVM_MAINNET";
    ChainId[ChainId["IMTBL_ZKEVM_TESTNET"] = 13473] = "IMTBL_ZKEVM_TESTNET";
    ChainId[ChainId["IMTBL_ZKEVM_DEVNET"] = 15003] = "IMTBL_ZKEVM_DEVNET";
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["SEPOLIA"] = 11155111] = "SEPOLIA";
})(ChainId || (ChainId = {}));
/**
 * Enum representing different chain names.
 * @enum {number}
 * @property {number} IMTBL_ZKEVM_MAINNET - The chain name for IMTBL ZKEVM Mainnet.
 * @property {number} IMTBL_ZKEVM_TESTNET - The chain name for IMTBL ZKEVM Testnet.
 * @property {number} IMTBL_ZKEVM_DEVNET - The chain name for IMTBL ZKEVM Devnet.
 * @property {number} ETHEREUM - The chain name for Ethereum.
 * @property {number} SEPOLIA - The chain name for Sepolia.
 */
var ChainName;
(function (ChainName) {
    ChainName["ETHEREUM"] = "Ethereum";
    ChainName["SEPOLIA"] = "Sepolia";
    ChainName["IMTBL_ZKEVM_TESTNET"] = "Immutable zkEVM Test";
    ChainName["IMTBL_ZKEVM_DEVNET"] = "Immutable zkEVM Dev";
    ChainName["IMTBL_ZKEVM_MAINNET"] = "Immutable zkEVM";
})(ChainName || (ChainName = {}));

const KEY_PKCE_STATE = 'pkce_state';
const KEY_PKCE_VERIFIER = 'pkce_verifier';
const validCredentialsMinTtlSec = 3600; // 1 hour
class DeviceCredentialsManager {
    areValid(tokenResponse) {
        if (tokenResponse) {
            const accessTokenValid = this.isTokenValid(tokenResponse.access_token);
            const idTokenValid = this.isTokenValid(tokenResponse.id_token);
            return accessTokenValid && idTokenValid;
        }
        return false;
    }
    isTokenValid(jwt) {
        try {
            const tokenPayload = jwt_decode(jwt);
            const expiresAt = tokenPayload.exp ?? 0;
            const now = (Date.now() / 1000) + validCredentialsMinTtlSec;
            return expiresAt > now;
        }
        catch (error) {
            return false;
        }
    }
    savePKCEData(data) {
        localStorage.setItem(KEY_PKCE_STATE, data.state);
        localStorage.setItem(KEY_PKCE_VERIFIER, data.verifier);
    }
    getPKCEData() {
        const state = localStorage.getItem(KEY_PKCE_STATE);
        const verifier = localStorage.getItem(KEY_PKCE_VERIFIER);
        if (state && verifier) {
            return { state, verifier };
        }
        return null;
    }
}

function isIdTokenExpired(idToken) {
    if (!idToken) {
        return false;
    }
    const decodedToken = jwt_decode(idToken);
    const now = Math.floor(Date.now() / 1000);
    return decodedToken.exp < now;
}
function isTokenExpired(oidcUser) {
    const { id_token: idToken, expired } = oidcUser;
    if (expired) {
        return true;
    }
    return isIdTokenExpired(idToken);
}

const formUrlEncodedHeader = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};
const getAuthConfiguration = (config) => {
    const { authenticationDomain, oidcConfiguration } = config;
    const store = typeof window !== 'undefined' ? window.localStorage : new InMemoryWebStorage();
    const userStore = new WebStorageStateStore({ store });
    let endSessionEndpoint = `${authenticationDomain}/v2/logout?client_id=${oidcConfiguration.clientId}`;
    if (oidcConfiguration.logoutRedirectUri) {
        endSessionEndpoint += `&returnTo=${encodeURIComponent(oidcConfiguration.logoutRedirectUri)}`;
    }
    const baseConfiguration = {
        authority: authenticationDomain,
        redirect_uri: oidcConfiguration.redirectUri,
        popup_redirect_uri: oidcConfiguration.redirectUri,
        client_id: oidcConfiguration.clientId,
        metadata: {
            authorization_endpoint: `${authenticationDomain}/authorize`,
            token_endpoint: `${authenticationDomain}/oauth/token`,
            userinfo_endpoint: `${authenticationDomain}/userinfo`,
            end_session_endpoint: endSessionEndpoint,
        },
        mergeClaims: true,
        loadUserInfo: true,
        scope: oidcConfiguration.scope,
        userStore,
    };
    if (oidcConfiguration.audience) {
        baseConfiguration.extraQueryParams = {
            audience: oidcConfiguration.audience,
        };
    }
    return baseConfiguration;
};
function wait$1(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}
function base64URLEncode(str) {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}
function sha256(buffer) {
    return crypto.createHash('sha256').update(buffer).digest();
}
class AuthManager {
    userManager;
    config;
    deviceCredentialsManager;
    logoutMode;
    /**
     * Promise that is used to prevent multiple concurrent calls to the refresh token endpoint.
     */
    refreshingPromise = null;
    constructor(config) {
        this.config = config;
        this.userManager = new UserManager(getAuthConfiguration(config));
        this.deviceCredentialsManager = new DeviceCredentialsManager();
        this.logoutMode = config.oidcConfiguration.logoutMode || 'redirect';
    }
    static mapOidcUserToDomainModel = (oidcUser) => {
        const passport = oidcUser.profile?.passport;
        const user = {
            expired: oidcUser.expired,
            idToken: oidcUser.id_token,
            accessToken: oidcUser.access_token,
            refreshToken: oidcUser.refresh_token,
            profile: {
                sub: oidcUser.profile.sub,
                email: oidcUser.profile.email,
                nickname: oidcUser.profile.nickname,
            },
        };
        if (passport?.imx_eth_address) {
            user.imx = {
                ethAddress: passport.imx_eth_address,
                starkAddress: passport.imx_stark_address,
                userAdminAddress: passport.imx_user_admin_address,
            };
        }
        if (passport?.zkevm_eth_address) {
            user.zkEvm = {
                ethAddress: passport?.zkevm_eth_address,
                userAdminAddress: passport?.zkevm_user_admin_address,
            };
        }
        return user;
    };
    static mapDeviceTokenResponseToOidcUser = (tokenResponse) => {
        const idTokenPayload = jwt_decode(tokenResponse.id_token);
        return new User({
            id_token: tokenResponse.id_token,
            access_token: tokenResponse.access_token,
            refresh_token: tokenResponse.refresh_token,
            token_type: tokenResponse.token_type,
            profile: {
                sub: idTokenPayload.sub,
                iss: idTokenPayload.iss,
                aud: idTokenPayload.aud,
                exp: idTokenPayload.exp,
                iat: idTokenPayload.iat,
                email: idTokenPayload.email,
                nickname: idTokenPayload.nickname,
                passport: idTokenPayload.passport,
            },
        });
    };
    async login() {
        return withPassportError(async () => {
            const popupWindowFeatures = { width: 410, height: 450 };
            const oidcUser = await this.userManager.signinPopup({
                popupWindowFeatures,
            });
            return AuthManager.mapOidcUserToDomainModel(oidcUser);
        }, PassportErrorType.AUTHENTICATION_ERROR);
    }
    async loginCallback() {
        return withPassportError(async () => this.userManager.signinPopupCallback(), PassportErrorType.AUTHENTICATION_ERROR);
    }
    async loginWithDeviceFlow() {
        return withPassportError(async () => {
            const response = await axios.post(`${this.config.authenticationDomain}/oauth/device/code`, {
                client_id: this.config.oidcConfiguration.clientId,
                scope: this.config.oidcConfiguration.scope,
                audience: this.config.oidcConfiguration.audience,
            }, formUrlEncodedHeader);
            return {
                code: response.data.user_code,
                deviceCode: response.data.device_code,
                url: response.data.verification_uri_complete,
                interval: response.data.interval,
            };
        }, PassportErrorType.AUTHENTICATION_ERROR);
    }
    /* eslint-disable no-await-in-loop */
    async loginWithDeviceFlowCallback(deviceCode, interval, timeoutMs) {
        return withPassportError(async () => {
            const startTime = Date.now();
            const loopCondition = true;
            while (loopCondition) {
                if (timeoutMs != null && Date.now() - startTime > timeoutMs) {
                    throw new Error('Timed out');
                }
                await wait$1(interval * 1000);
                try {
                    const tokenResponse = await this.getDeviceFlowToken(deviceCode);
                    const oidcUser = AuthManager.mapDeviceTokenResponseToOidcUser(tokenResponse);
                    const user = AuthManager.mapOidcUserToDomainModel(oidcUser);
                    await this.userManager.storeUser(oidcUser);
                    return user;
                }
                catch (error) {
                    if (axios.isAxiosError(error)) {
                        const responseError = error.response?.data;
                        switch (responseError.error) {
                            case 'authorization_pending':
                                break;
                            case 'slow_down':
                                break;
                            case 'expired_token':
                                throw new Error('Token expired, please log in again');
                            case 'access_denied':
                                throw new Error('User denied access');
                            default:
                                throw new Error('Error getting token');
                        }
                    }
                    else {
                        throw error;
                    }
                }
            }
            throw new Error('Failed to get credentials');
        }, PassportErrorType.AUTHENTICATION_ERROR);
    }
    /* eslint-enable no-await-in-loop */
    async getDeviceFlowToken(deviceCode) {
        const response = await axios.post(`${this.config.authenticationDomain}/oauth/token`, {
            client_id: this.config.oidcConfiguration.clientId,
            grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
            device_code: deviceCode,
        }, formUrlEncodedHeader);
        return response.data;
    }
    getPKCEAuthorizationUrl() {
        const verifier = base64URLEncode(crypto.randomBytes(32));
        const challenge = base64URLEncode(sha256(verifier));
        // https://auth0.com/docs/secure/attack-protection/state-parameters
        const state = base64URLEncode(crypto.randomBytes(32));
        this.deviceCredentialsManager.savePKCEData({ state, verifier });
        return `${this.config.authenticationDomain}/authorize?`
            + 'response_type=code'
            + `&code_challenge=${challenge}`
            + '&code_challenge_method=S256'
            + `&client_id=${this.config.oidcConfiguration.clientId}`
            + `&redirect_uri=${this.config.oidcConfiguration.redirectUri}`
            + `&scope=${this.config.oidcConfiguration.scope}`
            + `&state=${state}`
            + `&audience=${this.config.oidcConfiguration.audience}`;
    }
    async connectImxPKCEFlow(authorizationCode, state) {
        return withPassportError(async () => {
            const pkceData = this.deviceCredentialsManager.getPKCEData();
            if (!pkceData) {
                throw new Error('No code verifier or state for PKCE');
            }
            if (state !== pkceData.state) {
                throw new Error('Provided state does not match stored state');
            }
            const tokenResponse = await this.getPKCEToken(authorizationCode, pkceData.verifier);
            const oidcUser = AuthManager.mapDeviceTokenResponseToOidcUser(tokenResponse);
            const user = AuthManager.mapOidcUserToDomainModel(oidcUser);
            await this.userManager.storeUser(oidcUser);
            return user;
        }, PassportErrorType.AUTHENTICATION_ERROR);
    }
    async getPKCEToken(authorizationCode, codeVerifier) {
        const response = await axios.post(`${this.config.authenticationDomain}/oauth/token`, {
            client_id: this.config.oidcConfiguration.clientId,
            grant_type: 'authorization_code',
            code_verifier: codeVerifier,
            code: authorizationCode,
            redirect_uri: this.config.oidcConfiguration.redirectUri,
        }, formUrlEncodedHeader);
        return response.data;
    }
    async logout() {
        return withPassportError(async () => {
            if (this.logoutMode === 'silent') {
                return this.userManager.signoutSilent();
            }
            return this.userManager.signoutRedirect();
        }, PassportErrorType.LOGOUT_ERROR);
    }
    async removeUser() {
        return this.userManager.removeUser();
    }
    getDeviceFlowEndSessionEndpoint() {
        const { authenticationDomain, oidcConfiguration } = this.config;
        let endSessionEndpoint = `${authenticationDomain}/v2/logout`;
        if (oidcConfiguration.logoutRedirectUri) {
            endSessionEndpoint += `?client_id=${oidcConfiguration.clientId}`
                + `&returnTo=${encodeURIComponent(oidcConfiguration.logoutRedirectUri)}`;
        }
        return endSessionEndpoint;
    }
    async logoutSilentCallback(url) {
        return this.userManager.signoutSilentCallback(url);
    }
    async forceUserRefresh() {
        return this.refreshTokenAndUpdatePromise();
    }
    /**
     * Refreshes the token and returns the user.
     * If the token is already being refreshed, returns the existing promise.
     */
    async refreshTokenAndUpdatePromise() {
        if (this.refreshingPromise)
            return this.refreshingPromise;
        // eslint-disable-next-line no-async-promise-executor
        this.refreshingPromise = new Promise(async (resolve, reject) => {
            try {
                const newOidcUser = await this.userManager.signinSilent();
                if (newOidcUser) {
                    resolve(AuthManager.mapOidcUserToDomainModel(newOidcUser));
                    return;
                }
                resolve(null);
            }
            catch (err) {
                reject(err);
            }
            finally {
                this.refreshingPromise = null; // Reset the promise after completion
            }
        });
        return this.refreshingPromise;
    }
    /**
     * Get the user from the cache or refresh the token if it's expired.
     * return null if there's no refresh token.
     */
    async getUser() {
        return withPassportError(async () => {
            const oidcUser = await this.userManager.getUser();
            if (!oidcUser)
                return null;
            if (!isTokenExpired(oidcUser)) {
                return AuthManager.mapOidcUserToDomainModel(oidcUser);
            }
            if (oidcUser.refresh_token) {
                return this.refreshTokenAndUpdatePromise();
            }
            return null;
        }, PassportErrorType.NOT_LOGGED_IN_ERROR);
    }
}

const lazyLoad = (promiseToAwait, initialiseFunction) => promiseToAwait().then(initialiseFunction);
const lazyDocumentReady = (initialiseFunction) => {
    const documentReadyPromise = () => new Promise((resolve) => {
        if (window.document.readyState === 'complete') {
            resolve();
        }
        else {
            const onReadyStateChange = () => {
                if (window.document.readyState === 'complete') {
                    resolve();
                    window.document.removeEventListener('readystatechange', onReadyStateChange);
                }
            };
            window.document.addEventListener('readystatechange', onReadyStateChange);
        }
    });
    return lazyLoad(documentReadyPromise, initialiseFunction);
};

class MagicAdapter {
    config;
    lazyMagicClient;
    constructor(config) {
        this.config = config;
        if (typeof window !== 'undefined') {
            this.lazyMagicClient = lazyDocumentReady(() => {
                const client = new Magic(this.config.magicPublishableApiKey, {
                    extensions: [new OpenIdExtension()],
                    network: this.config.network,
                });
                client.preload();
                return client;
            });
        }
    }
    get magicClient() {
        if (!this.lazyMagicClient) {
            throw new Error('Cannot perform this action outside of the browser');
        }
        return this.lazyMagicClient;
    }
    async login(idToken) {
        return withPassportError(async () => {
            const magicClient = await this.magicClient;
            await magicClient.openid.loginWithOIDC({
                jwt: idToken,
                providerId: this.config.magicProviderId,
            });
            return magicClient.rpcProvider;
        }, PassportErrorType.WALLET_CONNECTION_ERROR);
    }
    async logout() {
        const magicClient = await this.magicClient;
        if (magicClient.user) {
            await magicClient.user.logout();
        }
    }
}

const getStarkSigner = async (signer) => withPassportError(async () => {
    const privateKey = await generateLegacyStarkPrivateKey(signer);
    return createStarkSigner(privateKey);
}, PassportErrorType.WALLET_CONNECTION_ERROR);

const POLL_INTERVAL = 1 * 1000; // every 1 second
const MAX_RETRIES = 3;
const wait = (ms) => new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
});
const retryWithDelay = async (fn, options) => {
    const { retries = MAX_RETRIES, interval = POLL_INTERVAL, finalErr = Error('Retry failed'), finallyFn = () => { }, } = options || {};
    try {
        return await fn();
    }
    catch (err) {
        if (retries <= 0) {
            return Promise.reject(finalErr);
        }
        await wait(interval);
        return retryWithDelay(fn, { retries: retries - 1, finalErr, finallyFn });
    }
    finally {
        if (retries <= 0) {
            finallyFn();
        }
    }
};

/**
 * ProviderErrors should take priority over RpcErrorCodes
 * https://eips.ethereum.org/EIPS/eip-1193#provider-errors
 * https://eips.ethereum.org/EIPS/eip-1474#error-codes
 */
var ProviderErrorCode;
(function (ProviderErrorCode) {
    ProviderErrorCode[ProviderErrorCode["USER_REJECTED_REQUEST"] = 4001] = "USER_REJECTED_REQUEST";
    ProviderErrorCode[ProviderErrorCode["UNAUTHORIZED"] = 4100] = "UNAUTHORIZED";
    ProviderErrorCode[ProviderErrorCode["UNSUPPORTED_METHOD"] = 4200] = "UNSUPPORTED_METHOD";
    ProviderErrorCode[ProviderErrorCode["DISCONNECTED"] = 4900] = "DISCONNECTED";
})(ProviderErrorCode || (ProviderErrorCode = {}));
var RpcErrorCode;
(function (RpcErrorCode) {
    RpcErrorCode[RpcErrorCode["RPC_SERVER_ERROR"] = -32000] = "RPC_SERVER_ERROR";
    RpcErrorCode[RpcErrorCode["INVALID_REQUEST"] = -32600] = "INVALID_REQUEST";
    RpcErrorCode[RpcErrorCode["METHOD_NOT_FOUND"] = -32601] = "METHOD_NOT_FOUND";
    RpcErrorCode[RpcErrorCode["INVALID_PARAMS"] = -32602] = "INVALID_PARAMS";
    RpcErrorCode[RpcErrorCode["INTERNAL_ERROR"] = -32603] = "INTERNAL_ERROR";
    RpcErrorCode[RpcErrorCode["PARSE_ERROR"] = -32700] = "PARSE_ERROR";
    RpcErrorCode[RpcErrorCode["TRANSACTION_REJECTED"] = -32003] = "TRANSACTION_REJECTED";
})(RpcErrorCode || (RpcErrorCode = {}));
class JsonRpcError extends Error {
    message;
    code;
    constructor(code, message) {
        super(message);
        this.message = message;
        this.code = code;
    }
}

const transactionRejectedCrossSdkBridgeError = 'Transaction requires confirmation but this functionality is not'
    + ' supported in this environment. Please contact Immutable support if you need to enable this feature.';
const convertBigNumberishToString = (value) => BigNumber.from(value).toString();
const transformGuardianTransactions = (txs) => {
    try {
        return txs.map((t) => ({
            delegateCall: t.delegateCall === true,
            revertOnError: t.revertOnError === true,
            gasLimit: t.gasLimit ? convertBigNumberishToString(t.gasLimit) : '0',
            target: t.to ?? ethers.constants.AddressZero,
            value: t.value ? convertBigNumberishToString(t.value) : '0',
            data: t.data ? t.data.toString() : '0x',
        }));
    }
    catch (error) {
        const errorMessage = error instanceof Error ? error.message : String(error);
        throw new JsonRpcError(RpcErrorCode.INVALID_PARAMS, `Transaction failed to parsing: ${errorMessage}`);
    }
};
class GuardianClient {
    transactionAPI;
    messageAPI;
    confirmationScreen;
    crossSdkBridgeEnabled;
    constructor({ confirmationScreen, config }) {
        const guardianConfiguration = new guardian.Configuration({ basePath: config.imxPublicApiDomain });
        this.confirmationScreen = confirmationScreen;
        this.crossSdkBridgeEnabled = config.crossSdkBridgeEnabled;
        this.messageAPI = new guardian.MessagesApi(guardianConfiguration);
        this.transactionAPI = new guardian.TransactionsApi(guardianConfiguration);
    }
    /**
     * Open confirmation screen and close it automatically if the
     * underlying task fails.
     */
    withConfirmationScreen(popupWindowSize) {
        return (task) => this.withConfirmationScreenTask(popupWindowSize)(task)();
    }
    withConfirmationScreenTask(popupWindowSize) {
        return (task) => async () => {
            this.confirmationScreen.loading(popupWindowSize);
            try {
                return await task();
            }
            catch (err) {
                this.confirmationScreen.closeWindow();
                throw err;
            }
        };
    }
    withDefaultConfirmationScreenTask(task) {
        return this.withConfirmationScreenTask()(task);
    }
    async evaluateImxTransaction({ payloadHash, user }) {
        const finallyFn = () => {
            this.confirmationScreen.closeWindow();
        };
        const headers = { Authorization: `Bearer ${user.accessToken}` };
        const transactionRes = await retryWithDelay(async () => this.transactionAPI.getTransactionByID({
            transactionID: payloadHash,
            chainType: 'starkex',
        }, { headers }), { finallyFn });
        if (!transactionRes.data.id) {
            throw new Error("Transaction doesn't exists");
        }
        const evaluateImxRes = await this.transactionAPI.evaluateTransaction({
            id: payloadHash,
            transactionEvaluationRequest: {
                chainType: 'starkex',
            },
        }, { headers });
        const { confirmationRequired } = evaluateImxRes.data;
        if (confirmationRequired) {
            if (this.crossSdkBridgeEnabled) {
                throw new Error(transactionRejectedCrossSdkBridgeError);
            }
            const confirmationResult = await this.confirmationScreen.requestConfirmation(payloadHash, user.imx.ethAddress, TransactionApprovalRequestChainTypeEnum.Starkex);
            if (!confirmationResult.confirmed) {
                throw new Error('Transaction rejected by user');
            }
        }
        else {
            this.confirmationScreen.closeWindow();
        }
    }
    async evaluateEVMTransaction({ chainId, nonce, user, metaTransactions, }) {
        const headers = { Authorization: `Bearer ${user.accessToken}` };
        const guardianTransactions = transformGuardianTransactions(metaTransactions);
        try {
            const transactionEvaluationResponseAxiosResponse = await this.transactionAPI.evaluateTransaction({
                id: 'evm',
                transactionEvaluationRequest: {
                    chainType: 'evm',
                    chainId,
                    transactionData: {
                        nonce,
                        userAddress: user.zkEvm.ethAddress,
                        metaTransactions: guardianTransactions,
                    },
                },
            }, { headers });
            return transactionEvaluationResponseAxiosResponse.data;
        }
        catch (error) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            throw new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, `Transaction failed to validate with error: ${errorMessage}`);
        }
    }
    async validateEVMTransaction({ chainId, nonce, user, metaTransactions, }) {
        const transactionEvaluationResponse = await this.evaluateEVMTransaction({
            chainId,
            nonce,
            user,
            metaTransactions,
        });
        const { confirmationRequired, transactionId } = transactionEvaluationResponse;
        if (confirmationRequired && this.crossSdkBridgeEnabled) {
            throw new JsonRpcError(RpcErrorCode.TRANSACTION_REJECTED, transactionRejectedCrossSdkBridgeError);
        }
        if (confirmationRequired && !!transactionId) {
            const confirmationResult = await this.confirmationScreen.requestConfirmation(transactionId, user.zkEvm.ethAddress, TransactionApprovalRequestChainTypeEnum.Evm, chainId);
            if (!confirmationResult.confirmed) {
                throw new JsonRpcError(RpcErrorCode.TRANSACTION_REJECTED, 'Transaction rejected by user');
            }
        }
        else {
            this.confirmationScreen.closeWindow();
        }
    }
    async evaluateMessage({ chainID, payload, user }) {
        try {
            const messageEvalResponse = await this.messageAPI.evaluateMessage({ messageEvaluationRequest: { chainID, payload } }, { headers: { Authorization: `Bearer ${user.accessToken}` } });
            return messageEvalResponse.data;
        }
        catch (error) {
            const errorMessage = error instanceof Error ? error.message : String(error);
            throw new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, `Message failed to validate with error: ${errorMessage}`);
        }
    }
    async validateMessage({ chainID, payload, user }) {
        const { messageId, confirmationRequired } = await this.evaluateMessage({ chainID, payload, user });
        if (confirmationRequired && this.crossSdkBridgeEnabled) {
            throw new JsonRpcError(RpcErrorCode.TRANSACTION_REJECTED, transactionRejectedCrossSdkBridgeError);
        }
        if (confirmationRequired && !!messageId) {
            const confirmationResult = await this.confirmationScreen.requestMessageConfirmation(messageId, user.zkEvm.ethAddress);
            if (!confirmationResult.confirmed) {
                throw new JsonRpcError(RpcErrorCode.TRANSACTION_REJECTED, 'Signature rejected by user');
            }
        }
        else {
            this.confirmationScreen.closeWindow();
        }
    }
}

var PassportEvents;
(function (PassportEvents) {
    PassportEvents["LOGGED_OUT"] = "loggedOut";
})(PassportEvents || (PassportEvents = {}));
var Networks;
(function (Networks) {
    Networks["PRODUCTION"] = "mainnet";
    Networks["SANDBOX"] = "sepolia";
})(Networks || (Networks = {}));

async function exchangeTransfer({ user, starkSigner, request, exchangesApi, }) {
    return withPassportError(async () => {
        const { ethAddress } = user.imx;
        const transferAmount = request.amount;
        const signableResult = await exchangesApi.getExchangeSignableTransfer({
            id: request.transactionID,
            getSignableTransferRequest: {
                sender: ethAddress,
                token: convertToSignableToken(request),
                amount: transferAmount,
                receiver: request.receiver,
            },
        });
        const starkAddress = await starkSigner.getAddress();
        const { payload_hash: payloadHash } = signableResult.data;
        const starkSignature = await starkSigner.signMessage(payloadHash);
        const transferSigningParams = {
            sender_stark_key: signableResult.data.sender_stark_key || starkAddress,
            sender_vault_id: signableResult.data.sender_vault_id,
            receiver_stark_key: signableResult.data.receiver_stark_key,
            receiver_vault_id: signableResult.data.receiver_vault_id,
            asset_id: signableResult.data.asset_id,
            amount: signableResult.data.amount,
            nonce: signableResult.data.nonce,
            expiration_timestamp: signableResult.data.expiration_timestamp,
            stark_signature: starkSignature,
        };
        const headers = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${user.accessToken}`,
        };
        const response = await exchangesApi.createExchangeTransfer({
            id: request.transactionID,
            createTransferRequest: transferSigningParams,
        }, { headers });
        return {
            sent_signature: response?.data.sent_signature,
            status: response?.data.status?.toString(),
            time: response?.data.time,
            transfer_id: response?.data.transfer_id,
        };
    }, PassportErrorType.EXCHANGE_TRANSFER_ERROR);
}

const ERC721$1 = 'ERC721';
async function createOrder({ starkSigner, user, request, ordersApi, guardianClient, }) {
    return withPassportError(guardianClient.withDefaultConfirmationScreenTask(async () => {
        const { ethAddress } = user.imx;
        const amountSell = request.sell.type === ERC721$1 ? '1' : request.sell.amount;
        const amountBuy = request.buy.type === ERC721$1 ? '1' : request.buy.amount;
        const headers = { Authorization: `Bearer ${user.accessToken}` };
        const getSignableOrderRequestV3 = {
            user: ethAddress,
            amount_buy: amountBuy,
            token_buy: convertToSignableToken(request.buy),
            amount_sell: amountSell,
            token_sell: convertToSignableToken(request.sell),
            fees: request.fees,
            split_fees: true,
            expiration_timestamp: request.expiration_timestamp,
        };
        const getSignableOrderResponse = await ordersApi.getSignableOrder({
            getSignableOrderRequestV3,
        }, { headers });
        await guardianClient.evaluateImxTransaction({
            user,
            payloadHash: getSignableOrderResponse.data.payload_hash,
        });
        const { payload_hash: payloadHash } = getSignableOrderResponse.data;
        const starkSignature = await starkSigner.signMessage(payloadHash);
        const signableResultData = getSignableOrderResponse.data;
        const orderParams = {
            createOrderRequest: {
                include_fees: true,
                fees: request.fees,
                stark_signature: starkSignature,
                amount_buy: signableResultData.amount_buy,
                amount_sell: signableResultData.amount_sell,
                asset_id_buy: signableResultData.asset_id_buy,
                asset_id_sell: signableResultData.asset_id_sell,
                expiration_timestamp: signableResultData.expiration_timestamp,
                nonce: signableResultData.nonce,
                stark_key: signableResultData.stark_key,
                vault_id_buy: signableResultData.vault_id_buy,
                vault_id_sell: signableResultData.vault_id_sell,
            },
        };
        const createOrderResponse = await ordersApi.createOrderV3(orderParams, {
            headers,
        });
        return {
            ...createOrderResponse.data,
        };
    }), PassportErrorType.CREATE_ORDER_ERROR);
}
async function cancelOrder({ user, starkSigner, request, ordersApi, guardianClient, }) {
    return withPassportError(guardianClient.withDefaultConfirmationScreenTask(async () => {
        const getSignableCancelOrderRequest = {
            order_id: request.order_id,
        };
        const headers = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${user.accessToken}`,
        };
        const getSignableCancelOrderResponse = await ordersApi.getSignableCancelOrderV3({
            getSignableCancelOrderRequest,
        }, { headers });
        await guardianClient.evaluateImxTransaction({
            user,
            payloadHash: getSignableCancelOrderResponse.data.payload_hash,
        });
        const { payload_hash: payloadHash } = getSignableCancelOrderResponse.data;
        const starkSignature = await starkSigner.signMessage(payloadHash);
        const cancelOrderResponse = await ordersApi.cancelOrderV3({
            id: request.order_id.toString(),
            cancelOrderRequest: {
                order_id: request.order_id,
                stark_signature: starkSignature,
            },
        }, { headers });
        return {
            order_id: cancelOrderResponse.data.order_id,
            status: cancelOrderResponse.data.status,
        };
    }), PassportErrorType.CANCEL_ORDER_ERROR);
}

async function registerPassport({ ethSigner, starkSigner, usersApi }, authorization) {
    const [userAddress, starkPublicKey] = await Promise.all([
        ethSigner.getAddress(),
        starkSigner.getAddress(),
    ]);
    const signableResult = await usersApi.getSignableRegistrationOffchain({
        getSignableRegistrationRequest: {
            ether_key: userAddress,
            stark_key: starkPublicKey,
        },
    });
    const { signable_message: signableMessage, payload_hash: payloadHash } = signableResult.data;
    const [ethSignature, starkSignature] = await Promise.all([
        signRaw(signableMessage, ethSigner),
        starkSigner.signMessage(payloadHash),
    ]);
    const response = await usersApi.registerPassportUser({
        authorization: `Bearer ${authorization}`,
        registerPassportUserRequest: {
            eth_signature: ethSignature,
            ether_key: userAddress,
            stark_signature: starkSignature,
            stark_key: starkPublicKey,
        },
    });
    return response.data;
}

async function createTrade({ request, tradesApi, user, starkSigner, guardianClient, }) {
    return withPassportError(guardianClient.withDefaultConfirmationScreenTask(async () => {
        const { ethAddress } = user.imx;
        const getSignableTradeRequest = {
            expiration_timestamp: request.expiration_timestamp,
            fees: request.fees,
            order_id: request.order_id,
            user: ethAddress,
        };
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const headers = { Authorization: `Bearer ${user.accessToken}` };
        const getSignableTradeResponse = await tradesApi.getSignableTrade({
            getSignableTradeRequest,
        }, { headers });
        await guardianClient.evaluateImxTransaction({
            user,
            payloadHash: getSignableTradeResponse.data.payload_hash,
        });
        const { payload_hash: payloadHash } = getSignableTradeResponse.data;
        const starkSignature = await starkSigner.signMessage(payloadHash);
        const { data: signableResultData } = getSignableTradeResponse;
        const tradeParams = {
            createTradeRequest: {
                include_fees: true,
                fees: request?.fees,
                stark_signature: starkSignature,
                order_id: request?.order_id,
                fee_info: signableResultData.fee_info,
                amount_buy: signableResultData.amount_buy,
                amount_sell: signableResultData.amount_sell,
                asset_id_buy: signableResultData.asset_id_buy,
                asset_id_sell: signableResultData.asset_id_sell,
                expiration_timestamp: signableResultData.expiration_timestamp,
                nonce: signableResultData.nonce,
                stark_key: signableResultData.stark_key,
                vault_id_buy: signableResultData.vault_id_buy,
                vault_id_sell: signableResultData.vault_id_sell,
            },
        };
        const { data: createTradeResponse } = await tradesApi.createTradeV3(tradeParams, {
            headers,
        });
        return createTradeResponse;
    }), PassportErrorType.CREATE_TRADE_ERROR);
}

const ERC721 = 'ERC721';
async function transfer({ request, transfersApi, starkSigner, user, guardianClient, }) {
    return withPassportError(guardianClient.withDefaultConfirmationScreenTask(async () => {
        const transferAmount = request.type === ERC721 ? '1' : request.amount;
        const getSignableTransferRequest = {
            sender: user.imx.ethAddress,
            token: convertToSignableToken(request),
            amount: transferAmount,
            receiver: request.receiver,
        };
        const headers = {
            Authorization: `Bearer ${user.accessToken}`,
        };
        const signableResult = await transfersApi.getSignableTransferV1({
            getSignableTransferRequest,
        }, { headers });
        await guardianClient.evaluateImxTransaction({
            user,
            payloadHash: signableResult.data.payload_hash,
        });
        const signableResultData = signableResult.data;
        const { payload_hash: payloadHash } = signableResultData;
        const starkSignature = await starkSigner.signMessage(payloadHash);
        const senderStarkKey = await starkSigner.getAddress();
        const transferSigningParams = {
            sender_stark_key: signableResultData.sender_stark_key || senderStarkKey,
            sender_vault_id: signableResultData.sender_vault_id,
            receiver_stark_key: signableResultData.receiver_stark_key,
            receiver_vault_id: signableResultData.receiver_vault_id,
            asset_id: signableResultData.asset_id,
            amount: signableResultData.amount,
            nonce: signableResultData.nonce,
            expiration_timestamp: signableResultData.expiration_timestamp,
            stark_signature: starkSignature,
        };
        const createTransferRequest = {
            createTransferRequest: transferSigningParams,
        };
        const { data: responseData } = await transfersApi.createTransferV1(createTransferRequest, { headers });
        return {
            sent_signature: responseData.sent_signature,
            status: responseData.status?.toString(),
            time: responseData.time,
            transfer_id: responseData.transfer_id,
        };
    }), PassportErrorType.TRANSFER_ERROR);
}
async function batchNftTransfer({ user, starkSigner, request, transfersApi, guardianClient, }) {
    // eslint-disable-next-line function-paren-newline
    return withPassportError(guardianClient.withConfirmationScreenTask({ width: 480, height: 784 })(async () => {
        const { ethAddress } = user.imx;
        const signableRequests = request.map((nftTransfer) => ({
            amount: '1',
            token: convertToSignableToken({
                type: ERC721,
                tokenId: nftTransfer.tokenId,
                tokenAddress: nftTransfer.tokenAddress,
            }),
            receiver: nftTransfer.receiver,
        }));
        const headers = { Authorization: `Bearer ${user.accessToken}` };
        const signableResult = await transfersApi.getSignableTransfer({
            getSignableTransferRequestV2: {
                sender_ether_key: ethAddress,
                signable_requests: signableRequests,
            },
        }, { headers });
        await guardianClient.evaluateImxTransaction({
            user,
            payloadHash: signableResult.data.signable_responses[0]?.payload_hash,
        });
        const requests = await Promise.all(signableResult.data.signable_responses.map(async (resp) => {
            const starkSignature = await starkSigner.signMessage(resp.payload_hash);
            return {
                sender_vault_id: resp.sender_vault_id,
                receiver_stark_key: resp.receiver_stark_key,
                receiver_vault_id: resp.receiver_vault_id,
                asset_id: resp.asset_id,
                amount: resp.amount,
                nonce: resp.nonce,
                expiration_timestamp: resp.expiration_timestamp,
                stark_signature: starkSignature,
            };
        }));
        const transferSigningParams = {
            sender_stark_key: signableResult.data.sender_stark_key,
            requests,
        };
        const response = await transfersApi.createTransfer({
            createTransferRequestV2: transferSigningParams,
        }, { headers });
        return {
            transfer_ids: response?.data.transfer_ids,
        };
    }), PassportErrorType.TRANSFER_ERROR);
}

async function forceUserRefresh(authManager) {
    // User metadata is updated asynchronously. Poll userinfo endpoint until it is updated.
    await retryWithDelay(async () => {
        const user = await authManager.forceUserRefresh(); // force refresh to get updated user info
        if (user?.imx)
            return user;
        return Promise.reject(new Error('user wallet addresses not exist'));
    });
}
async function registerOffchain(userAdminKeySigner, starkSigner, unregisteredUser, authManager, usersApi) {
    return withPassportError(async () => {
        try {
            const response = await registerPassport({
                ethSigner: userAdminKeySigner,
                starkSigner,
                usersApi,
            }, unregisteredUser.accessToken);
            await forceUserRefresh(authManager);
            return response;
        }
        catch (err) {
            if (axios.isAxiosError(err) && err.status === 409) {
                // The user already registered, but the user token is not updated yet.
                await forceUserRefresh(authManager);
                return { tx_hash: '' };
            }
            throw err;
        }
    }, PassportErrorType.USER_REGISTRATION_ERROR);
}

class PassportImxProvider {
    authManager;
    immutableXClient;
    guardianClient;
    magicAdapter;
    /**
     * This property is set during initialisation and stores the signers in a promise.
     * This property is not meant to be accessed directly, but through the
     * `getAuthenticatedUserAndSigners` method.
     * @see getAuthenticatedUserAndSigners
     */
    signers;
    signerInitialisationError;
    constructor({ authManager, immutableXClient, confirmationScreen, config, passportEventEmitter, magicAdapter, }) {
        this.authManager = authManager;
        this.immutableXClient = immutableXClient;
        this.guardianClient = new GuardianClient({
            confirmationScreen,
            config,
        });
        this.magicAdapter = magicAdapter;
        this.initialiseSigners();
        passportEventEmitter.on(PassportEvents.LOGGED_OUT, this.handleLogout);
    }
    handleLogout = () => {
        this.signers = undefined;
    };
    /**
     * This method is called by the constructor and asynchronously initialises the signers.
     * The signers are stored in a promise so that they can be retrieved by the provider
     * when needed.
     *
     * If an error is thrown during initialisation, it is stored in the `signerInitialisationError`,
     * so that it doesn't result in an unhandled promise rejection.
     *
     * This error is thrown when the signers are requested through:
     * @see getAuthenticatedUserAndSigners
     *
     */
    async initialiseSigners() {
        const generateSigners = async () => {
            const user = await this.authManager.getUser();
            // The user will be present because the factory validates it
            const magicRpcProvider = await this.magicAdapter.login(user.idToken);
            const web3Provider = new Web3Provider(magicRpcProvider);
            const ethSigner = web3Provider.getSigner();
            const starkSigner = await getStarkSigner(ethSigner);
            return { ethSigner, starkSigner };
        };
        // eslint-disable-next-line no-async-promise-executor
        this.signers = new Promise(async (resolve) => {
            try {
                resolve(await generateSigners());
            }
            catch (err) {
                // Capture and store the initialization error
                this.signerInitialisationError = err;
                resolve(undefined);
            }
        });
    }
    async getAuthenticatedUserAndSigners() {
        const user = await this.authManager.getUser();
        if (!user || !this.signers) {
            throw new PassportError('User has been logged out', PassportErrorType.NOT_LOGGED_IN_ERROR);
        }
        const signers = await this.signers;
        // Throw the stored error if the signers failed to initialise
        if (typeof signers === 'undefined') {
            if (typeof this.signerInitialisationError !== 'undefined') {
                throw this.signerInitialisationError;
            }
            throw new Error('Signers failed to initialise');
        }
        return { user, ...signers };
    }
    async getRegisteredImxUserAndSigners() {
        const { user, starkSigner, ethSigner } = await this.getAuthenticatedUserAndSigners();
        const isUserImx = (oidcUser) => oidcUser?.imx !== undefined;
        if (!isUserImx(user)) {
            throw new PassportError('User has not been registered with StarkEx', PassportErrorType.USER_NOT_REGISTERED_ERROR);
        }
        return { user, starkSigner, ethSigner };
    }
    async transfer(request) {
        const { user, starkSigner } = await this.getRegisteredImxUserAndSigners();
        return transfer({
            request,
            user,
            starkSigner,
            transfersApi: this.immutableXClient.transfersApi,
            guardianClient: this.guardianClient,
        });
    }
    async registerOffchain() {
        const { user, ethSigner, starkSigner } = await this.getAuthenticatedUserAndSigners();
        return await registerOffchain(ethSigner, starkSigner, user, this.authManager, this.immutableXClient.usersApi);
    }
    async isRegisteredOffchain() {
        const { user } = await this.getAuthenticatedUserAndSigners();
        return !!user.imx;
    }
    // TODO: Remove once implemented
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    isRegisteredOnchain() {
        throw new PassportError('Operation not supported', PassportErrorType.OPERATION_NOT_SUPPORTED_ERROR);
    }
    async createOrder(request) {
        const { user, starkSigner } = await this.getRegisteredImxUserAndSigners();
        return createOrder({
            request,
            user,
            starkSigner,
            ordersApi: this.immutableXClient.ordersApi,
            guardianClient: this.guardianClient,
        });
    }
    async cancelOrder(request) {
        const { user, starkSigner } = await this.getRegisteredImxUserAndSigners();
        return cancelOrder({
            request,
            user,
            starkSigner,
            ordersApi: this.immutableXClient.ordersApi,
            guardianClient: this.guardianClient,
        });
    }
    async createTrade(request) {
        const { user, starkSigner } = await this.getRegisteredImxUserAndSigners();
        return createTrade({
            request,
            user,
            starkSigner,
            tradesApi: this.immutableXClient.tradesApi,
            guardianClient: this.guardianClient,
        });
    }
    async batchNftTransfer(request) {
        const { user, starkSigner } = await this.getRegisteredImxUserAndSigners();
        return batchNftTransfer({
            request,
            user,
            starkSigner,
            transfersApi: this.immutableXClient.transfersApi,
            guardianClient: this.guardianClient,
        });
    }
    async exchangeTransfer(request) {
        const { user, starkSigner } = await this.getRegisteredImxUserAndSigners();
        return exchangeTransfer({
            request,
            user,
            starkSigner,
            exchangesApi: this.immutableXClient.exchangeApi,
        });
    }
    // TODO: Remove once implemented
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    deposit(deposit) {
        throw new PassportError('Operation not supported', PassportErrorType.OPERATION_NOT_SUPPORTED_ERROR);
    }
    // TODO: Remove once implemented
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    prepareWithdrawal(request) {
        throw new PassportError('Operation not supported', PassportErrorType.OPERATION_NOT_SUPPORTED_ERROR);
    }
    // TODO: Remove once implemented
    // eslint-disable-next-line class-methods-use-this
    completeWithdrawal(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    starkPublicKey, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    token) {
        throw new PassportError('Operation not supported', PassportErrorType.OPERATION_NOT_SUPPORTED_ERROR);
    }
    async getAddress() {
        const { user } = await this.getRegisteredImxUserAndSigners();
        return Promise.resolve(user.imx.ethAddress);
    }
}

class PassportImxProviderFactory {
    authManager;
    config;
    confirmationScreen;
    immutableXClient;
    magicAdapter;
    passportEventEmitter;
    constructor({ authManager, config, confirmationScreen, immutableXClient, magicAdapter, passportEventEmitter, }) {
        this.authManager = authManager;
        this.config = config;
        this.confirmationScreen = confirmationScreen;
        this.immutableXClient = immutableXClient;
        this.magicAdapter = magicAdapter;
        this.passportEventEmitter = passportEventEmitter;
    }
    async getProvider() {
        let user = null;
        try {
            user = await this.authManager.getUser();
        }
        catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e);
        }
        if (!user) {
            user = await this.authManager.login();
        }
        return this.createProviderInstance(user);
    }
    async getProviderSilent() {
        const user = await this.authManager.getUser();
        if (!user) {
            return null;
        }
        return this.createProviderInstance(user);
    }
    async getProviderWithPKCEFlow(authorizationCode, state) {
        const user = await this.authManager.connectImxPKCEFlow(authorizationCode, state);
        return this.createProviderInstance(user);
    }
    async createProviderInstance(user) {
        if (!user.idToken) {
            throw new PassportError('Failed to initialise', PassportErrorType.WALLET_CONNECTION_ERROR);
        }
        return new PassportImxProvider({
            config: this.config,
            authManager: this.authManager,
            immutableXClient: this.immutableXClient,
            confirmationScreen: this.confirmationScreen,
            passportEventEmitter: this.passportEventEmitter,
            magicAdapter: this.magicAdapter,
        });
    }
}

const validateConfiguration = (configuration, requiredKeys, prefix) => {
    const missingKeys = requiredKeys
        .map((key) => !configuration[key] && key)
        .filter((n) => n)
        .join(', ');
    if (missingKeys !== '') {
        const errorMessage = prefix
            ? `${prefix} - ${missingKeys} cannot be null`
            : `${missingKeys} cannot be null`;
        throw new PassportError(errorMessage, PassportErrorType.INVALID_CONFIGURATION);
    }
};
class PassportConfiguration {
    network;
    authenticationDomain;
    passportDomain;
    imxPublicApiDomain;
    magicPublishableApiKey;
    magicProviderId;
    oidcConfiguration;
    baseConfig;
    zkEvmRpcUrl;
    relayerUrl;
    multiRollupConfig;
    crossSdkBridgeEnabled;
    constructor({ baseConfig, overrides, crossSdkBridgeEnabled, ...oidcConfiguration }) {
        validateConfiguration(oidcConfiguration, [
            'clientId',
            'redirectUri',
        ]);
        this.oidcConfiguration = oidcConfiguration;
        this.baseConfig = baseConfig;
        this.crossSdkBridgeEnabled = crossSdkBridgeEnabled || false;
        if (overrides) {
            validateConfiguration(overrides, [
                'network',
                'authenticationDomain',
                'passportDomain',
                'magicPublishableApiKey',
                'magicProviderId',
                'zkEvmRpcUrl',
                'relayerUrl',
                'imxPublicApiDomain',
                'indexerMrBasePath',
                'orderBookMrBasePath',
                'passportMrBasePath',
            ], 'overrides');
            this.network = overrides.network;
            this.authenticationDomain = overrides.authenticationDomain;
            this.passportDomain = overrides.passportDomain;
            this.imxPublicApiDomain = overrides.imxPublicApiDomain;
            this.magicPublishableApiKey = overrides.magicPublishableApiKey;
            this.magicProviderId = overrides.magicProviderId;
            this.zkEvmRpcUrl = overrides.zkEvmRpcUrl;
            this.relayerUrl = overrides.relayerUrl;
            this.multiRollupConfig = {
                indexer: createConfig({
                    basePath: overrides.indexerMrBasePath,
                }),
                orderBook: createConfig({
                    basePath: overrides.orderBookMrBasePath,
                }),
                passport: createConfig({
                    basePath: overrides.passportMrBasePath,
                }),
            };
        }
        else {
            switch (baseConfig.environment) {
                case Environment.PRODUCTION: {
                    this.network = Networks.PRODUCTION;
                    this.authenticationDomain = 'https://auth.immutable.com';
                    this.magicPublishableApiKey = 'pk_live_10F423798A540ED7';
                    this.magicProviderId = 'fSMzaRQ4O7p4fttl7pCyGVtJS_G70P8SNsLXtPPGHo0=';
                    this.passportDomain = 'https://passport.immutable.com';
                    this.imxPublicApiDomain = 'https://api.immutable.com';
                    this.zkEvmRpcUrl = ''; // TODO: ID-785 Update once mainnet has been deployed
                    this.relayerUrl = 'https://api.immutable.com/relayer-mr';
                    this.multiRollupConfig = multiRollupConfig.getProduction();
                    break;
                }
                case Environment.SANDBOX:
                default: {
                    this.network = Networks.SANDBOX;
                    this.authenticationDomain = 'https://auth.immutable.com';
                    this.magicPublishableApiKey = 'pk_live_10F423798A540ED7';
                    this.magicProviderId = 'fSMzaRQ4O7p4fttl7pCyGVtJS_G70P8SNsLXtPPGHo0=';
                    this.passportDomain = 'https://passport.sandbox.immutable.com';
                    this.imxPublicApiDomain = 'https://api.sandbox.immutable.com';
                    this.zkEvmRpcUrl = 'https://rpc.testnet.immutable.com';
                    this.relayerUrl = 'https://api.sandbox.immutable.com/relayer-mr';
                    this.multiRollupConfig = multiRollupConfig.getSandbox();
                    break;
                }
            }
        }
    }
}

var ReceiveMessage;
(function (ReceiveMessage) {
    ReceiveMessage["CONFIRMATION_WINDOW_READY"] = "confirmation_window_ready";
    ReceiveMessage["TRANSACTION_CONFIRMED"] = "transaction_confirmed";
    ReceiveMessage["TRANSACTION_ERROR"] = "transaction_error";
    ReceiveMessage["MESSAGE_CONFIRMED"] = "message_confirmed";
    ReceiveMessage["MESSAGE_REJECTED"] = "message_rejected";
    ReceiveMessage["LOGOUT_SUCCESS"] = "logout_success";
})(ReceiveMessage || (ReceiveMessage = {}));
const PASSPORT_EVENT_TYPE = 'imx_passport_confirmation';

const openPopupCenter = ({ url, title, width, height, }) => {
    const left = Math.max(0, Math.round(window.screenX + (window.outerWidth - width) / 2));
    const top = Math.max(0, Math.round(window.screenY + (window.outerHeight - height) / 2));
    const newWindow = window.open(url, title, `
      scrollbars=yes,
      width=${width}, 
      height=${height}, 
      top=${top}, 
      left=${left}
     `);
    if (!newWindow) {
        throw new Error('Failed to open confirmation screen');
    }
    newWindow.focus();
    return newWindow;
};

const CONFIRMATION_WINDOW_TITLE = 'Confirm this transaction';
const CONFIRMATION_WINDOW_HEIGHT = 380;
const CONFIRMATION_WINDOW_WIDTH = 480;
const CONFIRMATION_WINDOW_CLOSED_POLLING_DURATION = 1000;
const CONFIRMATION_IFRAME_ID = 'passport-confirm';
const CONFIRMATION_IFRAME_STYLE = 'display: none; position: absolute;width:0px;height:0px;border:0;';
class ConfirmationScreen {
    config;
    confirmationWindow;
    constructor(config) {
        this.config = config;
    }
    getHref(relativePath, queryStringParams) {
        let href = `${this.config.passportDomain}/transaction-confirmation/${relativePath}`;
        if (queryStringParams) {
            const queryString = queryStringParams
                ? Object.keys(queryStringParams)
                    .map((key) => `${key}=${queryStringParams[key]}`)
                    .join('&')
                : '';
            href = `${href}?${queryString}`;
        }
        return href;
    }
    requestConfirmation(transactionId, etherAddress, chainType, chainId) {
        return new Promise((resolve, reject) => {
            const messageHandler = ({ data, origin }) => {
                if (origin !== this.config.passportDomain
                    || data.eventType !== PASSPORT_EVENT_TYPE) {
                    return;
                }
                switch (data.messageType) {
                    case ReceiveMessage.CONFIRMATION_WINDOW_READY: {
                        break;
                    }
                    case ReceiveMessage.TRANSACTION_CONFIRMED: {
                        resolve({ confirmed: true });
                        break;
                    }
                    case ReceiveMessage.TRANSACTION_ERROR: {
                        reject(new Error('Transaction error'));
                        break;
                    }
                    default:
                        reject(new Error('Unsupported message type'));
                }
            };
            if (!this.confirmationWindow) {
                resolve({ confirmed: false });
                return;
            }
            window.addEventListener('message', messageHandler);
            let href = '';
            if (chainType === TransactionApprovalRequestChainTypeEnum.Starkex) {
                href = this.getHref('transaction', { transactionId, etherAddress, chainType });
            }
            else {
                href = this.getHref('zkevm', {
                    transactionId, etherAddress, chainType, chainId,
                });
            }
            this.showConfirmationScreen(href, messageHandler, resolve);
        });
    }
    requestMessageConfirmation(messageID, etherAddress) {
        return new Promise((resolve, reject) => {
            const messageHandler = ({ data, origin }) => {
                if (origin !== this.config.passportDomain
                    || data.eventType !== PASSPORT_EVENT_TYPE) {
                    return;
                }
                switch (data.messageType) {
                    case ReceiveMessage.CONFIRMATION_WINDOW_READY: {
                        break;
                    }
                    case ReceiveMessage.MESSAGE_CONFIRMED: {
                        resolve({ confirmed: true });
                        break;
                    }
                    case ReceiveMessage.MESSAGE_REJECTED: {
                        reject(new Error('Message rejected'));
                        break;
                    }
                    default:
                        reject(new Error('Unsupported message type'));
                }
            };
            if (!this.confirmationWindow) {
                resolve({ confirmed: false });
                return;
            }
            window.addEventListener('message', messageHandler);
            const href = this.getHref('zkevm/message', { messageID, etherAddress });
            this.showConfirmationScreen(href, messageHandler, resolve);
        });
    }
    loading(popupOptions) {
        if (this.config.crossSdkBridgeEnabled) {
            // There is no need to open a confirmation window if cross-sdk bridge is enabled
            return;
        }
        this.confirmationWindow = openPopupCenter({
            url: this.getHref('loading'),
            title: CONFIRMATION_WINDOW_TITLE,
            width: popupOptions?.width || CONFIRMATION_WINDOW_WIDTH,
            height: popupOptions?.height || CONFIRMATION_WINDOW_HEIGHT,
        });
    }
    closeWindow() {
        this.confirmationWindow?.close();
    }
    logout() {
        return new Promise((resolve, rejects) => {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('id', CONFIRMATION_IFRAME_ID);
            iframe.setAttribute('src', this.getHref('logout'));
            iframe.setAttribute('style', CONFIRMATION_IFRAME_STYLE);
            const logoutHandler = ({ data, origin }) => {
                if (origin !== this.config.passportDomain
                    || data.eventType !== PASSPORT_EVENT_TYPE) {
                    return;
                }
                window.removeEventListener('message', logoutHandler);
                iframe.remove();
                if (data.messageType === ReceiveMessage.LOGOUT_SUCCESS) {
                    resolve({ logout: true });
                }
                rejects(new Error('Unsupported logout type'));
            };
            window.addEventListener('message', logoutHandler);
            document.body.appendChild(iframe);
        });
    }
    showConfirmationScreen(href, messageHandler, resolve) {
        this.confirmationWindow.location.href = href;
        // https://stackoverflow.com/questions/9388380/capture-the-close-event-of-popup-window-in-javascript/48240128#48240128
        const timer = setInterval(() => {
            if (this.confirmationWindow?.closed) {
                clearInterval(timer);
                window.removeEventListener('message', messageHandler);
                resolve({ confirmed: false });
            }
        }, CONFIRMATION_WINDOW_CLOSED_POLLING_DURATION);
    }
}

var RelayerTransactionStatus;
(function (RelayerTransactionStatus) {
    RelayerTransactionStatus["PENDING"] = "PENDING";
    RelayerTransactionStatus["SUBMITTED"] = "SUBMITTED";
    RelayerTransactionStatus["SUCCESSFUL"] = "SUCCESSFUL";
    RelayerTransactionStatus["REVERTED"] = "REVERTED";
    RelayerTransactionStatus["FAILED"] = "FAILED";
})(RelayerTransactionStatus || (RelayerTransactionStatus = {}));
var ProviderEvent;
(function (ProviderEvent) {
    ProviderEvent["ACCOUNTS_CHANGED"] = "accountsChanged";
})(ProviderEvent || (ProviderEvent = {}));

class TypedEventEmitter {
    emitter = new EventEmitter();
    emit(eventName, ...eventArg) {
        this.emitter.emit(eventName, ...eventArg);
    }
    on(eventName, handler) {
        this.emitter.on(eventName, handler);
    }
    removeListener(eventName, handler) {
        this.emitter.removeListener(eventName, handler);
    }
}

const SIGNATURE_WEIGHT = 1; // Weight of a single signature in the multi-sig
const TRANSACTION_SIGNATURE_THRESHOLD = 1; // Total required weight in the multi-sig for a transaction
const EIP712_SIGNATURE_THRESHOLD = 2; // Total required weight in the multi-sig for data signing
const ETH_SIGN_FLAG = '02';
const ETH_SIGN_PREFIX = '\x19\x01';
const META_TRANSACTIONS_TYPE = `tuple(
  bool delegateCall,
  bool revertOnError,
  uint256 gasLimit,
  address target,
  uint256 value,
  bytes data
)[]`;
function getNormalisedTransactions(txs) {
    return txs.map((t) => ({
        delegateCall: t.delegateCall === true,
        revertOnError: t.revertOnError === true,
        gasLimit: t.gasLimit ?? ethers.constants.Zero,
        target: t.to ?? ethers.constants.AddressZero,
        value: t.value ?? ethers.constants.Zero,
        data: t.data ?? [],
    }));
}
function digestOfTransactionsAndNonce(nonce, normalisedTransactions) {
    const packMetaTransactionsNonceData = ethers.utils.defaultAbiCoder.encode(['uint256', META_TRANSACTIONS_TYPE], [nonce, normalisedTransactions]);
    return ethers.utils.keccak256(packMetaTransactionsNonceData);
}
const getNonce = async (jsonRpcProvider, smartContractWalletAddress) => {
    const code = await jsonRpcProvider.send('eth_getCode', [smartContractWalletAddress, 'latest']);
    if (code && code !== '0x') {
        const contract = new ethers.Contract(smartContractWalletAddress, walletContracts.mainModule.abi, jsonRpcProvider);
        return contract.nonce();
    }
    return 0;
};
const encodeMessageSubDigest = (chainId, walletAddress, digest) => (ethers.utils.solidityPack(['string', 'uint256', 'address', 'bytes32'], [ETH_SIGN_PREFIX, chainId, walletAddress, digest]));
const getSignedMetaTransactions = async (metaTransactions, nonce, chainId, walletAddress, signer) => {
    const normalisedMetaTransactions = getNormalisedTransactions(metaTransactions);
    // Get the hash
    const digest = digestOfTransactionsAndNonce(nonce, normalisedMetaTransactions);
    const completePayload = encodeMessageSubDigest(chainId, walletAddress, digest);
    const hash = ethers.utils.keccak256(completePayload);
    // Sign the digest
    const hashArray = ethers.utils.arrayify(hash);
    const ethsigNoType = await signer.signMessage(hashArray);
    const signedDigest = `${ethsigNoType}${ETH_SIGN_FLAG}`;
    // Add metadata
    const encodedSignature = v1.signature.encodeSignature({
        version: 1,
        threshold: TRANSACTION_SIGNATURE_THRESHOLD,
        signers: [
            {
                isDynamic: false,
                unrecovered: true,
                weight: SIGNATURE_WEIGHT,
                signature: signedDigest,
            },
        ],
    });
    // Encode the transaction;
    const walletInterface = new ethers.utils.Interface(walletContracts.mainModule.abi);
    return walletInterface.encodeFunctionData(walletInterface.getFunction('execute'), [
        normalisedMetaTransactions,
        nonce,
        encodedSignature,
    ]);
};
const decodeRelayerTypedDataSignature = (relayerSignature) => {
    const signatureWithThreshold = `0x0000${relayerSignature}`;
    return v1.signature.decodeSignature(signatureWithThreshold);
};
const getSignedTypedData = async (typedData, relayerSignature, chainId, walletAddress, signer) => {
    // Ethers auto-generates the EIP712Domain type in the TypedDataEncoder, and so it needs to be removed
    const types = { ...typedData.types };
    // @ts-ignore
    delete types.EIP712Domain;
    // eslint-disable-next-line no-underscore-dangle
    const digest = ethers.utils._TypedDataEncoder.hash(typedData.domain, types, typedData.message);
    const completePayload = encodeMessageSubDigest(chainId, walletAddress, digest);
    const hash = ethers.utils.keccak256(completePayload);
    // Sign the digest
    const hashArray = ethers.utils.arrayify(hash);
    const ethsigNoType = await signer.signMessage(hashArray);
    const signedDigest = `${ethsigNoType}${ETH_SIGN_FLAG}`;
    const { signers } = decodeRelayerTypedDataSignature(relayerSignature);
    return v1.signature.encodeSignature({
        version: 1,
        threshold: EIP712_SIGNATURE_THRESHOLD,
        signers: [
            ...signers,
            {
                isDynamic: false,
                unrecovered: true,
                weight: SIGNATURE_WEIGHT,
                signature: signedDigest,
            },
        ],
    });
};
const getEip155ChainId = (chainId) => `eip155:${chainId}`;

class RelayerClient {
    config;
    jsonRpcProvider;
    user;
    constructor({ config, jsonRpcProvider, user }) {
        this.config = config;
        this.jsonRpcProvider = jsonRpcProvider;
        this.user = user;
    }
    async postToRelayer(request) {
        const body = {
            id: 1,
            jsonrpc: '2.0',
            ...request,
        };
        const response = await fetch(`${this.config.relayerUrl}/v1/transactions`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${this.user.accessToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        const jsonResponse = await response.json();
        if (jsonResponse.error) {
            throw jsonResponse.error;
        }
        return jsonResponse;
    }
    async ethSendTransaction(to, data) {
        const { chainId } = await this.jsonRpcProvider.ready;
        const payload = {
            method: 'eth_sendTransaction',
            params: [{
                    to,
                    data,
                    chainId: getEip155ChainId(chainId),
                }],
        };
        const { result } = await this.postToRelayer(payload);
        return result;
    }
    async imGetTransactionByHash(hash) {
        const payload = {
            method: 'im_getTransactionByHash',
            params: [hash],
        };
        const { result } = await this.postToRelayer(payload);
        return result;
    }
    async imGetFeeOptions(userAddress, data) {
        const { chainId } = await this.jsonRpcProvider.ready;
        const payload = {
            method: 'im_getFeeOptions',
            params: [{
                    userAddress,
                    data,
                    chainId: getEip155ChainId(chainId),
                }],
        };
        const { result } = await this.postToRelayer(payload);
        return result;
    }
    async imSignTypedData(address, eip712Payload) {
        const { chainId } = await this.jsonRpcProvider.ready;
        const payload = {
            method: 'im_signTypedData',
            params: [{
                    address,
                    eip712Payload,
                    chainId: getEip155ChainId(chainId),
                }],
        };
        const { result } = await this.postToRelayer(payload);
        return result;
    }
}

const CHAIN_NAME_MAP = new Map([
    [
        ChainId.ETHEREUM,
        ChainName.ETHEREUM,
    ],
    [
        ChainId.SEPOLIA,
        ChainName.SEPOLIA,
    ],
    [
        ChainId.IMTBL_ZKEVM_MAINNET,
        ChainName.IMTBL_ZKEVM_MAINNET,
    ],
    [
        ChainId.IMTBL_ZKEVM_TESTNET,
        ChainName.IMTBL_ZKEVM_TESTNET,
    ],
    [
        ChainId.IMTBL_ZKEVM_DEVNET,
        ChainName.IMTBL_ZKEVM_DEVNET,
    ],
]);

const MESSAGE_TO_SIGN = 'Only sign this message from Immutable Passport';
async function registerZkEvmUser({ authManager, magicProvider, multiRollupApiClients, accessToken, jsonRpcProvider, }) {
    const web3Provider = new Web3Provider(magicProvider);
    const ethSigner = web3Provider.getSigner();
    const ethereumAddress = await ethSigner.getAddress();
    const ethereumSignature = await signRaw(MESSAGE_TO_SIGN, ethSigner);
    const headers = { Authorization: `Bearer ${accessToken}` };
    const { chainId } = await jsonRpcProvider.ready;
    try {
        const chainName = CHAIN_NAME_MAP.get(chainId);
        if (!chainName) {
            throw new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, `Chain name does not exist on for chain id ${chainId}`);
        }
        await multiRollupApiClients.passportApi.createCounterfactualAddress({
            chainName,
            createCounterfactualAddressRequest: {
                ethereum_address: ethereumAddress,
                ethereum_signature: ethereumSignature,
            },
        }, { headers });
    }
    catch (error) {
        throw new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, `Failed to create counterfactual address: ${error}`);
    }
    const user = await authManager.forceUserRefresh();
    if (!user?.zkEvm) {
        throw new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, 'Failed to refresh user details');
    }
    return user;
}

const loginZkEvmUser = async ({ authManager, magicAdapter, multiRollupApiClients, jsonRpcProvider, }) => {
    let user = null;
    try {
        user = await authManager.getUser();
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.warn('eth_requestAccounts` failed to retrieve a cached user session:', err);
    }
    if (!user) {
        user = await authManager.login();
    }
    if (!user.idToken) {
        throw new Error('User is missing idToken');
    }
    const magicProvider = await magicAdapter.login(user.idToken);
    if (!user.zkEvm) {
        // Generate counterfactual address and retrieve updated Auth0 user
        const userZkevm = await registerZkEvmUser({
            authManager,
            magicProvider,
            multiRollupApiClients,
            accessToken: user.accessToken,
            jsonRpcProvider,
        });
        return {
            user: userZkevm,
            magicProvider,
        };
    }
    return {
        user: user,
        magicProvider,
    };
};

const MAX_TRANSACTION_HASH_RETRIEVAL_RETRIES = 30;
const TRANSACTION_HASH_RETRIEVAL_WAIT = 1000;
const getMetaTransactions = async (metaTransaction, nonce, chainId, walletAddress, signer, relayerClient) => {
    // NOTE: We sign the transaction before getting the fee options because
    // accurate estimation of a transaction gas cost is only possible if the smart
    // wallet contract can actually execute it (in a simulated environment) - and
    // it can only execute signed transactions.
    const signedTransaction = await getSignedMetaTransactions([metaTransaction], nonce, chainId, walletAddress, signer);
    // TODO: ID-698 Add support for non-native gas payments (e.g ERC20, feeTransaction initialisation must change)
    // NOTE: "Fee Options" represent the multiple ways we could pay for the gas
    // used in this transaction. Each fee option has a "recipientAddress" we
    // should transfer the payment to, an amount and a currency. We choose one
    // option and build a transaction that sends the expected currency amount for
    // that option to the specified address.
    const feeOptions = await relayerClient.imGetFeeOptions(walletAddress, signedTransaction);
    const imxFeeOption = feeOptions.find((feeOption) => feeOption.tokenSymbol === 'IMX');
    if (!imxFeeOption) {
        throw new Error('Failed to retrieve fees for IMX token');
    }
    const feeMetaTransaction = {
        nonce,
        to: imxFeeOption.recipientAddress,
        value: imxFeeOption.tokenPrice,
        revertOnError: true,
    };
    if (BigNumber.from(feeMetaTransaction.value).isZero()) {
        return [metaTransaction];
    }
    return [metaTransaction, feeMetaTransaction];
};
const sendTransaction = ({ params, magicProvider, jsonRpcProvider, relayerClient, guardianClient, user, }) => guardianClient
    .withConfirmationScreen({ width: 480, height: 520 })(async () => {
    const transactionRequest = params[0];
    if (!transactionRequest.to) {
        throw new JsonRpcError(RpcErrorCode.INVALID_PARAMS, 'eth_sendTransaction requires a "to" field');
    }
    const { chainId } = await jsonRpcProvider.ready;
    const chainIdBigNumber = BigNumber.from(chainId);
    const magicWeb3Provider = new Web3Provider(magicProvider);
    const signer = magicWeb3Provider.getSigner();
    const nonce = await getNonce(jsonRpcProvider, user.zkEvm.ethAddress);
    const metaTransaction = {
        to: transactionRequest.to,
        data: transactionRequest.data,
        nonce,
        value: transactionRequest.value,
        revertOnError: true,
    };
    const metaTransactions = await getMetaTransactions(metaTransaction, nonce, chainIdBigNumber, user.zkEvm.ethAddress, signer, relayerClient);
    await guardianClient.validateEVMTransaction({
        chainId: getEip155ChainId(chainId),
        nonce: convertBigNumberishToString(nonce),
        user,
        metaTransactions,
    });
    // NOTE: We sign again because we now are adding the fee transaction, so the
    // whole payload is different and needs a new signature.
    const signedTransactions = await getSignedMetaTransactions(metaTransactions, nonce, chainIdBigNumber, user.zkEvm.ethAddress, signer);
    const relayerId = await relayerClient.ethSendTransaction(user.zkEvm.ethAddress, signedTransactions);
    const retrieveRelayerTransaction = async () => {
        const tx = await relayerClient.imGetTransactionByHash(relayerId);
        // NOTE: The transaction hash is only available from the Relayer once the
        // transaction is actually submitted onchain. Hence we need to poll the
        // Relayer get transaction endpoint until the status transitions to one that
        // has the hash available.
        if (tx.status === RelayerTransactionStatus.PENDING) {
            throw new Error();
        }
        return tx;
    };
    const relayerTransaction = await retryWithDelay(retrieveRelayerTransaction, {
        retries: MAX_TRANSACTION_HASH_RETRIEVAL_RETRIES,
        interval: TRANSACTION_HASH_RETRIEVAL_WAIT,
        finalErr: new JsonRpcError(RpcErrorCode.RPC_SERVER_ERROR, 'transaction hash not generated in time'),
    });
    if (![
        RelayerTransactionStatus.SUBMITTED,
        RelayerTransactionStatus.SUCCESSFUL,
    ].includes(relayerTransaction.status)) {
        let errorMessage = `Transaction failed to submit with status ${relayerTransaction.status}.`;
        if (relayerTransaction.statusMessage) {
            errorMessage += ` Error message: ${relayerTransaction.statusMessage}`;
        }
        throw new JsonRpcError(RpcErrorCode.RPC_SERVER_ERROR, errorMessage);
    }
    return relayerTransaction.hash;
});

const REQUIRED_TYPED_DATA_PROPERTIES = ['types', 'domain', 'primaryType', 'message'];
const isValidTypedDataPayload = (typedData) => (REQUIRED_TYPED_DATA_PROPERTIES.every((key) => key in typedData));
const transformTypedData = (typedData, chainId) => {
    let transformedTypedData;
    if (typeof typedData === 'string') {
        try {
            transformedTypedData = JSON.parse(typedData);
        }
        catch (err) {
            throw new JsonRpcError(RpcErrorCode.INVALID_PARAMS, `Failed to parse typed data JSON: ${err}`);
        }
    }
    else if (typeof typedData === 'object') {
        transformedTypedData = typedData;
    }
    else {
        throw new JsonRpcError(RpcErrorCode.INVALID_PARAMS, `Invalid typed data argument: ${typedData}`);
    }
    if (!isValidTypedDataPayload(transformedTypedData)) {
        throw new JsonRpcError(RpcErrorCode.INVALID_PARAMS, `Invalid typed data argument. The following properties are required: ${REQUIRED_TYPED_DATA_PROPERTIES.join(', ')}`);
    }
    const providedChainId = transformedTypedData.domain?.chainId;
    if (providedChainId) {
        // domain.chainId (if defined) can be a number, string, or hex value, but the relayer & guardian only accept a number.
        if (typeof providedChainId === 'string') {
            if (providedChainId.startsWith('0x')) {
                transformedTypedData.domain.chainId = parseInt(providedChainId, 16);
            }
            else {
                transformedTypedData.domain.chainId = parseInt(providedChainId, 10);
            }
        }
        if (transformedTypedData.domain.chainId !== chainId) {
            throw new JsonRpcError(RpcErrorCode.INVALID_PARAMS, `Invalid chainId, expected ${chainId}`);
        }
    }
    return transformedTypedData;
};
const signTypedDataV4 = async ({ params, method, magicProvider, jsonRpcProvider, relayerClient, guardianClient, user, }) => guardianClient
    .withConfirmationScreen({ width: 480, height: 730 })(async () => {
    const fromAddress = params[0];
    const typedDataParam = params[1];
    if (!fromAddress || !typedDataParam) {
        throw new JsonRpcError(RpcErrorCode.INVALID_PARAMS, `${method} requires an address and a typed data JSON`);
    }
    const { chainId } = await jsonRpcProvider.ready;
    const typedData = transformTypedData(typedDataParam, chainId);
    await guardianClient.validateMessage({ chainID: String(chainId), payload: typedData, user });
    const relayerSignature = await relayerClient.imSignTypedData(fromAddress, typedData);
    const magicWeb3Provider = new Web3Provider(magicProvider);
    const signer = magicWeb3Provider.getSigner();
    return getSignedTypedData(typedData, relayerSignature, BigNumber.from(chainId), fromAddress, signer);
});

class ZkEvmProvider {
    authManager;
    config;
    confirmationScreen;
    magicAdapter;
    multiRollupApiClients;
    jsonRpcProvider; // Used for read
    eventEmitter;
    guardianClient;
    relayerClient;
    magicProvider; // Used for signing
    user;
    isPassport = true;
    constructor({ authManager, magicAdapter, config, confirmationScreen, multiRollupApiClients, passportEventEmitter, }) {
        this.authManager = authManager;
        this.magicAdapter = magicAdapter;
        this.config = config;
        this.confirmationScreen = confirmationScreen;
        if (config.crossSdkBridgeEnabled) {
            // JsonRpcProvider by default sets the referrer as "client".
            // On Unreal 4 this errors as the browser used is expecting a valid URL.
            this.jsonRpcProvider = new JsonRpcProvider({
                url: this.config.zkEvmRpcUrl,
                fetchOptions: { referrer: 'http://imtblgamesdk.local' },
            });
        }
        else {
            this.jsonRpcProvider = new JsonRpcProvider(this.config.zkEvmRpcUrl);
        }
        this.multiRollupApiClients = multiRollupApiClients;
        this.eventEmitter = new TypedEventEmitter();
        passportEventEmitter.on(PassportEvents.LOGGED_OUT, this.handleLogout);
    }
    handleLogout = () => {
        const shouldEmitAccountsChanged = this.isLoggedIn();
        this.magicProvider = undefined;
        this.user = undefined;
        this.relayerClient = undefined;
        this.guardianClient = undefined;
        if (shouldEmitAccountsChanged) {
            this.eventEmitter.emit(ProviderEvent.ACCOUNTS_CHANGED, []);
        }
    };
    isLoggedIn() {
        return this.magicProvider !== undefined
            && this.user !== undefined
            && this.relayerClient !== undefined
            && this.guardianClient !== undefined;
    }
    async performRequest(request) {
        switch (request.method) {
            case 'eth_requestAccounts': {
                if (this.isLoggedIn()) {
                    return [this.user.zkEvm.ethAddress];
                }
                const { magicProvider, user } = await loginZkEvmUser({
                    authManager: this.authManager,
                    config: this.config,
                    magicAdapter: this.magicAdapter,
                    multiRollupApiClients: this.multiRollupApiClients,
                    jsonRpcProvider: this.jsonRpcProvider,
                });
                this.user = user;
                this.magicProvider = magicProvider;
                this.relayerClient = new RelayerClient({
                    config: this.config,
                    jsonRpcProvider: this.jsonRpcProvider,
                    user: this.user,
                });
                this.guardianClient = new GuardianClient({
                    confirmationScreen: this.confirmationScreen,
                    config: this.config,
                });
                this.eventEmitter.emit(ProviderEvent.ACCOUNTS_CHANGED, [this.user.zkEvm.ethAddress]);
                return [this.user.zkEvm.ethAddress];
            }
            case 'eth_sendTransaction': {
                if (!this.isLoggedIn()) {
                    throw new JsonRpcError(ProviderErrorCode.UNAUTHORIZED, 'Unauthorised - call eth_requestAccounts first');
                }
                return sendTransaction({
                    params: request.params || [],
                    magicProvider: this.magicProvider,
                    guardianClient: this.guardianClient,
                    jsonRpcProvider: this.jsonRpcProvider,
                    relayerClient: this.relayerClient,
                    user: this.user,
                });
            }
            case 'eth_accounts': {
                return this.isLoggedIn() ? [this.user.zkEvm.ethAddress] : [];
            }
            case 'eth_signTypedData':
            case 'eth_signTypedData_v4': {
                if (!this.isLoggedIn()) {
                    throw new JsonRpcError(ProviderErrorCode.UNAUTHORIZED, 'Unauthorised - call eth_requestAccounts first');
                }
                return signTypedDataV4({
                    method: request.method,
                    params: request.params || [],
                    magicProvider: this.magicProvider,
                    jsonRpcProvider: this.jsonRpcProvider,
                    relayerClient: this.relayerClient,
                    user: this.user,
                    guardianClient: this.guardianClient,
                });
            }
            // Pass through methods
            case 'eth_gasPrice':
            case 'eth_getBalance':
            case 'eth_getCode':
            case 'eth_getStorageAt':
            case 'eth_estimateGas':
            case 'eth_call':
            case 'eth_blockNumber':
            case 'eth_chainId':
            case 'eth_getBlockByHash':
            case 'eth_getBlockByNumber':
            case 'eth_getTransactionByHash':
            case 'eth_getTransactionReceipt':
            case 'eth_getTransactionCount': {
                return this.jsonRpcProvider.send(request.method, request.params || []);
            }
            default: {
                throw new JsonRpcError(ProviderErrorCode.UNSUPPORTED_METHOD, 'Method not supported');
            }
        }
    }
    async performJsonRpcRequest(request) {
        const { id, jsonrpc } = request;
        try {
            const result = await this.performRequest(request);
            return {
                id,
                jsonrpc,
                result,
            };
        }
        catch (error) {
            let jsonRpcError;
            if (error instanceof JsonRpcError) {
                jsonRpcError = error;
            }
            else if (error instanceof Error) {
                jsonRpcError = new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, error.message);
            }
            else {
                jsonRpcError = new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, 'Internal error');
            }
            return {
                id,
                jsonrpc,
                error: jsonRpcError,
            };
        }
    }
    async request(request) {
        try {
            return this.performRequest(request);
        }
        catch (error) {
            if (error instanceof JsonRpcError) {
                throw error;
            }
            if (error instanceof Error) {
                throw new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, error.message);
            }
            throw new JsonRpcError(RpcErrorCode.INTERNAL_ERROR, 'Internal error');
        }
    }
    sendAsync(request, callback) {
        if (!callback) {
            throw new Error('No callback provided');
        }
        if (Array.isArray(request)) {
            Promise.all(request.map(this.performJsonRpcRequest)).then((result) => {
                callback(null, result);
            }).catch((error) => {
                callback(error, []);
            });
        }
        else {
            this.performJsonRpcRequest(request).then((result) => {
                callback(null, result);
            }).catch((error) => {
                callback(error, null);
            });
        }
    }
    async send(request, callbackOrParams, callback) {
        // Web3 >= 1.0.0-beta.38 calls `send` with method and parameters.
        if (typeof request === 'string') {
            if (typeof callbackOrParams === 'function') {
                return this.sendAsync({
                    method: request,
                    params: [],
                }, callbackOrParams);
            }
            if (callback) {
                return this.sendAsync({
                    method: request,
                    params: Array.isArray(callbackOrParams) ? callbackOrParams : [],
                }, callback);
            }
            return this.request({
                method: request,
                params: Array.isArray(callbackOrParams) ? callbackOrParams : [],
            });
        }
        // Web3 <= 1.0.0-beta.37 uses `send` with a callback for async queries.
        if (typeof callbackOrParams === 'function') {
            return this.sendAsync(request, callbackOrParams);
        }
        if (!Array.isArray(request) && typeof request === 'object') {
            return this.performJsonRpcRequest(request);
        }
        throw new JsonRpcError(RpcErrorCode.INVALID_REQUEST, 'Invalid request');
    }
    on(event, listener) {
        this.eventEmitter.on(event, listener);
    }
    removeListener(event, listener) {
        this.eventEmitter.removeListener(event, listener);
    }
}

class Passport {
    authManager;
    config;
    confirmationScreen;
    immutableXClient;
    magicAdapter;
    multiRollupApiClients;
    passportImxProviderFactory;
    passportEventEmitter;
    constructor(passportModuleConfiguration) {
        this.config = new PassportConfiguration(passportModuleConfiguration);
        this.authManager = new AuthManager(this.config);
        this.magicAdapter = new MagicAdapter(this.config);
        this.confirmationScreen = new ConfirmationScreen(this.config);
        this.immutableXClient = passportModuleConfiguration.overrides?.immutableXClient
            || new ImmutableXClient({
                baseConfig: passportModuleConfiguration.baseConfig,
            });
        this.multiRollupApiClients = new MultiRollupApiClients(this.config.multiRollupConfig);
        this.passportEventEmitter = new TypedEventEmitter();
        this.passportImxProviderFactory = new PassportImxProviderFactory({
            authManager: this.authManager,
            config: this.config,
            confirmationScreen: this.confirmationScreen,
            immutableXClient: this.immutableXClient,
            magicAdapter: this.magicAdapter,
            passportEventEmitter: this.passportEventEmitter,
        });
    }
    /**
     * @deprecated The method `login` with an argument of `{ useCachedSession: true }` should be used in conjunction with
     * `connectImx` instead.
     */
    async connectImxSilent() {
        return this.passportImxProviderFactory.getProviderSilent();
    }
    async connectImx() {
        return this.passportImxProviderFactory.getProvider();
    }
    getPKCEAuthorizationUrl() {
        return this.authManager.getPKCEAuthorizationUrl();
    }
    async connectImxPKCEFlow(authorizationCode, state) {
        return this.passportImxProviderFactory.getProviderWithPKCEFlow(authorizationCode, state);
    }
    connectEvm() {
        if (this.config.network === Networks.PRODUCTION) {
            throw new Error('EVM is not supported on production network');
        }
        return new ZkEvmProvider({
            passportEventEmitter: this.passportEventEmitter,
            authManager: this.authManager,
            magicAdapter: this.magicAdapter,
            config: this.config,
            confirmationScreen: this.confirmationScreen,
            multiRollupApiClients: this.multiRollupApiClients,
        });
    }
    /**
     *
     * Initiates the authorisation flow.
     *
     * @param options.useCachedSession = false - If true, and no active session exists, then the user will not be
     * prompted to log in and the Promise will resolve with a null value.
     * @returns {Promise<UserProfile | null>} the user profile if the user is logged in, otherwise null
     */
    async login(options) {
        const { useCachedSession = false } = options || {};
        let user = null;
        try {
            user = await this.authManager.getUser();
        }
        catch (error) {
            if (useCachedSession) {
                throw error;
            }
            // eslint-disable-next-line no-console
            console.warn('login failed to retrieve a cached user session', error);
        }
        if (!user && !useCachedSession) {
            user = await this.authManager.login();
        }
        return user ? user.profile : null;
    }
    async loginCallback() {
        return this.authManager.loginCallback();
    }
    async loginWithDeviceFlow() {
        return this.authManager.loginWithDeviceFlow();
    }
    async loginWithDeviceFlowCallback(deviceCode, interval, timeoutMs) {
        const user = await this.authManager.loginWithDeviceFlowCallback(deviceCode, interval, timeoutMs);
        return user.profile;
    }
    async logout() {
        await this.confirmationScreen.logout();
        await this.authManager.logout();
        // Code after this point is only executed if the logout mode is silent
        await this.magicAdapter.logout();
        this.passportEventEmitter.emit(PassportEvents.LOGGED_OUT);
    }
    /**
     * Logs the user out of Passport when using device flow authentication.
     *
     * @returns {Promise<string>} The device flow end session endpoint. Consumers are responsible for
     * opening this URL in the same browser that was used to log the user in.
     */
    async logoutDeviceFlow() {
        await this.authManager.removeUser();
        await this.magicAdapter.logout();
        this.passportEventEmitter.emit(PassportEvents.LOGGED_OUT);
        return this.authManager.getDeviceFlowEndSessionEndpoint();
    }
    /**
     * This method should only be called from the logout redirect uri
     * when logout mode is 'silent'.
     */
    async logoutSilentCallback(url) {
        return this.authManager.logoutSilentCallback(url);
    }
    async getUserInfo() {
        const user = await this.authManager.getUser();
        return user?.profile;
    }
    async getIdToken() {
        const user = await this.authManager.getUser();
        return user?.idToken;
    }
    async getAccessToken() {
        const user = await this.authManager.getUser();
        return user?.accessToken;
    }
    async getLinkedAddresses() {
        const user = await this.authManager.getUser();
        if (!user?.profile.sub) {
            return [];
        }
        const headers = { Authorization: `Bearer ${user.accessToken}` };
        const linkedAddressesResult = await this.multiRollupApiClients.passportApi.getLinkedAddresses({
            chainName: ChainName.ETHEREUM,
            userId: user?.profile.sub,
        }, { headers });
        return linkedAddressesResult.data.linked_addresses;
    }
}

export { JsonRpcError, Networks, Passport, PassportError, ProviderErrorCode, ProviderEvent, RpcErrorCode };
