import { useEffect } from "react";

import { config, passport } from "@imtbl/sdk";
import {
  ClientIdProduction,
  RedirectUrlProduction,
} from "../../enums/Constants";

function Callback() {
  useEffect(() => {
    const passportInstance = new passport.Passport({
      baseConfig: {
        environment: config.Environment.PRODUCTION,
      },
      clientId: ClientIdProduction,
      redirectUri: RedirectUrlProduction,
    });

    passportInstance.loginCallback();
  }, []);

  return <div>You can close this window.</div>;
}

export default Callback;
