export const Api_Localhost = "http://localhost:3333/api";
export const Api_Dev = "https://wm-api-dev.cubestagearea.xyz/api";
export const Api_Stagging = "https://wm-api-stag.cubestagearea.xyz/api";
export const Api_Production = "https://api.wagmidefense.com/api";

export const ClientIdLocalHost = "3W4B5tfLH4Dzg7iUOX87YyMywAimsnNT";
export const ClientIdDevelopment = "dtAFv8o8SgUvWBXxsrmSOpe7ZKOfBekt";
export const ClientIdProduction = "xbV5uXrFI9VOsWVuWEvRIk8AZsLBCks9";

export const RedirectUrlLocalhost = "http://localhost:1234/redirect-localhost";
export const RedirectUrlDev = "https://support.wagmidefense.com/redirect-dev";
export const RedirectUrlProduction =
  "https://support.wagmidefense.com/redirect";
