import "./index.css";
import { useEffect, useState } from "react";
import {
  ENVIRONMENTS,
  L1_PROVIDERS,
  WALLET_SDK_EVENTS,
  WalletSDK,
  WalletConnection,
  walletSdkEvents,
} from "@imtbl/wallet-sdk-web";
import { useParams } from "react-router-dom";
import { config, passport } from "@imtbl/sdk";
import md5 from "crypto-js/md5";
import Swal from "sweetalert2";

import { wagmiLogo } from "../../assets/images";
import { useNetwork } from "../../helpers/useNetwork";
import { EnviromentType } from "../../enums";
import axios from "axios";
import {
  Api_Dev,
  Api_Localhost,
  Api_Production,
  Api_Stagging,
  ClientIdDevelopment,
  ClientIdLocalHost,
  ClientIdProduction,
  RedirectUrlDev,
  RedirectUrlLocalhost,
  RedirectUrlProduction,
} from "../../enums/Constants";

let sdk: WalletSDK;
let passportInstance: passport.Passport;
let walletRequestInit = false;

function Home() {
  const { env } = useParams();
  const [loading, setIsLoading] = useState(false);
  const [deleteWalletAddress, setDeleteWalletAddress] = useState("");

  const network = useNetwork();

  useEffect(() => {
    // set passport instance
    const { environment, clientId, redirectUri } = getPassportInstanceInfo();
    passportInstance = new passport.Passport({
      baseConfig: {
        environment: environment,
      },
      logoutMode: "silent",
      clientId: clientId,
      redirectUri: redirectUri,
    });

    // passport silent logout instance
    passportInstance.logoutSilentCallback("/");

    // add event listener for meta mask
    addEventListenersMetaMask();

    initWalletSdk();
  }, []);

  const initWalletSdk = async () => {
    setIsLoading(true);

    sdk = await WalletSDK.build({
      env: getEnviromentTypeMetaMask(),
    });

    setIsLoading(false);
  };

  function getEnviromentTypeMetaMask() {
    const env = getEnviroment();
    return env == EnviromentType.Production
      ? ENVIRONMENTS.PRODUCTION
      : ENVIRONMENTS.STAGING;
  }

  function getNetWorkMetaMask() {
    const env = getEnviroment();
    return env == EnviromentType.Production ? "ethereum" : "sepolia";
  }

  function getPassportInstanceInfo() {
    const env = getEnviroment();
    switch (env) {
      case EnviromentType.Localhost:
        return {
          environment: config.Environment.SANDBOX,
          clientId: ClientIdLocalHost,
          redirectUri: RedirectUrlLocalhost,
        };
      case EnviromentType.Development:
      case EnviromentType.Stagging:
        return {
          environment: config.Environment.SANDBOX,
          clientId: ClientIdDevelopment,
          redirectUri: RedirectUrlDev,
        };
      case EnviromentType.Production:
        return {
          environment: config.Environment.PRODUCTION,
          clientId: ClientIdProduction,
          redirectUri: RedirectUrlProduction,
        };
    }
  }

  async function deleteAccountRequest(walletAddress, header, payload) {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You want to delete account with wallet address '${walletAddress}', You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        setIsLoading(true);

        await axios.delete(`${getApiBaseUrl()}/delete-account`, {
          headers: header,
          data: payload,
        });
        console.log(`deleted successfully`);
        setIsLoading(false);
        setDeleteWalletAddress(walletAddress);

        Swal.fire({
          title: "Deleted!",
          text: "Your Account has been deleted.",
          icon: "success",
        });
      } catch (error) {
        console.log(`error`, error);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            error?.response?.data?.msg ??
            error?.message ??
            "Something Went Wrong",
        });
      }
    }
  }

  function getApiBaseUrl() {
    if (env == "localhost") {
      return Api_Localhost;
    } else if (env == "dev") {
      return Api_Dev;
    } else if (env == "stagging") {
      return Api_Stagging;
    }
    return Api_Production;
  }

  function getEnviroment() {
    if (env == "localhost") {
      return EnviromentType.Localhost;
    } else if (env == "dev") {
      return EnviromentType.Development;
    } else if (env == "stagging") {
      return EnviromentType.Stagging;
    }
    return EnviromentType.Production;
  }

  const connectMetaMask = async () => {
    try {
      setIsLoading(true);
      await network.switchNetwork(getNetWorkMetaMask());
      walletRequestInit = true;
      setIsLoading(false);
      await sdk.connect({
        provider: L1_PROVIDERS.METAMASK,
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  async function onWalletConnected(updatedWalletConnection: WalletConnection) {
    try {
      setIsLoading(true);
      const walletAddressIns =
        await updatedWalletConnection.l1Signer.getAddress();

      const response = await axios.post(`${getApiBaseUrl()}/generate-jwt`);

      const { data } = response.data;

      setIsLoading(false);

      const md5Data = md5(data);
      const hash = await updatedWalletConnection.l1Signer.signMessage(
        md5Data.toString()
      );

      deleteAccountRequest(
        walletAddressIns,
        { token: data },
        { isMetamask: "1", publicAddress: walletAddressIns, signature: hash }
      );
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.message ?? "Something Went Wrong",
      });
    }
  }

  function addEventListenersMetaMask() {
    walletSdkEvents.on(
      WALLET_SDK_EVENTS.CONNECTION_UPDATED,
      async (updatedWalletConnection: WalletConnection) => {
        if (walletRequestInit) {
          onWalletConnected(updatedWalletConnection);
        }
      }
    );

    walletSdkEvents.on(WALLET_SDK_EVENTS.WALLET_DISCONNECTED, () => {
      console.log(`wallet address is dic-connected`);
    });
  }

  const renderLoader = () => {
    return (
      <>
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </>
    );
  };

  const connectPassport = async () => {
    try {
      setIsLoading(true);

      console.log(`logout start`);

      await passportInstance.logout();

      console.log(`logout done`);

      setIsLoading(false);

      const provider = await passportInstance.connectImx();

      setIsLoading(true);

      const addressWallet = await provider.getAddress();
      const idToken = await passportInstance.getIdToken();
      console.log(`addressWallet`, addressWallet);
      console.log(`idToken`, idToken);

      setIsLoading(false);

      deleteAccountRequest(
        addressWallet,
        { token: idToken },
        { isPassport: "1" }
      );
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error?.message ?? "Something Went Wrong",
      });
      console.log(`error`, error);
    }
  };

  const renderHeader = () => {
    return (
      <div className="header">
        <img
          className="logo"
          alt="Wagmi games"
          src={wagmiLogo}
          decoding="async"
          data-nimg="intrinsic"
        />
      </div>
    );
  };

  const renderSignUp = () => {
    return (
      <>
        <div className="content">
          <div className="dialog">
            <h1 className="dialog-title">Connect Wallet To Delete Account</h1>
            <button type="button" onClick={connectMetaMask}>
              Connect Meta Mask
            </button>
            <button type="button" onClick={connectPassport}>
              Connect Passport
            </button>
          </div>
        </div>
      </>
    );
  };

  const accountDeletedMessage = () => {
    return (
      <>
        <div className="content">
          <div className="dialog">
            <h1 className="dialog-title">Account Deleted Successfully</h1>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="container">
      {renderHeader()}
      {deleteWalletAddress == "" ? renderSignUp() : accountDeletedMessage()}
      {loading == true && renderLoader()}
    </div>
  );
}

export default Home;
